import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import PageWithCenterBoxAndLogo from '../../components/PageWithCenterBoxAndLogo'
import TextWithDivider from '../../components/TextWithDivider'
import GoogleButton from '../../components/buttons/GoogleButton'
import { ReactComponent as IconWarning } from '../../assets/images/icon-warning.svg'
import AivatarClient from '../../services/AivatarClient'
import useDialog from '../../hooks/useDialog'
import useEmailInput from '../../hooks/useEmailInput'
import useURI from '../../hooks/useURI'

function SignUp() {
  const { t, i18n } = useTranslation('auth-signup')
  const { uri } = useURI()
  const dialog = useDialog()

  const emailInput = useEmailInput()
  const [isComplete, setIsComplete] = useState(false)

  const sendEmailVerification = () => {
    AivatarClient()
      .post('/auth/register', { email: emailInput.email, language: i18n.language })
      .then(() => setIsComplete(true))
      .catch(() => {
        emailInput.setErrorMsg(t('입력한 이메일은 이미 사용 중인 이메일 계정입니다.'))
      })
  }

  const resendEmailVerification = () => {
    AivatarClient()
      .get('/auth/resend-verification', { params: { email: emailInput.email } })
      .then(() =>
        dialog.alert({
          title: t('전송 완료'),
          message: t('이메일을 확인해 주세요.'),
          iconType: 'complete',
        }),
      )
  }

  return (
    <PageWithCenterBoxAndLogo>
      {isComplete ? (
        <>
          <span className="title">{t('이메일을 확인해 주세요.')}</span>
          <span className="subtitle leading-[29px]">
            <Trans t={t}>
              이메일 주소 확인 후에 바로 AiVATAR를 시작할 수 있습니다.
              <br />
              이메일을 받지 못하셨다면,{' '}
              <button className="link-main text-[16px]" onClick={resendEmailVerification}>
                여기
              </button>
              를 눌러 이메일을 다시 보낼 수 있습니다.
            </Trans>
          </span>
          <Link className="link-main mt-[50px]" to="/login">
            {t('로그인')}
          </Link>
        </>
      ) : (
        <>
          <span className="title">{t('가입하기')}</span>
          <span className="subtitle">
            {t('이미 계정이 있으신가요?')}{' '}
            <Link className="link-main text-[16px]" to="/login">
              {t('로그인')}
            </Link>
          </span>
          <input className={`input mt-[50px] ${emailInput.isError ? 'input-error' : ''}`} {...emailInput.inputProps} />
          {emailInput.isError && (
            <span className="text-point-3 mt-[10px] flex w-full items-center">
              <IconWarning className="mr-[5px]" />
              {emailInput.errorMsg}
            </span>
          )}
          <p className="mt-[20px] text-[12px] text-[gray-500]">
            <Trans t={t}>
              가입 신청 시 AiVATAR의{' '}
              <Link className="underline" to={uri['aivatar-privacy']} target="_blank">
                개인정보처리방침
              </Link>{' '}
              및{' '}
              <Link className="underline" to={uri['aivatar-term']} target="_blank">
                서비스 이용약관
              </Link>
              에 동의하는 것으로 간주합니다.
            </Trans>
          </p>
          <button
            className="btn-main btn-l mt-[20px]"
            onClick={() => {
              if (emailInput.verify()) {
                sendEmailVerification()
              }
            }}
          >
            {t('가입하기')}
          </button>
          <TextWithDivider className="mt-[20px]">{t('또는')}</TextWithDivider>
          <GoogleButton className="mt-[20px]">{t('구글 계정으로 가입하기')}</GoogleButton>
        </>
      )}
    </PageWithCenterBoxAndLogo>
  )
}

export default SignUp
