import { useCallback, useEffect } from 'react'

export default function useKeyPressed({ downCallback, upCallback, keyCodes }) {
  const downhandler = useCallback(
    ({ code }) => {
      console.log('useKeyPressed - downhandler - code', code)
      if (keyCodes.includes(code) && typeof downCallback === 'function') {
        downCallback()
      }
    },
    [downCallback, keyCodes],
  )

  const uphandler = useCallback(
    ({ code }) => {
      console.log('useKeyPressed - uphandler - code', code)
      if (keyCodes.includes(code) && typeof downCallback === 'function') {
        upCallback()
      }
    },
    [downCallback, keyCodes, upCallback],
  )

  useEffect(() => {
    window.addEventListener('keydown', downhandler)
    window.addEventListener('keyup', uphandler)
    return () => {
      window.removeEventListener('keydown', downhandler)
      window.removeEventListener('keyup', uphandler)
    }
  }, [downhandler, uphandler])
}
