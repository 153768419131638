import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageWithCenterBoxAndLogo from '../../components/PageWithCenterBoxAndLogo'
import { ReactComponent as IconWarning } from '../../assets/images/icon-warning.svg'
import useEmailInput from '../../hooks/useEmailInput'
import AivatarClient from '../../services/AivatarClient'

function FindPw() {
  const { t } = useTranslation('auth-findpw')
  const emailInput = useEmailInput()
  const [isComplete, setIsComplete] = useState(false)

  const sendEmailVerification = () => {
    AivatarClient()
      .get('/auth/change-password', { params: { email: emailInput.email } })
      .then(() => setIsComplete(true))
      .catch(() => {
        emailInput.setErrorMsg(t('이메일 주소와 일치하는 회원이 없습니다.'))
      })
  }

  return (
    <PageWithCenterBoxAndLogo>
      {isComplete ? (
        <>
          <span className="title">{t('이메일이 발송되었습니다.')}</span>
          <span className="subtitle mb-[20px]">
            {t('입력하신 이메일 주소로 비밀번호를 재설정 할 수 있는 링크가 포함된\n이메일이 발송되었습니다.')}
          </span>
        </>
      ) : (
        <>
          <span className="title">{t('비밀번호를 잊으셨나요?')}</span>
          <span className="subtitle">{t('안내받을 이메일 주소를 입력해 주세요.')}</span>
          <input className={`input mt-[50px] ${emailInput.isError ? 'input-error' : ''}`} {...emailInput.inputProps} />
          {emailInput.isError && (
            <span className="text-point-3 mt-[10px] flex w-full items-center">
              <IconWarning className="mr-[5px]" />
              {emailInput.errorMsg}
            </span>
          )}
          <button
            className="btn-main btn-l my-[20px] font-medium"
            onClick={() => {
              if (emailInput.verify()) {
                sendEmailVerification()
              }
            }}
          >
            {t('재설정 이메일 발송')}
          </button>
        </>
      )}
      <div>
        <Link className="link-main" to="/login">
          {t('로그인')}
        </Link>
        <span className="text-[14px] text-[gray-500]"> {t('또는')} </span>
        <Link className="link-main" to="/sign-up">
          {t('회원가입')}
        </Link>
      </div>
    </PageWithCenterBoxAndLogo>
  )
}

export default FindPw
