import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BackIcon } from '../../../../assets/images/icon-back.svg'
import ExportButton from './ExportButton'
import SaveButton from './SaveButton'
import ProjectTitle from './ProjectTitle'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import UpgradeButton from './UpgradeButton'

function Header() {
  /** Services */
  const { fetchProject } = useUpdateProject()
  const { t } = useTranslation('project')

  const onClickBack = () => {
    fetchProject()
  }

  return (
    <section className="flex min-h-[80px] items-center border-b-[1px] border-[#CACACA]">
      <div className="navigator_width h-full">
        <Link to="/">
          <button
            type="button"
            aria-label={t('뒤로가기')}
            className="flex h-full w-full items-center justify-center [&_path]:hover:fill-[#004fff]"
            onClick={onClickBack}
          >
            <div className="h-[16px] w-[21.23px]">
              <BackIcon />
            </div>
          </button>
        </Link>
      </div>

      <ProjectTitle />
      <div className="flex h-full items-center justify-center gap-[20px] pr-[50px]">
        <UpgradeButton />
        <SaveButton />
        <ExportButton />
      </div>
    </section>
  )
}

export default Header
