/* eslint-disable @typescript-eslint/no-use-before-define */
import { atom, atomFamily } from 'recoil'
import { CALL_STATE } from '../../../services/constants'

export const SENTENCEBOX_TYPE = {
  VIEWER: 'VIEWER',
  CREATOR: 'CREATOR',
}

export const SENTENCEBOX_CATEGORY = {
  GENERAL: 'GENERAL',
  AUDIO: 'AUDIO',
  OPTION: 'OPTION',
}

export const SENTENCEBOX_OPTIONS_LANG = {
  Korean: 'Korean',
  English: 'English',
  Chinese: 'Chinese',
  Japanese: 'Japanese',
  Arabic: 'Arabic',
  Danish: 'Danish',
  Dutch: 'Dutch',
  French: 'French',
  German: 'German',
  Greek: 'Greek',
  Hindi: 'Hindi',
  Indonesian: 'Indonesian',
  Italian: 'Italian',
  Malay: 'Malay',
  Polish: 'Polish',
  Portuguese: 'Portuguese',
  Russian: 'Russian',
  Spanish: 'Spanish',
  Swedish: 'Swedish',
  Turkish: 'Turkish',
  Thai: 'Thai',
  Vietnamese: 'Vietnamese',
}

/** SentenceBox General */
export const sentenceBoxState = atomFamily({
  key: 'sentenceBoxState',
  default: (id) => getSentenceBox(id),
  dangerouslyAllowMutability: false,
})

export const getSentenceBox = (id) => ({
  id,
  sentenceId: null,
  index: null,
  type: SENTENCEBOX_TYPE.VIEWER,
  text: '',
  isSelected: false,

  // Creator
  creatorText: '',
  isValidCreatorText: false,
})

/** Audio */
export const AUDIO_CRATE_STATUS = {
  READY: 'READY',
  PROCESSING: 'PROCESSING',
  FAIL: 'FAIL',
  COMPLETED: 'COMPLETED',
}

export const sentenceBoxAudioState = atomFamily({
  key: 'sentenceBoxAudioState',
  default: (id) => getSentenceBoxAudio(id),
})

export const getSentenceBoxAudio = (id) => ({
  id,
  audioId: null,

  // Auido
  url: null,
  playing: false,
  played: 0,
  duration: 0,
  estimatedDurationMS: 1000,
  status: AUDIO_CRATE_STATUS.READY,

  // Interval,
  intervalPlaying: false,
  intervalPlayed: 0,

  apiState: CALL_STATE.NEW,
})

/** Options */
export const sentenceBoxOptionState = atomFamily({
  key: 'sentenceBoxOptionState',
  default: (id) => getSentenceBoxOption(id),
})

export const getSentenceBoxOption = (id) => ({
  id,
  voiceId: null,
  lexo: null,

  space: 0.5,
  speed: 1,
  pitch: 1,
  volume: 1,
  language: 'ko-KR',
})

export const SentenceBoxValuesForFetch = atom({
  key: 'SentenceBoxValuesForFetch',
  default: {
    voiceId: null,
    lexo: null,

    space: 0.5,
    speed: 1,
    pitch: 1,
    volume: 1,
    language: 'ko-KR',
  },
  effects: [
    ({ onSet }) => {
      onSet((value) => {
        console.debug(`sentenceboxoption: ${JSON.stringify(value, null, 2)}`)
      })
    },
  ],
})
