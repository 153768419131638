import { useState } from 'react'
import { motion } from 'framer-motion'
import { ChromePicker } from 'react-color'

import { useRecoilState } from 'recoil'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
import { projectValueState } from '../../../../../../recoil/project/selectors'

function BackgroundColorPicker() {
  /** Recoils */

  const [customColor, setCustomColor] = useRecoilState(projectValueState({ key: 'backgroundColor' }))

  const [isOpen, setIsOpen] = useState(false)
  useAivatarDidMount(() => {
    setIsOpen(true)
    return () => {
      setIsOpen(false)
    }
  })

  /** Event */
  const handleChangeComplete = (color) => {
    setCustomColor(color.hex.slice(1))
  }

  /** Displays */

  return (
    <motion.div
      animate={{
        height: isOpen ? 'auto' : 0,
        opacity: isOpen ? 1 : 0,
      }}
      className="relative mt-[20px] h-0 w-full overflow-hidden rounded-[24px] px-[20px] py-[25px] opacity-0 shadow-[1px_3px_17px_0px_rgba(0,40,104,0.18)]"
    >
      <ChromePicker
        disableAlpha
        className="!w-full !shadow-none"
        color={`#${customColor}`}
        onChange={handleChangeComplete}
      />
    </motion.div>
  )
}

export default BackgroundColorPicker
