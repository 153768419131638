import { useState, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as ResetIcon } from '../../../../assets/images/icon-reset.svg'
import { SENTENCEBOX_CATEGORY } from '../../../../recoil/texteditor/sentenceBox/atoms'
import { focusedBoxValueState } from '../../../../recoil/texteditor/editor/selectors'
import useEditorCallbacks from '../../../../recoil/texteditor/editor/useEditorCallbacks'
import OptionSettingModal from '../OptionSettingModal'
import useAivatarModal from '../../../../hooks/useAivatarModal'
import { sentenceBoxOptionValueState } from '../../../../recoil/texteditor/sentenceBox/selectors'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import AivatarButton from '../../../../components/buttons/AivatarButton'

const UPDATE_PROJECT_ACTION_TYPE = {
  NEW: 'NEW',
  UPDATE: 'UPDATE',
}

function ResetOptions() {
  const [actionType, setActionType] = useState(UPDATE_PROJECT_ACTION_TYPE.NEW)
  const { t } = useTranslation('project-option')

  /** Recoilds */
  const { resetSentenceBoxOptions } = useEditorCallbacks()
  const focusedBoxId = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.GENERAL, key: 'id' }))
  const space = useRecoilValue(sentenceBoxOptionValueState({ id: focusedBoxId, key: 'space' }))
  const speed = useRecoilValue(sentenceBoxOptionValueState({ id: focusedBoxId, key: 'speed' }))
  const pitch = useRecoilValue(sentenceBoxOptionValueState({ id: focusedBoxId, key: 'pitch' }))
  const volume = useRecoilValue(sentenceBoxOptionValueState({ id: focusedBoxId, key: 'volume' }))

  /** Services */
  const { fetchProject } = useUpdateProject()

  /** Memos */
  const isActiveButton = useMemo(() => {
    if (!focusedBoxId) return false
    if (space === 0.5 && speed === 1 && pitch === 1 && volume === 1) return false
    return true
  }, [focusedBoxId, space, speed, pitch, volume])

  /** events */
  const onClick = () => {
    if (!isActiveButton) return
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    openOptionSettingModal()
  }

  const onClickClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    closeOptionSettingModal()
  }

  const onClickReset = async () => {
    resetSentenceBoxOptions()
    setActionType(UPDATE_PROJECT_ACTION_TYPE.UPDATE)
  }

  /** Modal */
  const {
    modalComponent: OptionSettingModalComponent,
    openModal: openOptionSettingModal,
    closeModal: closeOptionSettingModal,
  } = useAivatarModal({
    children: (
      <OptionSettingModal
        titleComponent={
          <p className="mb-[20px] px-[45px] text-center text-[24px] font-bold">
            <Trans t={t}>
              정말 모든 설정을
              <br />
              <span className="main_c">초기화</span>
              하시겠습니까?
            </Trans>
          </p>
        }
        subTitle={t('초기화를 누르면 모든 설정이 초기화됩니다.')}
        onClickSetting={onClickReset}
        onClickClose={onClickClose}
        setButtonText={t('초기화')}
      />
    ),
    closeOnDocumentClick: true,
  })

  /** Action Effects */
  useAivatarDidMount(async () => {
    if (actionType !== UPDATE_PROJECT_ACTION_TYPE.UPDATE) return
    setActionType(UPDATE_PROJECT_ACTION_TYPE.NEW)
    try {
      await fetchProject()
    } catch (error) {
      /* empty */
    }
    closeOptionSettingModal()
  }, [actionType])

  return (
    <AivatarButton
      type="button"
      variant={isActiveButton ? 'm3' : 'g5'}
      className="h-[30px]"
      disabled={!isActiveButton}
      onClick={onClick}
    >
      {OptionSettingModalComponent}
      <span className="mr-[5px] text-[14px] font-medium">{t('설정 초기화')}</span>
      <ResetIcon />
    </AivatarButton>
  )
}

export default ResetOptions
