import React, { useState } from 'react'
import DropdownSelect from '../../../components/DropdownSelect'
import AvatarList from './AvatarList'
import { PlanName } from '../../../@types/common'
import avatarListData from './AvatarList/avatarListData'

type FilterPlan = 'ALL' | PlanName

const dropdownItems: Array<{ value: FilterPlan; name: string }> = [
  { value: 'ALL', name: 'All AI Avatars' },
  { value: 'BASIC', name: 'Basic Plan' },
  { value: 'PRO', name: 'Pro Plan' },
  { value: 'ENTERPRISE', name: 'Enterprise Plan' },
]

// AiVATAR 아바타 리스트 및 데모 영상
export default function AvatarListPage() {
  const [filter, setFilter] = useState<FilterPlan>('ALL')

  const data = avatarListData.filter((avatar) => (filter === 'ALL' ? true : avatar.plans.includes(filter)))

  const handleFilterChanged = (newItem: (typeof dropdownItems)[0]) => {
    setFilter(newItem.value)
  }

  // useEffect(() => {
  // // test
  //   // iframe 내부 스크립트
  //   window.addEventListener('load', () => {
  //     const height = document.body.scrollHeight
  //     if (window.parent) {
  //       console.log(window.parent, 'posted', height)
  //       window.parent.postMessage(height, 'https://www.aivatar.ai') // 부모 도메인으로 높이 전송
  //     }
  //   })
  // }, [])

  return (
    <div className="mx-auto w-full max-w-[1280px]">
      <header className="border-main-3 mb-4 border-b pb-4">
        <DropdownSelect value={filter} items={dropdownItems} onChange={handleFilterChanged} />
      </header>
      <div className="flex justify-center">
        <AvatarList data={data} />
      </div>
    </div>
  )
}
