import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Section from '../../components/Dashboard/Section'
import {
  Config,
  ConfigEditButton,
  ConfigCancelButton,
  ConfigSaveButton,
  ConfigInput,
} from '../../components/Dashboard/ProfileConfig/Config'
import DropdownSelect from '../../components/DropdownSelect'
import { ReactComponent as CameraIcon } from '../../assets/images/icon-camera.svg'
import useAuth from '../../hooks/useAuth'
import { authQueries, updatePreferredLanguage } from '../../services/auth'

export default function ProfileConfig() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t, i18n } = useTranslation('user-update')
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { mutate: mutateToChangeLanguage } = useMutation({
    mutationFn: updatePreferredLanguage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: authQueries.me.queryKey })
    },
  })

  const profileImgInputRef = useRef(null)
  const nameInputRef = useRef(null)
  const newPwInpuRef = useRef(null)
  const [name, setName] = useState(user.name)
  const [newPw, setNewPw] = useState('')
  const [rePw, setRePw] = useState('')
  const [currentConfig, setCurrentConfig] = useState(null)
  const isPwError = newPw && rePw && newPw !== rePw

  const langItems = useMemo(
    () => [
      { value: 'ko', name: '한국어' },
      // { value: 'ja', name: '일본어' },
      // { value: 'es', name: '스페인어' },
      { value: 'en', name: 'English' },
      // { value: 'de', name: '독일어' },
    ],
    [],
  )

  useEffect(() => {
    if (currentConfig === 'name') {
      nameInputRef.current?.focus()
    } else {
      setName(user.name)
    }
  }, [currentConfig, user.name])

  useEffect(() => {
    if (currentConfig === 'pw') {
      newPwInpuRef.current?.focus()
    } else {
      setNewPw('')
      setRePw('')
    }
  }, [currentConfig])

  /** Events */
  const onClickSave = (type) => {
    if (type === 'name') {
      /* empty */
    }
  }

  const handleLanguageChanged = (item) => {
    // 이거 말고 API 호출하고 auth me invalidate 하고 새로운 정보 불러와서 그 값이 반영되도록 해야됨
    // i18n.changeLanguage(item.value)

    // lng 파라미터가 있을경우 없애야됩니다. 이 코드는 사용자가 UI를 통해 언어를 변경해야겠다고 선언한 부분이니까요.
    if (searchParams.get('lng')) {
      setSearchParams((prev) => {
        const { lng, ...newSearchParams } = prev
        return newSearchParams
      })
    }
    mutateToChangeLanguage({ preferredLanguage: item.value })
  }

  return (
    <div>
      <Section
        className="mt-[40px]"
        title={t('프로필 설정')}
        description={t('프로필, 이메일, 이름을 확인할 수 있습니다.')}
      >
        <Config className="mt-[49px] hidden">
          <div className="bg-main-3 relative h-[85px] w-[85px] overflow-hidden rounded-full [&>button]:hover:visible">
            {/* todo - remove hidden */}
            <button
              className="invisible absolute left-0 top-0 flex hidden h-full w-full items-center justify-center bg-[rgba(0,0,0,0.6)]"
              onClick={() => profileImgInputRef.current?.click()}
              aria-label="프로필 이미지 변경"
            >
              <CameraIcon />
              <input ref={profileImgInputRef} type="file" accept="image/*" hidden />
            </button>
            <img src={user?.imageUrl} alt="profile" />
          </div>
        </Config>
        <Config name={t('이메일')}>
          <div className="flex h-[45px] items-center">
            <span className="text-main font-bold">{user?.email || t('로그인 필요')}</span>
          </div>
        </Config>
        <Config name={t('이름')}>
          {currentConfig === 'name' ? (
            <>
              <ConfigInput
                ref={nameInputRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('이름')}
                description={t('변경할 이름을 입력해주세요.')}
                onFocused={() => {
                  setCurrentConfig('name')
                }}
              />
              <div className="mt-[20px] flex">
                <ConfigCancelButton
                  onClick={() => {
                    setCurrentConfig(null)
                  }}
                />
                <ConfigSaveButton
                  className="ml-[10px]"
                  onClick={() => {
                    onClickSave('name')
                  }}
                />
              </div>
            </>
          ) : (
            <div className="flex w-[374px] items-center">
              <span className="text-gray-2 font-light">{user?.name || t('로그인 필요')}</span>
              <ConfigEditButton
                className="ml-auto hidden"
                onClick={() => {
                  setCurrentConfig('name')
                }}
              />
            </div>
          )}
        </Config>
        <Config name={t('비밀번호')} className="hidden">
          {currentConfig === 'pw' ? (
            <>
              <ConfigInput
                ref={newPwInpuRef}
                type="password"
                name={t('새로운 비밀번호')}
                placeholder={t('새로운 비밀번호')}
                value={newPw}
                onChange={(e) => setNewPw(e.target.value)}
                isError={isPwError}
              />
              <ConfigInput
                className="mt-[10px]"
                type="password"
                name={t('새로운 비밀번호 확인')}
                placeholder={t('새로운 비밀번호 확인')}
                description={
                  <Link className="link-main text-[12px]" to="/find-pw">
                    {t('비밀번호를 잊으셨나요?')}
                  </Link>
                }
                value={rePw}
                onChange={(e) => setRePw(e.target.value)}
                isError={isPwError}
                errorMessage={isPwError ? t('입력한 비밀번호를 확인해 주세요.') : null}
              />
              <div className="mt-[20px] flex">
                <ConfigCancelButton
                  onClick={() => {
                    setCurrentConfig(null)
                  }}
                />
                <ConfigSaveButton className="ml-[10px]" />
              </div>
            </>
          ) : (
            <div className="flex w-[374px] items-center">
              <span className="text-gray-2 font-light">***************</span>
              <ConfigEditButton
                className="ml-auto"
                onClick={() => {
                  setCurrentConfig('pw')
                }}
              />
            </div>
          )}
        </Config>
        {/* todo - remove hidden */}
        <Config name={t('언어')}>
          <DropdownSelect
            value={user.preferredLanguage ?? i18n.language}
            items={langItems}
            onChange={handleLanguageChanged}
          />
        </Config>
      </Section>
    </div>
  )
}
