import { Link } from 'react-router-dom'
import Logo from '../assets/images/aivatar-logo.png'
import BottomTextLink from '../pages/Auth/BottomTextLink'
import useURI from '../hooks/useURI'

function PageWithCenterBoxAndLogo({ children }) {
  const { uri } = useURI()

  const links = {
    'AiVATAR.ai': uri['aivatar-home'],
    Help: uri['contact-us'],
    Terms: uri['aivatar-term'],
    Privacy: uri['aivatar-privacy'],
  }

  return (
    <div className="h-screen w-full bg-[rgba(245,247,254,0.40)]">
      <div className="box-center box-content">
        <Link to={links['AiVATAR.ai']}>
          <img className="w-[160px]" src={Logo} alt="logo" />
        </Link>
        {children}
      </div>
      <div className="links-center mb-1">
        {Object.keys(links).map((k, i) => (
          <BottomTextLink key={k} title={k} link={links[k]} dot={i !== Object.keys(links).length - 1} />
        ))}
      </div>
    </div>
  )
}

export default PageWithCenterBoxAndLogo
