import { selector, selectorFamily } from 'recoil'
import { CALL_STATE } from '../../../services/constants'
import { sentenceBoxIdsState } from '../editor/atoms'
// eslint-disable-next-line import/no-cycle
import { sentenceBoxesByTypeState } from '../editor/selectors'
import {
  AUDIO_CRATE_STATUS,
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
  SENTENCEBOX_TYPE,
} from './atoms'
import { checkIsValidSentenceBoxLength } from './sentenceBoxHelpers'

/** SenteceBox */
export const sentenceBoxValueState = selectorFamily({
  key: 'sentenceBoxValueState',
  get:
    ({ id, key }) =>
    ({ get }) => {
      const senetenceBox = get(sentenceBoxState(id))
      return senetenceBox[key]
    },
  set:
    ({ id, key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(sentenceBoxValueState({ id, key }))
      if (oldValue === newValue) return
      set(sentenceBoxState(id), (prev) => ({ ...prev, [key]: newValue }))
    },
})

export const sentenceBoxAudioValueState = selectorFamily({
  key: 'sentenceBoxAudioValueState',
  get:
    ({ id, key }) =>
    ({ get }) => {
      const senetenceBoxAudio = get(sentenceBoxAudioState(id))
      return senetenceBoxAudio[key]
    },
  set:
    ({ id, key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(sentenceBoxAudioValueState({ id, key }))
      if (oldValue === newValue) return
      set(sentenceBoxAudioState(id), (prev) => ({ ...prev, [key]: newValue }))
    },
})

export const sentenceBoxOptionValueState = selectorFamily({
  key: 'sentenceBoxOptionValueState',
  get:
    ({ id, key }) =>
    ({ get }) => {
      const senetenceBoxOption = get(sentenceBoxOptionState(id))
      return senetenceBoxOption[key]
    },
  set:
    ({ id, key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(sentenceBoxOptionValueState({ id, key }))
      if (oldValue === newValue) return
      set(sentenceBoxOptionState(id), (prev) => ({ ...prev, [key]: newValue }))
    },
})

export const isCallErrorAudioState = selectorFamily({
  key: 'isCallErrorAudioState',
  get:
    ({ id }) =>
    ({ get }) => {
      const apiState = get(sentenceBoxAudioValueState({ id, key: 'apiState' }))
      return apiState === CALL_STATE.ERROR
    },
})

export const isFailAudioStatusState = selectorFamily({
  key: 'isFailAudioStatusState',
  get:
    ({ id }) =>
    ({ get }) => {
      const status = get(sentenceBoxAudioValueState({ id, key: 'status' }))
      return status === AUDIO_CRATE_STATUS.FAIL
    },
})

export const isValidSentenceBoxAudioState = selectorFamily({
  key: 'isValidSentenceBoxAudioState',
  get:
    ({ id }) =>
    ({ get }) => {
      const isFailAudioStatus = get(isFailAudioStatusState({ id }))
      const isCallErrorAudio = get(isCallErrorAudioState({ id }))
      return !isFailAudioStatus && !isCallErrorAudio
    },
})

// export const isValidSentenceBoxTextState = selectorFamily({
//   key: "isValidSentenceBoxTextState",
//   get:
//     (id) =>
//     ({ get }) => {
//       const text = get(sentenceBoxValueState({ id, key: "text" }));
//       return checkIsValidSentenceBoxText(text);
//     }
// });

export const isValidSentenceBoxLengthState = selectorFamily({
  key: 'isValidSentenceBoxLengthState',
  get:
    ({ id }) =>
    ({ get }) => {
      const text = get(sentenceBoxValueState({ id, key: 'text' }))
      return checkIsValidSentenceBoxLength(text)
    },
})

export const isValidSentenceBoxState = selectorFamily({
  key: 'isValidSentenceBoxState',
  get:
    (id) =>
    ({ get }) => {
      const isValidSentenceBoxLength = get(isValidSentenceBoxLengthState({ id }))

      const isValidSentenceBoxAudio = get(isValidSentenceBoxAudioState({ id }))
      return isValidSentenceBoxLength && isValidSentenceBoxAudio
    },
})

export const isLastBoxState = selectorFamily({
  key: 'isLastBoxState',
  get:
    (id) =>
    ({ get }) => {
      const sentenceBox = get(sentenceBoxState(id))
      const boxIds = get(sentenceBoxIdsState)

      return boxIds.length - 1 === sentenceBox.index
    },
})

/** Creators */
export const isCreatorOpenState = selector({
  key: 'isCreatorOpenState',
  get: ({ get }) => {
    const creatorBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.CREATOR))
    return creatorBoxes.length > 0
  },
})

export const creatorBoxState = selector({
  key: 'creatorBoxState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)
    const sentenceBoxWithIds = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

    const creatorSentenceBox = sentenceBoxWithIds.find((sentenceBox) => sentenceBox.type === SENTENCEBOX_TYPE.CREATOR)

    return creatorSentenceBox
  },
})

export const isCallSuccessAudioState = selectorFamily({
  key: 'isCallSuccessAudioState',
  get:
    ({ id }) =>
    ({ get }) => {
      const apiState = get(sentenceBoxAudioValueState({ id, key: 'apiState' }))
      return apiState === CALL_STATE.SUCCESS
    },
})

export const isLoadingAudioState = selectorFamily({
  key: 'isLoadingAudioState',
  get:
    ({ id }) =>
    ({ get }) => {
      const apiState = get(sentenceBoxAudioValueState({ id, key: 'apiState' }))
      return apiState === CALL_STATE.FETCHING
    },
})

export const isCompletedAudioStatusState = selectorFamily({
  key: 'isCompletedAudioStatusState',
  get:
    ({ id }) =>
    ({ get }) => {
      const status = get(sentenceBoxAudioValueState({ id, key: 'status' }))
      return status === AUDIO_CRATE_STATUS.COMPLETED
    },
})

export const isValidPlayAudioState = selectorFamily({
  key: 'isValidPlayAudioState',
  get:
    ({ id }) =>
    ({ get }) => {
      const isValidSentenceBox = get(isValidSentenceBoxState({ id }))
      if (!isValidSentenceBox) return false

      const url = get(sentenceBoxAudioValueState({ id, key: 'url' }))

      if (!url) return false
      const isLoadingAudio = get(isLoadingAudioState({ id }))
      if (isLoadingAudio) return false
      return true
    },
})
