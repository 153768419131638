import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AudioIcon } from '../../../assets/images/icon-audio.svg'
import { ReactComponent as VideoIcon } from '../../../assets/images/icon-video.svg'
import { ReactComponent as LinkIcon } from '../../../assets/images/icon-link.svg'
import Table from '..'
import ExportPlay from './ExportPlay'
import ExportDownload from './ExportDownload'
// import NoDataImage from '../../assets/images/no-export-data.png'
import NoItemsFound from '../../../assets/images/no-items-found.png'
import ChipComplete from './status-chips/ChipComplete'
import ChipFail from './status-chips/ChipFail'
import ChipProcessing from './status-chips/ChipProcessing'
import ChipReady from './status-chips/ChipReady'
import { customDayjs } from '../../../utils/date'

export default function ExportTable({ ...otherProps }) {
  const { t } = useTranslation('export-list')
  const { workspaceId } = useParams()

  const renderStatus = useCallback((exportStatus, exportType, createdAt, duration) => {
    switch (exportStatus) {
      case 'COMPLETED':
        return <ChipComplete />
      case 'FAIL':
        return <ChipFail />
      case 'PROCESSING':
        return <ChipProcessing exportType={exportType} createdAt={createdAt} duration={duration} />
      default:
        return <ChipReady />
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        key: 'createdAt',
        name: t('생성일'),
        className: 'w-[180px] !text-main-2',
        sortable: true,
        render: (row) => customDayjs(row.createdAt).format('YYYY/MM/DD'),
      },
      {
        key: 'filename',
        name: t('파일 이름'),
        sortable: true,
        render: (row) => `${row.filename}.${row.filetype}`,
      },
      {
        key: 'status',
        name: t('상태'),
        className: 'w-[100px] [&_button]:mx-auto text-center',
        sortable: true,
        render: (row) => renderStatus(row.status, row.exportType, row.createdAt, row.duration),
      },
      {
        key: 'exportType',
        name: t('형식'),
        className: 'w-[100px] [&_button]:mx-auto',
        sortable: false,
        render: (row) =>
          row.exportType === 'sound' ? <AudioIcon className="mx-auto" /> : <VideoIcon className="mx-auto" />,
      },
      {
        key: 'play',
        name: t('재생'),
        className: 'w-[100px]',
        render: (row) => <ExportPlay row={row} />,
      },
      {
        key: 'download',
        name: t('다운로드'),
        className: 'w-[100px]',
        render: (row) => <ExportDownload name={t('다운로드')} row={row} />,
      },
      {
        key: 'projectId',
        name: t('프로젝트'),
        className: 'w-[100px]',
        render: (row) => (
          <a
            className="mx-auto block w-fit"
            href={`/workspace/${workspaceId}/project/${row.projectId}`}
            aria-label={t('프로젝트로 이동')}
          >
            <LinkIcon />
          </a>
        ),
      },
    ],
    [renderStatus, t, workspaceId],
  )

  return <Table columns={columns} noDataImage={NoItemsFound} {...otherProps} />
}
