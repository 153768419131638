import React, { ButtonHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as PlayIcon } from '../../../assets/images/player-play.svg'
import { ReactComponent as PauseIcon } from '../../../assets/images/player-pause.svg'

type Props = {
  playing: boolean
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  className?: string
}

export default function PlayButton({ playing, onClick, className }: Props) {
  return (
    <button
      type="button"
      className={twMerge(
        'shadow_2 hover:bg-main-3 group mr-[10px] flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white transition-all',
        className,
      )}
      aria-label={playing ? 'Pause' : 'Play'}
      onClick={onClick}
    >
      {playing ? (
        <PauseIcon className="h-full w-[42%] group-hover:[&_path]:fill-[#004fff]" />
      ) : (
        <PlayIcon className="mr-[-8%] h-full w-[58%] group-hover:[&_path]:fill-[#004fff]" />
      )}
    </button>
  )
}
