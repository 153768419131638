import { useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
// eslint-disable-next-line import/no-cycle
import { HotkeysContext } from '.'

export default function useTogglePlayHotkeys() {
  const { playButtonRef } = useContext(HotkeysContext)

  useHotkeys(
    ['space'],
    () => {
      playButtonRef?.current?.click()
    },
    {
      preventDefault: true,
    },
  )
}
