/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import {
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
  SENTENCEBOX_CATEGORY,
} from '../sentenceBox/atoms'
import { sentenceBoxIdsState } from './atoms'
import { viewerBoxIdsState } from './selectors'

export const getFocusedBoxByCategory = ({ category, focusedBoxId, get }) => {
  switch (category) {
    case SENTENCEBOX_CATEGORY.GENERAL:
      return get(sentenceBoxState(focusedBoxId))

    case SENTENCEBOX_CATEGORY.AUDIO:
      return get(sentenceBoxAudioState(focusedBoxId))

    case SENTENCEBOX_CATEGORY.OPTION:
      return get(sentenceBoxOptionState(focusedBoxId))

    default:
      break
  }
}

export const setFocusedBoxByCategory = ({ category, focusedBoxId, set, newValue }) => {
  switch (category) {
    case SENTENCEBOX_CATEGORY.GENERAL:
      return set(sentenceBoxState(focusedBoxId), (prev) => ({
        ...prev,
        ...newValue,
      }))

    case SENTENCEBOX_CATEGORY.AUDIO:
      return set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
        ...prev,
        ...newValue,
      }))

    case SENTENCEBOX_CATEGORY.OPTION:
      return set(sentenceBoxOptionState(focusedBoxId), (prev) => ({
        ...prev,
        ...newValue,
      }))

    default:
      break
  }
}

export const setFocusedBoxValueByCategory = ({ category, key, focusedBoxId, set, newValue }) => {
  switch (category) {
    case SENTENCEBOX_CATEGORY.GENERAL:
      return set(sentenceBoxState(focusedBoxId), (prev) => ({
        ...prev,
        [key]: newValue,
      }))

    case SENTENCEBOX_CATEGORY.AUDIO:
      return set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
        ...prev,
        [key]: newValue,
      }))

    case SENTENCEBOX_CATEGORY.OPTION:
      return set(sentenceBoxOptionState(focusedBoxId), (prev) => ({
        ...prev,
        [key]: newValue,
      }))

    default:
      break
  }
}

export const getViewerBoxesByCategory = ({ get, category }) => {
  const viewerBoxIds = get(viewerBoxIdsState)
  let boxes = []
  switch (category) {
    case SENTENCEBOX_CATEGORY.GENERAL:
      boxes = viewerBoxIds.map((id) => get(sentenceBoxState(id)))
      break
    case SENTENCEBOX_CATEGORY.AUDIO:
      boxes = viewerBoxIds.map((id) => get(sentenceBoxAudioState(id)))
      break
    case SENTENCEBOX_CATEGORY.OPTION:
      boxes = viewerBoxIds.map((id) => get(sentenceBoxOptionState(id)))
      break

    default:
      break
  }

  return boxes
}

export const getSentenceBoxesByType = ({ get, type }) => {
  const sentenceBoxIds = get(sentenceBoxIdsState)
  const sentenceBoxesWithIds = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

  const boxes = sentenceBoxesWithIds.filter((sentenceBox) => sentenceBox.type === type)
  return boxes
}

export const selectMultipleBoxes = ({ set, boxIds, isSelected }) => {
  for (const id of boxIds) {
    set(sentenceBoxState(id), (prev) => {
      if (prev.isSelected === isSelected) return prev
      return {
        ...prev,
        isSelected,
      }
    })
  }
}

export const getSelectedBoxes = ({ get }) => {
  const sentenceBoxIds = get(sentenceBoxIdsState)
  const sentenceBoxes = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

  const selectedBoxes = sentenceBoxes.filter((sentenceBox) => sentenceBox.isSelected === true)

  return selectedBoxes
}

export const getSelectedBoxesByCategory = ({ get, category }) => {
  const sentenceBoxIds = get(sentenceBoxIdsState)
  const sentenceBoxes = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

  const selectedBoxes = sentenceBoxes
    .filter((sentenceBox) => sentenceBox.isSelected === true)
    .map((box) => {
      switch (category) {
        case SENTENCEBOX_CATEGORY.GENERAL:
          return get(sentenceBoxState(box.id))
        case SENTENCEBOX_CATEGORY.AUDIO:
          return get(sentenceBoxAudioState(box.id))
        case SENTENCEBOX_CATEGORY.OPTION:
          return get(sentenceBoxOptionState(box.id))

        default:
          break
      }
    })

  return selectedBoxes
}
