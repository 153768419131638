import { useParams } from 'react-router-dom'
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { projectState } from '../../recoil/project/atoms'
import { isCallSuccessProjectState, isLoadingProjectState, projectValueState } from '../../recoil/project/selectors'
import useEditorCallbacks from '../../recoil/texteditor/editor/useEditorCallbacks'
import {
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
} from '../../recoil/texteditor/sentenceBox/atoms'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'
import { sentenceBoxIdsState } from '../../recoil/texteditor/editor/atoms'

export default function useUpdateProject() {
  const { workspaceId, projectId } = useParams()
  /** Recoils */
  const [project, setProject] = useRecoilState(projectState)

  const setProjectApiState = useSetRecoilState(projectValueState({ key: 'apiState' }))

  const isCallSuccess = useRecoilValue(isCallSuccessProjectState)
  const isLoading = useRecoilValue(isLoadingProjectState)

  const { updateSentenceBoxesByFetch } = useEditorCallbacks()

  const fetch = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => async (projectTitle) => {
    const ids = snapshot.getLoadable(sentenceBoxIdsState).getValue()

    transact_UNSTABLE(async ({ get }) => {
      const sentences = ids
        .map((id) => {
          const box = get(sentenceBoxState(id))
          const option = get(sentenceBoxOptionState(id))
          const audio = get(sentenceBoxAudioState(id))

          return {
            box,
            option,
            audio,
          }
        })
        .map((b) => {
          const sentenceBox = b.box
          const sentenceBoxOption = b.option
          const sentenceBoxAudio = b.audio
          const { voiceId, space, pitch, speed, volume, language } = sentenceBoxOption
          const sentence = {
            paragraph: 0,
            type: 'text',
            content: sentenceBox.text,
            language,
            attribute: {
              projectId: Number(projectId),
              voiceId,
              space,
              pitch,
              speed,
              volume,
              language,
            },
          }
          if (sentenceBoxOption.lexo) sentence.lexo = sentenceBoxOption.lexo
          if (sentenceBox.sentenceId) {
            // sentence.attribute["sentenceId"] = sentenceBox.sentenceId;
            sentence.id = sentenceBox.sentenceId
          }
          if (sentenceBoxAudio.audioId) sentence.attribute.audioId = sentenceBoxAudio.audioId
          return sentence
        })

      const body = {
        title: project.title,
        id: project.id,
        sentences,
        firstInterval: project.firstInterval ?? 0,
        aivatarId: project.avatarId,
        dressId: project.dressId,
        backgroundColor: project.backgroundColor,
      }

      try {
        if (!workspaceId) return

        if (projectTitle) body.title = projectTitle
        setProjectApiState(CALL_STATE.FETCHING)
        const res = await AivatarClient().post(`workspaces/${workspaceId}/projects`, body)
        const { data, status } = res
        if (status !== 200) throw new Error('Invalid Response Status')

        const {
          id,
          title,
          str,
          sentences: sentenceData,
          firstInterval,
          aivatarId: avatarId,
          dressId,
          backgroundColor,
        } = data
        setProject((prev) => ({
          ...prev,
          id,
          title,
          str,
          firstInterval,
          avatarId,
          dressId,
          backgroundColor,
        }))
        updateSentenceBoxesByFetch({ sentenceData })

        setProjectApiState(CALL_STATE.SUCCESS)
      } catch (error) {
        console.error(error)
        setProjectApiState(CALL_STATE.ERROR)
        throw new Error(error)
      }
    })
  })

  return {
    fetchProject: fetch,
    isLoading,
    isCallSuccess,
  }
}

export const updateProject = async ({ workspaceId, accessToken, body }) => {
  try {
    if (!workspaceId) return
    const res = await AivatarClient({
      accessToken,
    }).post(`workspaces/${workspaceId}/projects`, body)
    const { data, status } = res
    if (status !== 200) throw new Error('updateProject - Invalid Response Status')
    // eslint-disable-next-line consistent-return
    return data
  } catch (error) {
    throw new Error(error)
  }
}
