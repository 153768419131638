/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import PlusIcon from '../../../assets/images/plus.svg'
import AivatarClient from '../../../services/AivatarClient'
import AivatarButton from '../../buttons/AivatarButton'
import { useCurrentWorkspaceDetailQuery } from '../../../hooks/queries'

type Props = {
  className?: string
}

function NewProjectButton({ className }: Props) {
  const { t } = useTranslation('dashboard')
  const { workspaceId } = useParams()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const isPause = workspace?.subscription?.status === 'PAUSE'

  const createNewProject = () =>
    AivatarClient()
      .post(`/workspaces/${workspaceId}/projects`, {
        title: t('새 프로젝트'),
        firstInterval: 0,
        backgroundColor: '008000',
      })
      .then((res) => res.data)

  const navigateToProject = (project: any) => {
    window.location.href = `/workspace/${workspaceId}/project/${project.id}`
  }

  return (
    <AivatarButton
      variant={isPause ? 'g3' : undefined}
      className={twMerge(`mx-auto h-[45px] w-[166px] text-[17px] font-bold`, className)}
      onClick={() => createNewProject().then(navigateToProject)}
      disabled={isPause}
    >
      <img className="mr-[5px]" src={PlusIcon} alt="new-project" />
      {t('새 프로젝트')}
    </AivatarButton>
  )
}

export default NewProjectButton
