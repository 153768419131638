import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export default function RowHeader({ children }: Props) {
  return (
    <th scope="row" className="border-gray-5 text-gray-21 h-[54px] border-t-[1px] pl-[38px]">
      {children}
    </th>
  )
}
