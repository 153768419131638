import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import {
  useCurrentWorkspaceCreditsQuery,
  useCurrentWorkspaceDetailQuery,
  useCurrentWorkspacePlanName,
} from '../../../hooks/queries'
import useDialog from '../../../hooks/useDialog'
import SubscriptionPlanDialog from '../../SubscriptionPlan/SubscriptionPlanDialog'

function CreditStatus() {
  const { t } = useTranslation('dashboard')
  const dialog = useDialog()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const { data: credits } = useCurrentWorkspaceCreditsQuery()
  const planName = useCurrentWorkspacePlanName()

  const isPause = workspace?.subscription?.status === 'PAUSE'

  const showSubscriptionPlanSelector = () => {
    dialog.open(<SubscriptionPlanDialog expanded />)
  }

  const { current } = credits
  let available = workspace?.subscription?.status === 'PAUSE' ? 0 : credits.available
  if (available < 0) {
    available = 0
  }

  return (
    <div className="mx-[27px] mt-[22px]">
      <div className="bg-gray-5 relative h-[5px] overflow-hidden rounded-[48px]">
        <div
          className="bg-main absolute z-10 h-full rounded-[48px]"
          style={{
            width: `${current ? (available / current) * 100 : 0}%`,
          }}
        />
      </div>
      <div className="mt-[9px] flex items-center justify-between">
        <span className="text-point-2 text-[14px] font-medium">
          {t('크레딧')} {available}
        </span>
        <button
          lang="en"
          translate="no"
          className={twMerge(
            'btn-main-3 h-[23px] w-auto cursor-pointer px-[15px] text-[10px] font-medium',
            isPause ? 'bg-point-3 text-white' : null,
          )}
          onClick={showSubscriptionPlanSelector}
        >
          {isPause ? t('비활성 플랜') : planName}
        </button>
      </div>
    </div>
  )
}

export default CreditStatus
