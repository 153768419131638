import React, { ButtonHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  index: number
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  children: ButtonHTMLAttributes<HTMLButtonElement>['children']
  selected: boolean
}

export default function LanguageSelectButton({ index, onClick, children, selected }: Props) {
  return (
    <button
      type="button"
      data-index={index}
      onClick={onClick}
      className={twMerge(
        'bg-main-4 text-main-1 hover:border-main-1 h-[44px] w-[118px] justify-center rounded-[10px] text-[18px] font-[700] shadow-[1px_3px_17px_rgba(0,40,104,0.18)] hover:border-2 hover:shadow-[0px_0px_5px_var(--color-main-1)]',
        selected ? 'bg-main-1 text-gray-7' : null,
      )}
    >
      {children}
    </button>
  )
}
