import { useMemo } from 'react'
import { isMacOs } from 'react-device-detect'
import { useHotkeys } from 'react-hotkeys-hook'

export default function usePreventDefaultHotkeys() {
  const keyCombinations = useMemo(() => {
    if (isMacOs) {
      return [
        'meta+left', // 뒤로가기
        'meta+right', // 앞으로가기
      ]
    }

    return [
      'ctrl+left', // 뒤로가기
      'ctrl+right', // 앞으로가기
    ]
  }, [])

  useHotkeys(keyCombinations, () => {}, { preventDefault: true })
}
