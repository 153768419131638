import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  validBoxesByCategoryWithFilterState,
  validSelectedBoxesByCategoryWithFilterState,
} from '../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../recoil/texteditor/sentenceBox/atoms'
import useConstantProperties from '../utils/useConstantProperties'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'
import { projectValueState } from '../../recoil/project/selectors'

export default function useExportAudio() {
  const { workspaceId, projectId } = useParams()
  /** Recoils */
  const generalViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.GENERAL,
    }),
  )

  const generalSelectedBoxes = useRecoilValue(
    validSelectedBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.GENERAL,
    }),
  )

  const optionViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.OPTION,
    }),
  )
  const optionSelectedBoxes = useRecoilValue(
    validSelectedBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.OPTION,
    }),
  )

  const audioViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
    }),
  )

  const audioSelectedBoxes = useRecoilValue(
    validSelectedBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
    }),
  )

  const firstInterval = useRecoilValue(projectValueState({ key: 'firstInterval' }))

  const [res, setRes] = useState(null)
  const [apiState, setApiState] = useState(CALL_STATE.NEW)
  const { isCallSuccess, isCallError, isLoading } = useConstantProperties(apiState)

  const getBody = ({ filename, filetype, isAll }) => {
    const attributes = []

    const arrayLength = isAll ? generalViewerBoxes.length : generalSelectedBoxes.length
    let duration = 0
    for (let i = 0; i < arrayLength; i += 1) {
      const sentenceBox = isAll ? generalViewerBoxes[i] : generalSelectedBoxes[i]
      const sentenceBoxOption = isAll ? optionViewerBoxes[i] : optionSelectedBoxes[i]
      const sentenceBoxAudio = isAll ? audioViewerBoxes[i] : audioSelectedBoxes[i]
      const { voiceId, space, pitch, speed, volume, language } = sentenceBoxOption
      const attribute = {
        text: sentenceBox.text,
        projectId,
        voiceId,
        sentenceId: sentenceBox.sentenceId,
        audioId: sentenceBoxAudio.audioId,
        space,
        pitch,
        speed,
        volume,
        language,
      }
      duration += sentenceBoxAudio.estimatedDurationMS
      attributes.push(attribute)
    }

    const body = {
      workspaceId,
      projectId,
      credit: Math.floor(duration / 1000),
      before: firstInterval ?? 0,
      filename,
      filetype,
      attributes,
      durationPredicted: duration,
    }

    return body
  }

  // eslint-disable-next-line consistent-return
  const fetch = async ({ filename, filetype, isAll = true }) => {
    try {
      if (!workspaceId) return new Error('useExportVideo - invalid workspaceId')
      const body = getBody({ filename, filetype, isAll })
      setApiState(CALL_STATE.FETCHING)
      const apiRes = await AivatarClient().post(`audios/sound`, body)
      setRes(apiRes)

      setApiState(CALL_STATE.SUCCESS)
    } catch (error) {
      setApiState(CALL_STATE.ERROR)
      setRes(null)
      return Promise.reject(error)
    }
  }

  /** Status */
  const isValidStatus = useMemo(() => res?.status === 200, [res])
  /** data */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data = useMemo(() => {
    if (!isValidStatus) return null
    return res.data
  }, [res, isValidStatus])

  return {
    fetchExportAudio: fetch,
    isLoading,
    isCallSuccess,
    isCallError,
  }
}
