import { useRef, useMemo } from 'react'
import ReactPlayer from 'react-player'

import { useTranslation } from 'react-i18next'
import { AUDIO_CRATE_STATUS } from '../../../../recoil/texteditor/sentenceBox/atoms'
import ExportDownload from '../ExportDownload'
import env from '../../../../constants/env'
import useAuth from '../../../../hooks/useAuth'
import AivatarButton from '../../../buttons/AivatarButton'

function ExportVideoPlayModal({ row, handleCloseModal }) {
  const { user } = useAuth()
  const { t } = useTranslation('export-list')

  const player = useRef(null)

  /** Validations */
  const isValidPlay = useMemo(() => {
    if (!row.status) return false
    return row.status === AUDIO_CRATE_STATUS.COMPLETED
  }, [row])

  /** Memos */
  const url = useMemo(() => {
    if (!isValidPlay) return null
    if (!row.id) return null
    if (!user.accessToken) return null
    return `${env.API_HOST}/api/v1/movies/stream/${row.id}?token=${user.accessToken}`
  }, [isValidPlay, row.id, user.accessToken])

  return (
    <div className="relative flex h-[740px] w-[350px] flex-col items-center justify-center">
      {/* {isLoadingListenExportVideo && <IconLoading size={"100px"} />} */}
      <ReactPlayer
        id="AvatarPlayer"
        ref={player}
        className=""
        url={url}
        controls
        loop
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              autoPlay: false,
              controlsList: 'nodownload noremoteplayback',
            },
          },
        }}
      />
      <div className="mt-[33px] flex items-center justify-center">
        <AivatarButton
          type="button"
          variant="g5"
          className="text-gray-2 flex h-[44px] w-[117px] items-center justify-center rounded-full bg-[#F1F1F1] text-[16px] font-bold"
          onClick={() => {
            handleCloseModal()
          }}
        >
          {t('닫기', { ns: 'modal' })}
        </AivatarButton>
        <div className="min-w-[15px]" />
        <ExportDownload row={row} type="BUTTON" name={t('다운로드')} />
      </div>
    </div>
  )
}

export default ExportVideoPlayModal
