import { useState, useMemo } from 'react'

import AivatarClient, { API_TYPE } from '../AivatarClient'
import { CALL_STATE } from '../constants'
import useConstantProperties from '../utils/useConstantProperties'

export default function useListenExportVideo() {
  /** Recoils */

  /** poolVotes */
  const [res, setRes] = useState(null)
  const [apiState, setApiState] = useState(CALL_STATE.NEW)
  const { isCallSuccess, isLoading } = useConstantProperties(apiState)

  const fetch = async ({ id }) => {
    try {
      setApiState(CALL_STATE.FETCHING)
      const apiRes = await AivatarClient({
        type: API_TYPE.BLOB,
      }).get(`movies/stream/${id}`)

      setApiState(CALL_STATE.SUCCESS)
      setRes(apiRes)
      if (apiRes?.status !== 200) throw new Error('useListenExportVideo - invalid status')
      if (!apiRes?.data) throw new Error('useListenExportVideo - invalid data')
      return URL.createObjectURL(apiRes.data)
    } catch (error) {
      console.error('useListenExportVideo', error)
      setApiState(CALL_STATE.ERROR)
      setRes(null)
      throw new Error('useListenExportVideo', error)
    }
  }

  const init = () => {
    setRes(null)
    setApiState(CALL_STATE.NEW)
  }

  /** Status */
  const isValidStatus = useMemo(() => res?.status === 200, [res])
  /** data */
  const data = useMemo(() => {
    if (!isValidStatus) return null
    return res.data
  }, [res, isValidStatus])

  const url = useMemo(() => {
    if (!data) return null
    return URL.createObjectURL(data)
  }, [data])

  return {
    isLoadingListenExportVideo: isLoading,
    isCallSuccessListenExportVideo: isCallSuccess,
    url,

    fetchListenExportVideo: fetch,
    initListenExportVideo: init,
  }
}
