import { useMemo } from 'react'
import { CALL_STATE } from '../constants'

export default function useConstantProperties(callState) {
  const isCallSuccess = useMemo(() => callState === CALL_STATE.SUCCESS, [callState])

  const isCallError = useMemo(() => callState === CALL_STATE.Error, [callState])

  const isLoading = useMemo(() => callState === CALL_STATE.FETCHING, [callState])

  return {
    isCallSuccess,
    isCallError,
    isLoading,
  }
}
