import React, { ReactElement } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { LocalStorage } from '../../utils/storage'

type Props = {
  children: ReactElement
}

export default function RequiredAuth({ children }: Props) {
  const { user, isLoading } = useAuth()

  const { workspaceId } = useParams()

  // 유저가 없을땐 뭐라도 리턴해야됨
  if (!user) {
    if (isLoading) {
      return (
        <div className="grid h-full place-items-center">
          <CircularProgress />
        </div>
      )
    }
    return <Navigate to="/login" replace />
  }

  // 여기서부턴 유저 있다고 가정하는거임
  if (workspaceId) {
    const allowedWorkspaceIdList = user.workspaceList.map((workspaceItem) => workspaceItem.id)
    if (allowedWorkspaceIdList.includes(Number(workspaceId))) {
      LocalStorage.lastAccessedWorkspaceId = Number(workspaceId)
    } else {
      return <Navigate to="/" replace />
    }
  }

  return children
}
