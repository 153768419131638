import { useTranslation } from 'react-i18next'
import icon from '../../../assets/images/icon-update-note.svg'
import useURI from '../../../hooks/useURI'

function UpdateNoteButton() {
  const { t } = useTranslation('dashboard')
  const { uri } = useURI()

  return (
    <a className="mx-[27px]" target="_blank" href={uri['update-note']} rel="noreferrer">
      <div className="flex items-center gap-[4px]">
        <div className="flex items-center gap-[7px]">
          <img src={icon} alt="" />
          <span className="text-point-2 text-[14px] font-medium">{t('업데이트 노트')}</span>
        </div>
        <div className="bg-point-3 inline-block h-[6px] w-[6px] rounded-full" />
      </div>
    </a>
  )
}

export default UpdateNoteButton
