import React from 'react'
import AivatarButton from '../buttons/AivatarButton'
import { ReactComponent as ExclamationIcon } from '../../assets/images/icon-exclamation.svg'

type Props = {
  message: string
  proceedText: string
  onProceed: () => void
}

export default function NotificationBar({ message, proceedText, onProceed }: Props) {
  return (
    <div role="alert" className="fixed bottom-[42px] left-1/2 -translate-x-1/2">
      <div className="rounded-[10px] border border-[#ec2424] bg-[#fff7f7] px-[20px] py-[16px]">
        <div className="flex items-center">
          <ExclamationIcon />
          <p className="text-point-3 ml-[12px] mr-[42px] whitespace-nowrap text-[14px] font-[500]">
            <strong>{message}</strong>
          </p>
          <AivatarButton variant="p3" onClick={() => onProceed()}>
            {proceedText}
          </AivatarButton>
        </div>
      </div>
    </div>
  )
}
