type Props = {
  rows: Array<
    any & {
      id: string
    }
  >
  selected: string[]
  onChangeSelected?: (selected: Props['selected']) => void
}

export default function CheckboxColumn({ rows, selected, onChangeSelected }: Props) {
  return (
    <th className="w-[60px] !pl-[10px]">
      {selected.length > 0 ? (
        <div className="flex h-full w-full items-center justify-center">
          <button
            type="button"
            onClick={() => {
              onChangeSelected?.([])
            }}
            className="flex h-[13px] w-[13px] items-center justify-center rounded-[3px] border-[1.5px] border-[#3D3D3D] bg-white text-[#3D3D3D]"
          >
            <p className="mb-[3px]">-</p>
          </button>
        </div>
      ) : (
        <input
          type="checkbox"
          checked={rows.length > 0 ? rows.reduce((prev, row) => prev && selected.includes(row.id), true) : false}
          onChange={(e) => {
            if (e.target.checked) {
              onChangeSelected?.(rows.map((row) => row.id))
            } else {
              onChangeSelected?.([])
            }
          }}
        />
      )}
    </th>
  )
}
