import { useTranslation } from 'react-i18next'
import Dialog from '../Dialog'
import useDialog from '../../hooks/useDialog'
import { ReactComponent as IconClose } from '../../assets/images/icon-close.svg'
import SubscriptionPlanFeatureSheet from './SubscriptionPlanFeatureSheet'
import { useCurrentWorkspaceContracted } from '../../hooks/queries'
import Confirm from '../Dialog/Confirm'
import useURI from '../../hooks/useURI'
import useAuth from '../../hooks/useAuth'

function Section({ className = '', title, description, children }) {
  return (
    <section className={`w-full [&+&]:mt-[125px] ${className}`}>
      <span className="text-gray-2 block text-center text-[28px] font-medium">{title}</span>
      {description && <span className="text-gray-21 mt-[3px] block text-center">{description}</span>}
      {children}
    </section>
  )
}

export default function SubscriptionPlanDialog({ expanded = false }) {
  const dialog = useDialog()
  const { t } = useTranslation('plan-info-modal')
  const contracted = useCurrentWorkspaceContracted()
  const { user } = useAuth()
  const { uri } = useURI()

  return contracted ? (
    <Confirm
      title={t('커스텀 플랜')}
      message={t(
        '본 플랜은 별도 계약을 통해 제공되는 플랜입니다.\n플랜을 변경하거나 구독을 취소, 연장하기 위해서는 세일즈팀에 문의해 주세요.',
      )}
      confirmButtonName={t('세일즈팀에 문의')}
      onClickCancel={dialog.close}
      onClickConfirm={() => {
        window.open(`${uri['contact-us']}?email=${user.email}`)
      }}
      useCancel
    />
  ) : (
    <Dialog
      classes={{ paper: 'w-[1200px] !mx-auto !pt-[91px] !pb-[55px] !px-[50px] overflow-hidden' }}
      scroll={expanded ? 'body' : undefined}
    >
      <div className="bg-main-4 absolute left-0 top-0 h-[395px] w-full" />
      <button
        type="button"
        className="absolute right-[50px] top-[25px]"
        onClick={dialog.close}
        aria-label={t('닫기', { ns: 'modal' })}
      >
        <IconClose />
      </button>
      <Section
        className="z-10"
        title={t('최적의 플랜을 선택하세요')}
        description={t('플랜을 업그레이드하여 더욱 다양하고 매력적인 아바타와 제스처를 경험해 보세요.')}
      >
        {/* <SubscriptionPlanList className="mt-[79px]" plans={plans}/> */}
        <SubscriptionPlanFeatureSheet className="mt-[40px]" />
        <ul className="typo-body3 mt-[15px] text-[#5e5e5e]">
          <li>*{t('크레딧은 영상 또는 음성을 내보내기 할 때 사용됩니다.')}</li>
          <li>*{t('모든 가격은 부가세(10%) 미포함입니다.')}</li>
        </ul>
      </Section>
      {/* {expanded && ( */}
      {/*   <Section title="요금제 및 기능 비교"> */}
      {/*     <SubscriptionPlanFeatureSheet className="mt-[40px]" /> */}
      {/*   </Section> */}
      {/* )} */}
    </Dialog>
  )
}
