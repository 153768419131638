/* eslint-disable import/no-cycle */
import { editorState } from '../editor/atoms'
import { getSelectedBoxes } from '../editor/editorHelperCallbacks'

const MATCH_LIST_DOT =
  '\\u002E\\u05C3\\u0589\\u06D4\\u0701\\u0702\\u1362\\u1367\\u166E\\u1803\\u1809\\u2024\\u2CF9\\u2CFE\\u3002\\uA4FF\\uA60E\\uA6F3\\uFE12\\uFE52\\uFF0E\\uFF61\\uD82F\\uDC9F'
const MATCH_LIST_EXCLAMATION = '\\u0021\\u055C\\u1944\\u203C\\u203D\\u2049\\uFE15\\uFE57\\uFF01'
//! 그릭 물음표 (037E) 인식 이슈로 인해 일단은 빼둔다. See: https://www.notion.so/d27404d699a84ebe8be146ac1cc065fd?d=63dff9c408614a119297a64411412c86&pvs=4#293746a7fdfc41b8888eebf921a3710d
const MATCH_LIST_QUESTION =
  '\\u003F\\u055E\\u061F\\u1945\\u2047\\u2048\\u2CFA\\u2CFB\\uA60F\\uA6F7\\uFE16\\uFE56\\uFF1F\\uD804\\uDD43'
const MATCH_LIST_QUOTATION =
  '\\u00AB\\u00BB\\u2018\\u2019\\u201B\\u201C\\u201F\\u201D\\u2039\\u203A\\u2E02\\u2E03\\u2E04\\u2E05\\u2E09\\u2E0A\\u2E0C\\u2E0D\\u2E1C\\u2E1D\\u2E20\\u2E21\\u0022\\uFF02'
const MATCH_LIST_PUNCTUATION_OPEN =
  '\\u0028\\u005B\\u007B\\u0F3A\\u0F3C\\u169B\\u201A\\u201E\\u2045\\u207D\\u208D\\u2308\\u230A\\u2329\\u2768\\u276A\\u276C\\u276E\\u2770\\u2772\\u2774\\u27C5\\u27E6\\u27E8\\u27EA\\u27EC\\u27EE\\u2983\\u2985\\u2987\\u2989\\u298B\\u298D\\u298F\\u2991\\u2993\\u2995\\u2997\\u29D8\\u29DA\\u29FC\\u2E22\\u2E24\\u2E26\\u2E28\\u2E42\\u2E55\\u2E57\\u2E59\\u2E5B\\u3008\\u300A\\u300C\\u300E\\u3010\\u3014\\u3016\\u3018\\u301A\\u301D\\uFD3F\\uFE17\\uFE35\\uFE37\\uFE39\\uFE3B\\uFE3D\\uFE3F\\uFE41\\uFE43\\uFE47\\uFE59\\uFE5B\\uFE5D\\uFF08\\uFF3B\\uFF5B\\uFF5F\\uFF62'
const MATCH_LIST_PUNCTUATION_CLOSE =
  '\\u0029\\u005D\\u007D\\u0F3B\\u0F3D\\u169C\\u2046\\u207E\\u208E\\u2309\\u230B\\u232A\\u2769\\u276B\\u276D\\u276F\\u2771\\u2773\\u2775\\u27C6\\u27E7\\u27E9\\u27EB\\u27ED\\u27EF\\u2984\\u2986\\u2988\\u298A\\u298C\\u298E\\u2990\\u2992\\u2994\\u2996\\u2998\\u29D9\\u29DB\\u29FD\\u2E23\\u2E25\\u2E27\\u2E29\\u2E56\\u2E58\\u2E5A\\u2E5C\\u3009\\u300B\\u300D\\u300F\\u3011\\u3015\\u3017\\u3019\\u301B\\u301E\\u301F\\uFD3E\\uFE18\\uFE36\\uFE38\\uFE3A\\uFE3C\\uFE3E\\uFE40\\uFE42\\uFE44\\uFE48\\uFE5A\\uFE5C\\uFE5E\\uFF09\\uFF3D\\uFF5D\\uFF60\\uFF63'

export const updateFocused = ({ get, set }) => {
  const { focusedBoxId } = get(editorState)
  const selectedBoxes = getSelectedBoxes({ get })

  // reset focused
  if (selectedBoxes.length === 0) {
    set(editorState, (prev) => ({
      ...prev,
      focusedBoxId: null,
    }))
    return
  }

  // set focused
  const newFocusedBoxId = selectedBoxes[0].id
  if (focusedBoxId === newFocusedBoxId) return
  set(editorState, (prev) => ({
    ...prev,
    focusedBoxId: newFocusedBoxId,
  }))
}

export const checkIsValidSentenceBoxText = (text) => {
  // eslint-disable-next-line no-useless-escape
  const notAvailableRegex = /[^\u3131-\u3163\uac00-\ud7a3\d\u3002\.\?!,'" ]+/g

  return !notAvailableRegex.test(text)
}

export const checkIsValidSentenceBoxLength = (text) => text.length <= 400 && text.length >= 0

export const getSplitedCreatorTexts = (text) => {
  const fullStopMatchListSet = MATCH_LIST_DOT + MATCH_LIST_EXCLAMATION + MATCH_LIST_QUESTION
  const quotationMatchListSet = MATCH_LIST_QUOTATION + MATCH_LIST_PUNCTUATION_OPEN + MATCH_LIST_PUNCTUATION_CLOSE

  const splitFilter = [
    // {
    //   regex: /\s+/g,
    //   replaceText: " "
    // },
    {
      // eslint-disable-next-line no-misleading-character-class
      regex: new RegExp(`([${fullStopMatchListSet}]*[ ${fullStopMatchListSet}]*[${fullStopMatchListSet}]+)`, 'g'),
      replaceText: '$1|',
    },
    {
      regex: /[\n|\r|\r\n]/g,
      replaceText: '|',
    },
  ]

  let convertedText = text

  // 온점분리
  for (const filter of splitFilter) {
    const { regex, replaceText } = filter
    convertedText = convertedText.replaceAll(regex, replaceText)
  }

  // 인용문구 분리제외
  //! convertedText를 mutate하는 로직이다. 주의해서 살펴보도록
  const quotationMatches = Array.from(
    // eslint-disable-next-line no-useless-escape
    convertedText.matchAll(new RegExp(`[${quotationMatchListSet}].*?[${quotationMatchListSet}]`, 'g')),
    (match) => match[0],
  )
  quotationMatches.forEach((quotedStr) => {
    //! 아래 코드 필요없다. matchAll은 한번 매칭에 참여한 문자 안건드린다. 못믿겠으면 브라우저에서 test = "'a'b'c'".matchAll(/'.*?'/g) 하고 [...test]해보던가
    // if (i % 2 === 1) return // 인덱스가 홀수(짝수번째 아이템)인 경우는 정규식 매치가 되지만 인용구 바깥의 것들일 것이다. 이것들은 제외

    const prevStr = quotedStr
    const nextStr = prevStr.replaceAll('|', '')
    convertedText = convertedText.replace(prevStr, nextStr)
  })

  // 실수 분리제외
  //! convertedText를 mutate하는 로직이다. 주의해서 살펴보도록
  const floatMatches = Array.from(convertedText.matchAll(/\d+\.\|\d+/g), (match) => match[0])
  floatMatches.forEach((floatStr) => {
    const prevStr = floatStr
    const nextStr = prevStr.replaceAll('|', '')
    convertedText = convertedText.replace(prevStr, nextStr)
  })

  return convertedText
    .split('|')

    .map((t) => t.trim())
    .filter((t) => t.length > 0)
}
