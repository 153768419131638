import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import useAivatarDidMount from '../../hooks/useAivatarDidMount'
import { projectState } from '../../recoil/project/atoms'
import { isCallSuccessProjectState, isLoadingProjectState, projectValueState } from '../../recoil/project/selectors'
import useEditorCallbacks from '../../recoil/texteditor/editor/useEditorCallbacks'
import { AUDIO_CRATE_STATUS, SentenceBoxValuesForFetch } from '../../recoil/texteditor/sentenceBox/atoms'

import AivatarClient, { API_TYPE } from '../AivatarClient'
import { CALL_STATE } from '../constants'

export default function useProjectData({ workspaceId, projectId }) {
  /** Recoils */
  const setProject = useSetRecoilState(projectState)
  const setProjectApiState = useSetRecoilState(projectValueState({ key: 'apiState' }))

  const isCallSuccess = useRecoilValue(isCallSuccessProjectState)
  const isLoading = useRecoilValue(isLoadingProjectState)

  const { addSentenceBoxesByFetch } = useEditorCallbacks()
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  const getAudioFilePromises = (sentences) => {
    const audioFileInfos = []
    for (let i = 0; i < sentences.length; i += 1) {
      const sentence = sentences[i]
      if (sentence.attribute.status === AUDIO_CRATE_STATUS.COMPLETED) {
        audioFileInfos.push({
          index: i,
          audioId: sentence.attribute.audioId,
        })
      }
    }

    const promises = audioFileInfos.map((audioFileInfo) =>
      AivatarClient({
        type: API_TYPE.BLOB,
      })
        .get(`audios/${audioFileInfo.audioId}/listen`)
        .then((res) => {
          const { data, status } = res
          if (status !== 200) throw new Error(`audios/${audioFileInfo.audioId}/listen - Invalid Response Status`)

          const url = URL.createObjectURL(data)
          return {
            ...audioFileInfo,
            url,
          }
        }),
    )

    return promises
  }

  const getSentencesWithUrl = (sentences, audioUrlInfos) => {
    const sentencesWithUrl = [...sentences]
    for (const audioUrlInfo of audioUrlInfos) {
      const prev = sentencesWithUrl[audioUrlInfo.index]
      const { attribute } = prev
      sentencesWithUrl[audioUrlInfo.index] = {
        ...prev,
        attribute: {
          ...attribute,
          url: audioUrlInfo.url,
        },
      }
    }

    return sentencesWithUrl
  }

  const fetch = async () => {
    try {
      setProjectApiState(CALL_STATE.FETCHING)
      await AivatarClient()
        .get(`workspaces/${workspaceId}/projects/${projectId}?detail=true`)
        .then((res) => {
          const { data, status } = res
          if (status !== 200) throw new Error('Invalid Response Status')
          const { id, title, str, sentences, firstInterval, aivatarId: avatarId, dressId, backgroundColor } = data
          setProject((prev) => ({
            ...prev,
            id,
            title,
            str,
            firstInterval,
            avatarId,
            dressId,
            backgroundColor,
          }))
          setSentenceBoxValues({ ...sentenceBoxValues, ...sentences[sentences.length - 1]?.attribute })
          /** Convert Audio File to Url  */
          const audioFilePromises = getAudioFilePromises(sentences)
          return Promise.all(audioFilePromises).then((audioUrlInfos) => {
            const sentencesWithUrl = getSentencesWithUrl(sentences, audioUrlInfos)
            addSentenceBoxesByFetch({ sentenceData: sentencesWithUrl })
          })
        })
        .catch(() => {
          throw new Error('useProjectData - Invalid Response Status')
        })
      setProjectApiState(CALL_STATE.SUCCESS)
    } catch (error) {
      setProjectApiState(CALL_STATE.ERROR)
    }
  }

  useAivatarDidMount(() => {
    fetch()
  }, [])

  return {
    fetchProject: fetch,
    isLoading,
    isCallSuccess,
  }
}
