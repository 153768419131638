import { useState } from 'react'
import { MenuItem, Select } from '@mui/material'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { SENTENCEBOX_CATEGORY } from '../../../../recoil/texteditor/sentenceBox/atoms'
import { focusedBoxValueState } from '../../../../recoil/texteditor/editor/selectors'
import useEditorCallbacks from '../../../../recoil/texteditor/editor/useEditorCallbacks'
import Arrowicon from '../../../../assets/images/option_icon-langArrow.png'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import useLocaleList from '../../../../hooks/useLocaleList'

function LangSelect() {
  /** Recoilds */
  const { resetSentenceBoxAtClickLanguage } = useEditorCallbacks()
  // const {SENTENCEBOX_OPTION } = message[useLanguages(usePreferredLanguage())].PROJECT;
  const { localeList: languages } = useLocaleList()

  // // 이거 이미 resetSentenceBoxAtClickLanguage에서 쓰고있음
  // const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  const [language] = useRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'language',
    }),
  )

  const setPlayed = useSetRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )

  // const { setOptionValues } = useEditorCallbacks()
  const { fetchProject } = useUpdateProject()

  const [isOpen, setIsOpen] = useState(false)

  // TODO 이거 커다란 부채임. 옵션체인지 관련하여 전부 나중에 수정 하자.
  // TODO resetsentenceBoxAtClickLanguage 맞게 수정한건지 모르겠다. 그 후 fetchProject해서 새로운 문장아이디생성하는건 맞는거같아
  // TODO 어쩌면 잘한걸지도?
  const handleChange = (event) => {
    const newLanguage = event.target.value
    resetSentenceBoxAtClickLanguage({ language: newLanguage })
    // TODO 다중옵션시 아래 주석 살려야됨
    // setOptionValues({ optionKey: 'language', optionValue: sentenceBoxValues.language })
    // setOptionValues({ optionKey: 'voiceId', optionValue: sentenceBoxValues.voiceId })
    fetchProject()
    setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
  }

  /** Memos */

  return (
    <div className="flex w-full items-center justify-center">
      <Select
        sx={{
          zIndex: 10,
          backgroundColor: 'var(--color-gray-7)',
          borderRadius: '19px',
          boxShadow: '0px 4px 4px 0px rgba(0, 40, 104, 0.10)',
          fontSize: '15px',
          fontWeight: 300,
          border: 'none',
          '.MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&:hover': {
            backgroundColor: 'var(--color-main-3)',
          },
        }}
        MenuProps={{
          focusvisibleclassname: '',
          // disableGutters: true,
          sx: {
            '& .MuiMenuItem-root:hover': {
              backgroundColor: '#F5F7FE',
              color: '#3A74F5',
            },
            '& .Mui-selected': {
              backgroundColor: '#F5F7FE !important',
              color: '#3A74F5',
            },
          },
          PaperProps: {
            sx: {
              height: '200px',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '1px 3px 17px 0px rgba(0, 40, 104, 0.18)',
              '& .MuiMenuItem-root': {
                borderRadius: '10px',
              },
            },
          },
        }}
        size="small"
        fullWidth
        value={language}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent={() => (
          <img
            className={`!pointer-events-none !absolute !right-0 z-0 h-[38px] w-[38px] ${isOpen ? 'rotate-180' : ''}`}
            src={Arrowicon}
            alt="arrow"
          />
        )}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        {Object.entries(languages)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map(([langKey, langText]) => (
            <MenuItem
              sx={{
                fontSize: '15px',
                fontWeight: 300,
              }}
              key={`LANGS-${langKey}`}
              value={langKey}
            >
              {langText}
            </MenuItem>
          ))}
      </Select>
    </div>
  )
}

export default LangSelect
