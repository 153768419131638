import { selector, selectorFamily } from 'recoil'
import { CALL_STATE } from '../../services/constants'
import { projectState } from './atoms'

export const projectValueState = selectorFamily({
  key: 'projectValueState',
  get:
    ({ key }) =>
    ({ get }) => {
      const project = get(projectState)
      return project[key]
    },
  set:
    ({ key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(projectValueState({ key }))

      const adjustedNewValue = newValue
      // if (key === 'firstInterval') {
      //   // 부동소수정 오차 보정. firstInterval은 현재 소수점 1자리까지만 있음
      //   adjustedNewValue = Math.round(adjustedNewValue * 10) / 10
      // }

      if (oldValue === newValue) return
      set(projectState, (prev) => ({ ...prev, [key]: adjustedNewValue }))
    },
})

export const isCallSuccessProjectState = selector({
  key: 'isCallSuccessProjectState',
  get: ({ get }) => {
    const apiState = get(projectValueState({ key: 'apiState' }))
    return apiState === CALL_STATE.SUCCESS
  },
})

export const isLoadingProjectState = selector({
  key: 'isLoadingProjectState',
  get: ({ get }) => {
    const apiState = get(projectValueState({ key: 'apiState' }))
    return apiState === CALL_STATE.FETCHING
  },
})
