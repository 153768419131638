import { SnackbarProvider } from 'notistack'
import ExportSnackBar, { durationTime } from './ExportSnackBar'

function AivatarSnackbarProvider({ children }) {
  return (
    <SnackbarProvider
      maxSnack={8}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={durationTime}
      disableWindowBlurListener
      Components={{
        export: ExportSnackBar,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default AivatarSnackbarProvider
