import { useTranslation } from 'react-i18next'
import ProfileImg from './ProfileImg'
import useAuth from '../../hooks/useAuth'

export default function Profile({ className = '', user }) {
  const { user: me } = useAuth()
  const { t } = useTranslation('dashboard-header')

  return (
    <div className={`flex items-center ${className}`}>
      <ProfileImg />
      <div className="ml-[16px] flex flex-col">
        <span className="text-gray-2 flex items-center">
          {user.name}
          {user.id === me.id && <span className="text-gray-3 ml-[1px] text-[12px]">{t('(나)')}</span>}
        </span>
        <span className="text-gray-3 text-[12px]">{user.email}</span>
      </div>
    </div>
  )
}
