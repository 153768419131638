import { useState, useMemo } from 'react'

import AivatarClient, { API_TYPE } from '../AivatarClient'
import { CALL_STATE } from '../constants'
import useConstantProperties from '../utils/useConstantProperties'

export default function useListenVoice() {
  /** Recoils */

  /** poolVotes */
  const [res, setRes] = useState(null)
  const [apiState, setApiState] = useState(CALL_STATE.NEW)
  const { isCallSuccess, isLoading } = useConstantProperties(apiState)

  const fetch = async ({ voiceId }) => {
    try {
      setApiState(CALL_STATE.FETCHING)
      const apiRes = await AivatarClient({
        type: API_TYPE.BLOB,
      }).get(`resources/voices/${voiceId}/listen`)
      setApiState(CALL_STATE.SUCCESS)
      setRes(apiRes)
    } catch (error) {
      console.error('useListenVoice', error)
      setApiState(CALL_STATE.ERROR)
      setRes(null)
    }
  }

  const init = () => {
    setRes(null)
    setApiState(CALL_STATE.NEW)
  }

  /** Status */
  const isValidStatus = useMemo(() => res?.status === 200, [res])
  /** data */
  const data = useMemo(() => {
    if (!isValidStatus) return null
    return res.data
  }, [res, isValidStatus])

  const url = useMemo(() => {
    if (!data) return null
    return URL.createObjectURL(data)
  }, [data])

  return {
    isLoadingListenVoice: isLoading,
    isCallSuccessListenVoice: isCallSuccess,
    url,

    fetchListenVoice: fetch,
    initListenVoice: init,
  }
}
