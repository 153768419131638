import { useMemo, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import navigatorIndexState from '../../../recoil/navigator/atoms'
import { isClickedVideoNavigatorState } from '../../../recoil/workspace/atoms'

type Props = {
  item: {
    text: string
    icon: string
    inactiveIcon: string
    hoverIcon: string
  }
  index: number
}

export default function NavigationItem({ item, index }: Props) {
  const [isHover, setIsHover] = useState(false)

  const [selectedNaviIndex, setSelectedNaviIndex] = useRecoilState(navigatorIndexState)

  const setIsClickedVideoNavigator = useSetRecoilState(isClickedVideoNavigatorState)
  const { text, icon, inactiveIcon, hoverIcon } = item
  const isSelected = index === selectedNaviIndex

  const onClickItem = () => {
    if (!item) return
    if (isSelected) return
    setSelectedNaviIndex(index)
    if (index === 1) setIsClickedVideoNavigator(true)
  }

  /** Event Subscribers */
  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  const imageSize = useMemo(() => {
    if (index === 0) {
      return 'w-[25px] h-[33px]'
    }
    return 'w-[33px] h-[33px] min-w-[33px] min-h-[33px]'
  }, [index])

  const innerBgColor = useMemo(() => {
    if (isSelected) {
      return clsx('bg-white')
    }
    return clsx('bg-[#3A74F5]')
  }, [isSelected])

  const outerBgColor = useMemo(() => {
    if (isSelected) {
      return 'bg-[#3A74F5]'
    }
    return 'bg-white'
  }, [isSelected])

  const rounded = useMemo(() => {
    if (selectedNaviIndex === 0) {
      if (isSelected) {
        return clsx('rounded-l-[30px]')
      }
      return clsx('rounded-tr-[30px]')
    }
    if (selectedNaviIndex === 1) {
      if (isSelected) {
        return clsx('rounded-l-[30px]')
      }
      return clsx('rounded-br-[30px]')
    }
    return null
  }, [isSelected, selectedNaviIndex])

  const imageSrc = useMemo(() => {
    if (isSelected) {
      return icon
    }
    return isHover ? hoverIcon : inactiveIcon
  }, [isSelected, isHover, hoverIcon, inactiveIcon, icon])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={twMerge(
        `navigator_item_height ml-[5px] w-[110px-5px] cursor-pointer transition-all duration-300 ${outerBgColor} ${
          !isSelected && 'hover:'
        }`,
      )}
      onClick={onClickItem}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={twMerge(
          `flex h-full w-full flex-col items-center justify-center transition-all duration-300`,
          clsx(isSelected ? 'text-[#3A74F5]' : 'text-[#CACACA] hover:text-[#ffffff]'),
          rounded,
          innerBgColor,
        )}
      >
        <div className={`${imageSize}`}>
          <img className="h-full w-full" src={imageSrc} alt="" />
        </div>

        <p className="mt-[5px] font-[350]">{text}</p>
      </div>
    </div>
  )
}
