import { useState, useMemo } from 'react'

import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useParams } from 'react-router-dom'
import { editorValueState, focusedBoxValueState } from '../../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY, SentenceBoxValuesForFetch } from '../../../../../recoil/texteditor/sentenceBox/atoms'
import VoicePlay from '../VoicePlay'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import useUpdateAudio from '../../../../../services/audio/useUpdateAudio'
import useEditorCallbacks from '../../../../../recoil/texteditor/editor/useEditorCallbacks'
import useAudioControllerCallbacks from '../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import useDidMount from '../../../../../hooks/useDidMount'
import useDisplayVoiceTag from '../../../../../hooks/useDisplayTag'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function VoiceItem({ voice, index, itemWidth, itemHeight, handleSelector, isLast }) {
  const { projectId } = useParams()
  const { displayVoiceTag } = useDisplayVoiceTag()

  /** Recoilds */
  const didMount = useDidMount()
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))

  const [focusedBoxVoiceId] = useRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'voiceId',
    }),
  )

  const audioId = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'audioId',
    }),
  )

  const setPlayed = useSetRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )

  /** Services */
  const { fetchAudioUpdate } = useUpdateAudio()
  const { setOptionValues } = useEditorCallbacks()
  const { getAudioUpdateBody } = useAudioControllerCallbacks()

  /** Update Effect */

  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)
  const [value, setValue] = useState(-1)

  // TODO 이거 상위로 빼고, 옵션 선택하는 코드(다중셀렉포함)랑 같이 커플링되야됨
  useAivatarDidMount(() => {
    // TODO 다중옵션 적용시 [focusedBoxId] -> selectedBoxIds로 변경하기
    const body = getAudioUpdateBody({ sentenceBoxIds: [focusedBoxId], projectId })
    if (!didMount) return
    if (!audioId) return
    if (value === -1) return
    fetchAudioUpdate({ sentenceBoxIds: [focusedBoxId] })
    setSentenceBoxValues({ ...sentenceBoxValues, ...body.attributes[0] })
    setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
  }, [value])

  const onClickItem = () => {
    setValue(voice.id)
    setOptionValues({ optionKey: 'voiceId', optionValue: voice.id })
    setOptionValues({ optionKey: 'language', optionValue: voice.locale })
    handleSelector({ isOpen: false })
  }

  const isSelected = useMemo(() => focusedBoxVoiceId === voice.id, [focusedBoxVoiceId, voice])

  function getRandomInt(min, max) {
    const minCeiled = Math.ceil(min)
    const maxfloored = Math.floor(max)
    return Math.floor(Math.random() * (maxfloored - minCeiled)) + minCeiled
  }

  const bgColor = useMemo(() => {
    const colors = ['bg-[#D7E1EF]', 'bg-[#D7EFE1]', 'bg-[#D7D8EF]', 'bg-[#EFD7E8]', 'bg-[#EFE5D7]']
    return colors[getRandomInt(0, colors.length - 1)]
  }, [])

  return (
    <button
      type="button"
      className={`flex w-full items-center justify-between rounded-2xl bg-[#F5F7FE] px-[10px] pb-[18px] pt-[17px] ${
        !isLast && 'mb-[10px]'
      } hover:bg-main-3 ${isSelected && 'main_bd shadow_selected border-[2px]'}`}
      onClick={onClickItem}
      style={{
        // width: itemWidth,
        height: itemWidth * 0.2842,
      }}
    >
      <div className="flex">
        <div
          className={`${bgColor} flex h-[47px] min-w-[47px] items-center justify-center rounded-full text-[20px] font-bold text-white`}
        >
          {voice?.alias[0] || ''}
        </div>
        <div className="mx-[15px] flex w-full flex-col items-start">
          <p>{voice?.alias || ''}</p>
          <div className="mt-[7px] flex flex-wrap gap-[4px]">
            {voice?.tags &&
              voice?.tags.map((tag) => (
                <div
                  className="rounded-full border-[1px] border-[#7697E1] px-[4px] text-[12px] text-[#7697E1]"
                  key={`VoiceItemTag-${tag}`}
                >
                  {`#${displayVoiceTag(tag)}`}
                </div>
              ))}
          </div>
        </div>
      </div>
      <VoicePlay voice={voice} />
    </button>
  )
}

export default VoiceItem
