import { Suspense } from 'react'
import './components.css'
import './i18n'
import AivatarRouterProvider from './providers/AivatarRouterProvider'

function App() {
  return (
    //! 다국어 렌더링에 언어 변환되는 이질감 없애기 위한 서스펜스
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Suspense fallback={<></>}>
      <AivatarRouterProvider />
    </Suspense>
  )
}

export default App
