import { useMemo } from 'react'
import { motion } from 'framer-motion'
import { useMeasure } from 'react-use'

import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
import { currentSelectedCharacterState, workspaceAvatarValueState } from '../../../../../../recoil/workspace/selectors'
import useWorkspaceCallback from '../../../../../../recoil/workspace/useWorkspaceCallback'

const ITEM_GAP = 6

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Item({ avatar, itemWidth, itemHeight, index, currentSelectedCharacter, onClickItem }) {
  const { thumbnailSrc, id, active } = avatar

  const isSelected = useMemo(() => id === currentSelectedCharacter?.id, [id, currentSelectedCharacter])

  return (
    <button
      type="button"
      style={{
        minHeight: itemHeight,
        minWidth: itemWidth,
        width: itemWidth,
        height: itemHeight,
        pointerEvents: active ? 'auto' : 'none',
      }}
      className={`bg-[#F5F7FE] ${isSelected && 'main_bd shadow_selected border-[2px]'} hover:bg-main-3 overflow-hidden rounded-[24px]`}
      onClick={() => {
        onClickItem(avatar)
      }}
    >
      <img className="h-full w-full" src={thumbnailSrc} alt="character" style={{ opacity: active ? 1 : 0.5 }} />
    </button>
  )
}

function CharacterSelectContent({ isOpen }) {
  const [ref, { width }] = useMeasure()
  const { t } = useTranslation('project-option')

  /** Recoils */
  const avatars = useRecoilValue(workspaceAvatarValueState({ key: 'avatars' }))
  const currentSelectedCharacter = useRecoilValue(currentSelectedCharacterState)

  const { selectCharacter } = useWorkspaceCallback()

  /** Event */
  const onClickItem = (avatar) => {
    if (currentSelectedCharacter?.id === avatar.id) return
    selectCharacter({ avatarId: avatar.id })
  }

  /** Life Cycle */
  useAivatarDidMount(() => {}, [])

  /** Displays */

  const itemWidth = useMemo(() => (width - 18) / 2, [width])

  const itemHeight = useMemo(() => itemWidth * 0.9333, [itemWidth])

  const wrapperHeight = useMemo(() => (itemHeight + ITEM_GAP) * 4.25, [itemHeight])

  const customAvatars = avatars.filter((avatar) => avatar.type === 'CUSTOM')
  const genericAvatars = avatars.filter((avatar) => avatar.type === 'GENERIC')

  return (
    <motion.div
      className="relative flex w-full flex-col overflow-y-auto"
      animate={{
        height: isOpen ? wrapperHeight : 0,
        minHeight: isOpen ? wrapperHeight : 0,
        maxHeight: isOpen ? wrapperHeight : 0,
        marginTop: isOpen ? '16px' : '0px',
        opacity: isOpen ? 1 : 0,
      }}
    >
      {customAvatars.length !== 0 ? (
        <>
          <p className="mb-[8px] text-[17px] font-medium text-[#3D3D3D]">{t('맞춤형')}</p>
          <div
            ref={ref}
            className={`aivatar_scroll_wrapper flex w-full flex-wrap gap-[var(--item-gap)] pl-[3px] pt-[3px] ${
              !isOpen && 'pointer-events-none'
            }`}
            style={{ '--item-gap': `${ITEM_GAP}px` }}
          >
            {customAvatars.map((avatar, index) => (
              <Item
                avatar={avatar}
                itemWidth={itemWidth}
                itemHeight={itemHeight}
                index={index}
                currentSelectedCharacter={currentSelectedCharacter}
                onClickItem={onClickItem}
              />
            ))}
          </div>
          <p className="mb-[8px] mt-[20px] text-[17px] font-medium text-[#3D3D3D]">{t('범용')}</p>
        </>
      ) : null}

      <div
        ref={ref}
        className={`aivatar_scroll_wrapper flex w-full flex-wrap gap-[var(--item-gap)] pl-[3px] pt-[3px] ${
          !isOpen && 'pointer-events-none'
        }`}
        style={{ '--item-gap': `${ITEM_GAP}px` }}
      >
        {genericAvatars.map((avatar, index) => (
          <Item
            avatar={avatar}
            itemWidth={itemWidth}
            itemHeight={itemHeight}
            index={index}
            currentSelectedCharacter={currentSelectedCharacter}
            onClickItem={onClickItem}
          />
        ))}
      </div>
    </motion.div>
  )
}

export default CharacterSelectContent
