import { useTranslation } from 'react-i18next'
import Table from '../../Table/index'
import Profile from '../../Profile'
import RoleSelect from './RoleSelect'
import { customDayjs } from '../../../utils/date'

export default function MemberTable({ className, ...otherProps }) {
  const { t } = useTranslation('workspace')
  const columns = [
    {
      key: 'user',
      name: t('사용자'),
      className: 'text-left w-[300px]',
      render: (row) => <Profile user={row} />,
    },
    {
      key: 'role',
      name: t('역할'),
      className: 'text-left',
      render: (row) => <RoleSelect value={row.role} />,
    },
    {
      key: 'createdAt',
      name: t('계정 생성일'),
      className: 'text-center w-[120px]',
      render: (row) => customDayjs(row.joinedAt).format('YYYY/MM/DD'),
    },
    /** todo - add lastAccessedAt data */
    // {
    //   key: 'lastAccessedAt',
    //   name: '최근접속',
    //   className: 'text-center w-[120px]',
    //   render: () => customDayjs().fromNow(),
    // },
  ]

  return <Table className={className} columns={columns} {...otherProps} />
}
