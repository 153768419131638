import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function useEmailInput() {
  const { t } = useTranslation('auth-input')
  const [email, setEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const verify = () => {
    if (!email) {
      setErrorMsg(t('이메일을 입력해 주세요.'))
      return false
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (!emailRegex.test(email)) {
      setErrorMsg(t('올바르지 않은 이메일입니다.'))
      return false
    }

    setErrorMsg('')
    return true
  }

  return {
    email,
    setEmail,
    verify,
    errorMsg,
    setErrorMsg,
    isError: !!errorMsg,
    inputProps: {
      type: 'email',
      placeholder: t('이메일'),
      value: email,
      onChange: (e) => {
        setErrorMsg('')
        setEmail(e.target.value)
      },
    },
  }
}
