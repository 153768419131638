import { motion } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import { isAvatarEditorShowState } from '../../../../recoil/navigator/selectors'
import CharacterSelect from './CharacterSelect'
import DressSelect from './DressSelect'
import BackgroundSelect from './BackgroundSelect'

function VideoOptionRoot() {
  const isAvatarEditorShow = useRecoilValue(isAvatarEditorShowState)

  return (
    <div className="relative flex h-full w-full flex-col">
      <motion.div
        animate={{
          opacity: isAvatarEditorShow ? 1 : 0,
        }}
        className="flex h-full w-full flex-col"
      >
        <CharacterSelect />
        <div className="min-h-[25px]" />
        <DressSelect />
        <div className="min-h-[25px]" />
        <BackgroundSelect />
        <div className="min-h-[160px]" />
      </motion.div>
    </div>
  )
}
export default VideoOptionRoot
