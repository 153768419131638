import { ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

export default function Section({ className = '', children }: Props) {
  return <section className={`w-[500px] [&+&]:mt-[32px] ${className}`}>{children}</section>
}
