import { useContext, useMemo } from 'react'
import { isMacOs } from 'react-device-detect'
import { useHotkeys } from 'react-hotkeys-hook'
// eslint-disable-next-line import/no-cycle
import { HotkeysContext } from '.'

export default function useTrackMoveHotkeys() {
  const { prevButtonRef, nextButtonRef } = useContext(HotkeysContext)

  const keyCombinations = useMemo(() => {
    if (isMacOs) return ['ctrl+meta+left', 'ctrl+meta+right']
    return ['ctrl+left', 'ctrl+right']
  }, [])

  useHotkeys(keyCombinations, (_, hotkeysEvent) => {
    if (hotkeysEvent.keys?.includes('left')) {
      prevButtonRef?.current?.click()
    }
    if (hotkeysEvent.keys?.includes('right')) {
      nextButtonRef?.current?.click()
    }
  })
}
