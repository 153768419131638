import axios from 'axios'
import env from '../../constants/env'
import i18n from '../../i18n'
import LOCALSTORAGE_KEY from '../../constants/localstorage-key'

const LOCALSTORAGE_KEY_USER = LOCALSTORAGE_KEY.USER

export const API_TYPE = {
  JSON: 'JSON',
  BLOB: 'BLOB',
}

const AivatarClient = ({ accessToken = null, type = API_TYPE.JSON } = {}) => {
  let user = window.localStorage.getItem(LOCALSTORAGE_KEY_USER)
  if (user) {
    user = JSON.parse(user)
  }

  const requestAccessToken = accessToken || user?.accessToken
  const headers = {
    Accept: '*/*',
    'Content-Type': 'Application/json',
    // "Content-Type": "Application/x-www-form-urlencode"
  }

  const responseType = type === API_TYPE.JSON ? 'json' : 'blob'

  if (requestAccessToken) headers.Authorization = `Bearer ${requestAccessToken}`
  const axiosInstance = axios.create({
    // `url`이 절대값이 아닌 경우 `baseURL`은 URL 앞에 붙습니다.
    // 상대적인 URL을 인스턴스 메서드에 전달하려면 `baseURL`을 설정하는 것은 편리합니다.
    baseURL: `${env.API_HOST}/api/v1`,

    // `headers`는 사용자 지정 헤더입니다.
    headers,

    // `timeout`은 요청이 시간 초과되기 전의 시간(밀리초)을 지정합니다.
    // 요청이 `timeout`보다 오래 걸리면 요청이 중단됩니다.
    timeout: 0, // 기본값은 `0` (타임아웃 없음)

    // `withCredentials`은 자격 증명을 사용하여 사이트 간 액세스 제어 요청을 해야 하는지 여부를 나타냅니다.
    withCredentials: false, // 기본값

    // `responseType`은 서버에서 받는 데이터의 타입입니다.
    // 옵션: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // 브라우저 전용: 'blob'
    responseType,

    // `responseEncoding`은 응답 디코딩에 사용할 인코딩입니다.
    // Node.js 전용
    // 참고: 클라이언트 사이드 요청 또는 `responseType`이 'stream'이면 무시합니다.
    responseEncoding: 'utf8', // 기본값

    // `xsrfCookieName`은 xsrf 토큰 값으로 사용할 쿠키의 이름입니다.
    xsrfCookieName: 'XSRF-TOKEN', // 기본값

    // `xsrfHeaderName`은 xsrf 토큰 값을 운반하는 HTTP 헤더의 이름입니다.
    xsrfHeaderName: 'X-XSRF-TOKEN', // 기본값

    // `onUploadProgress`는 업로드 진행 이벤트를 처리합니다.
    // 브라우저 전용
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUploadProgress(progressEvent) {
      // 업로드 진행 이벤트 작업 수행
    },

    // `onDownloadProgress`는 다운로드로드 진행 이벤트를 처리합니다.
    // 브라우저 전용
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDownloadProgress(progressEvent) {
      // 다운로드 진행 이벤트 작업 수행
    },

    // `validateStatus`는 지정된 HTTP 응답 상태 코드에 대한 Promise를 이행할지 또는 거부할지 여부를 정의합니다.
    // 만약 `validateStatus`가 true를 반환하면(또는 'null' 또는 'undefined'으로 설정) Promise는 이행됩니다.
    // 그렇지 않으면, 그 Promise는 거부될 것이다.
    validateStatus(status) {
      return status >= 200 && status < 300 // 기본값
    },
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // TODO alert 제거하기. apiClient 단에서 로그인 요구 UI 보이지 않도록 리팩토링
        // eslint-disable-next-line no-alert
        alert(i18n.t('로그인이 필요합니다.'))
        window.localStorage.removeItem(LOCALSTORAGE_KEY_USER)
        window.location = '/login'
        return undefined
      }

      return Promise.reject(error)
    },
  )

  return axiosInstance
}

export default AivatarClient
