import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'
import useConstantProperties from '../utils/useConstantProperties'

export default function useDeleteProject() {
  const { workspaceId } = useParams()
  /** Recoils */

  const [res, setRes] = useState(null)
  const [apiState, setApiState] = useState(CALL_STATE.NEW)
  const { isCallSuccess, isLoading } = useConstantProperties(apiState)

  const fetch = async ({ projectId }) => {
    try {
      if (!workspaceId) throw new Error('current workspace is not valid')
      setApiState(CALL_STATE.FETCHING)
      const projectListRes = await AivatarClient().delete(`workspaces/${workspaceId}/projects/${projectId}`)
      setApiState(CALL_STATE.SUCCESS)
      setRes(projectListRes)
    } catch (error) {
      console.error(error.message)
      setApiState(CALL_STATE.ERROR)
      setRes(null)
    }
  }

  /** Status */
  const isValidStatus = useMemo(() => res?.status === 200, [res])
  /** data */
  const data = useMemo(() => {
    if (!isValidStatus) return null
    return res.data
  }, [res, isValidStatus])

  return {
    data,
    isLoadingDeleteProject: isLoading,
    isCallSuccessDeleteProject: isCallSuccess,

    fetchDeleteProject: fetch,
  }
}
