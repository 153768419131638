import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { CALL_STATE } from '../../services/constants'

export const workspaceVoiceState = atom({
  key: 'workspaceVoiceListState',
  default: {
    workspaceId: null,
    voices: [],
    apiState: CALL_STATE.NEW,
  },
})

export const workspaceAvatarState = atom({
  key: 'workspaceAvatarState',
  default: {
    workspaceId: null,
    avatars: [],
    apiState: CALL_STATE.NEW,
  },
})

const { persistAtom } = recoilPersist()
export const isClickedVideoNavigatorState = atom({
  key: 'isClickedVideoNavigatorState',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
