import { useTranslation } from 'react-i18next'
import WarnIcon from '../../../../assets/images/option_icon-warning.png'
import AivatarButton from '../../../../components/buttons/AivatarButton'

function OptionSettingModal({ onClickSetting, onClickClose, titleComponent, subTitle, setButtonText }) {
  /** Recoilds */
  // const [voice, setVoice] = React.useState(voices[0]);
  const { t } = useTranslation('modal')

  return (
    <div className="aivatar_scroll_wrapper z-50 flex w-[400px] flex-col overflow-hidden overflow-y-auto rounded-md py-[10px]">
      <div className="mb-[10px] flex w-full items-center justify-center">
        <img className="h-[60px] w-[60px]" src={WarnIcon} alt="warn" />
      </div>
      {titleComponent}

      <p className="mb-[20px] w-full text-center text-[#9F9F9F]">{subTitle}</p>
      <div className="flex h-[44px] items-center justify-center">
        <AivatarButton
          type="button"
          variant="g5"
          className="mr-[10px] flex h-full w-[117.47px] items-center justify-center rounded-full bg-[#F1F1F1] text-[16px] font-bold text-[#3D3D3D]"
          onClick={onClickClose}
        >
          {t('취소')}
        </AivatarButton>
        <AivatarButton
          type="button"
          variant="m1"
          className="main_bg flex h-full w-[117.47px] items-center justify-center rounded-full text-[16px] font-bold text-white"
          onClick={onClickSetting}
        >
          {setButtonText}
        </AivatarButton>
      </div>
    </div>
  )
}

export default OptionSettingModal
