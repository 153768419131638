import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconCheck } from '../../assets/images/icon-check-circle-fill.svg'
import { workspaceQueries } from '../../services/workspace'
import { authQueries } from '../../services/auth'
import { useCurrentWorkspaceDetailQuery } from '../../hooks/queries'

type Props = {
  type: 'change' | 'cancel' | 'continue'
}

export default function PlanChanged({ type }: Props) {
  const queryClient = useQueryClient()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const { t } = useTranslation('plan-changed')

  useEffect(() => {
    ;(async function invalidate() {
      await queryClient.invalidateQueries({ queryKey: workspaceQueries.detail(workspace.id).queryKey })
      await queryClient.invalidateQueries({ queryKey: authQueries.me.queryKey })
    })()
  }, [queryClient, workspace.id])

  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <IconCheck className="inline-block h-[100px] w-[100px] [&_path]:fill-[#a9bdea]" />
      <h1 className="mt-[15px] text-[28px] font-[700]">
        <span className="text-main-1">
          {
            {
              cancel: t('구독 해지가 성공적으로 완료되었습니다!'),
              change: t('플랜이 성공적으로 변경되었습니다!'),
              continue: t('구독 재개가 성공적으로 완료되었습니다!'),
            }[type]
          }
        </span>
      </h1>
      <p className="text-gray-2 mt-[7px] whitespace-pre text-[16px] font-[400]">
        {
          {
            cancel: t(
              '다음 결제일부터 구독이 갱신되지 않으며, 현재 구독 혜택은 구독 만료일까지 유지됩니다.\n자세한 사항은 구독 관리 페이지에서 확인할 수 있습니다.',
            ),
            change: t('변경된 플랜 제공 사항 및 결제 내역은 구독 관리 페이지에서 확인할 수 있습니다.'),
            continue: t('구독 관리 페이지에서 유지된 구독을 확인할 수 있습니다.'),
          }[type]
        }
      </p>

      <div className="mt-[40px] flex justify-center gap-[10px] text-[17px] font-[700]">
        <Link to="/" className="btn-main">
          {t('홈으로')}
        </Link>
        <Link to={`/workspace/${workspace?.id}/subscription`} className="btn-white">
          {t('구독 확인하기')}
        </Link>
      </div>
    </div>
  )
}
