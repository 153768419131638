import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import PageWithCenterBoxAndLogo from '../../components/PageWithCenterBoxAndLogo'
import AivatarClient from '../../services/AivatarClient'
import ErrMsg from './ErrMsg'
import useURI from '../../hooks/useURI'
import useAuth from '../../hooks/useAuth'
import { authQueries } from '../../services/auth'

function Checkbox({ className, children, ...otherProps }) {
  return (
    <label htmlFor={otherProps.id} className={`flex w-full cursor-pointer select-none items-start ${className}`}>
      <input type="checkbox" className="mr-[7px] mt-[3px]" {...otherProps} />
      <span className="text-gray-21 whitespace-pre text-[12px]">{children}</span>
    </label>
  )
}

function UserInfoUpdate() {
  const navigate = useNavigate()
  const { t } = useTranslation('user-update-prompt')
  const { uri } = useURI()

  const { user } = useAuth()
  const [name, setName] = useState(user.name)
  const [nameErrMsg, setNameErrMsg] = useState('')
  const [lastName, setLastName] = useState(user.lastName)
  const [agreedTermsAndPrivacy, setAgreedTermsAndPrivacy] = useState(false)
  const [agreedMarketing, setAgreedMarketing] = useState(false)
  const [agreementErrMsg, setAgreementErrMsg] = useState('')
  const [isComplete, setIsComplete] = useState(false)
  const queryClient = useQueryClient()

  const checkName = () => {
    if (!name) {
      setNameErrMsg(t('필수 입력 사항입니다.'))
      return false
    }

    return true
  }

  const checkAgreements = () => {
    if (!agreedTermsAndPrivacy || !agreedMarketing) {
      setAgreementErrMsg(t('모든 필수 항목에 동의해주세요.'))
      return false
    }

    return true
  }

  const update = () => {
    AivatarClient()
      .post('/auth/update', {
        name,
        lastName,
        adAgreed: agreedMarketing,
      })
      .then(async () => {
        await queryClient.invalidateQueries({ queryKey: authQueries.me.queryKey })
        setIsComplete(true)
      })
  }

  return (
    <PageWithCenterBoxAndLogo>
      {isComplete ? (
        <>
          <span className="title">{t('업데이트가 완료되었습니다!')}</span>
          <button
            className="btn-main btn-l mt-[50px]"
            onClick={() => {
              navigate('/')
            }}
          >
            {t('계속하기')}
          </button>
        </>
      ) : (
        <>
          <span className="title">{t('더 나은 서비스를 위해 정보를 업데이트 해 주세요!')}</span>
          <span className="text-main mt-[50px] w-full px-[10px] py-[12px] font-bold">{user.email}</span>
          <input
            className={`input mt-[10px] ${nameErrMsg ? 'input-error' : ''}`}
            placeholder={t('이름')}
            value={name}
            onChange={(e) => {
              setNameErrMsg('')
              setName(e.target.value)
            }}
          />
          {nameErrMsg && <ErrMsg>{nameErrMsg}</ErrMsg>}
          <input
            className="input mt-[10px]"
            placeholder={t('성')}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value)
            }}
          />
          <Checkbox
            className="mt-[20px]"
            checked={agreedTermsAndPrivacy}
            onChange={(e) => {
              setAgreementErrMsg('')
              setAgreedTermsAndPrivacy(e.target.checked)
            }}
          >
            <Trans t={t}>
              (필수) AiVATAR{' '}
              <Link className="underline" to={uri['aivatar-privacy']} target="_blank">
                개인정보처리방침
              </Link>{' '}
              및{' '}
              <Link className="underline" to={uri['aivatar-term']} target="_blank">
                서비스 이용약관
              </Link>
              에 동의합니다.
            </Trans>
          </Checkbox>
          <Checkbox
            className="mt-[5px]"
            checked={agreedMarketing}
            onChange={(e) => {
              setAgreementErrMsg('')
              setAgreedMarketing(e.target.checked)
            }}
          >
            {t('(필수) AiVATAR 서비스와 관련한 제품, 서비스 홍보 등 광고성 이메일 수신에 동의합니다.')}
          </Checkbox>
          {agreementErrMsg && <ErrMsg>{agreementErrMsg}</ErrMsg>}
          <button
            className="btn-main btn-l mt-[50px]"
            onClick={() => {
              if (checkName() && checkAgreements()) {
                update()
              }
            }}
          >
            {t('업데이트')}
          </button>
        </>
      )}
    </PageWithCenterBoxAndLogo>
  )
}

export default UserInfoUpdate
