import { useRef, useState, useMemo } from 'react'
import { Popover } from '@mui/material'
import { useMeasure } from 'react-use'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import VoiceItemList from './VoiceItemList'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import { currentSelectedVoiceState } from '../../../../recoil/workspace/selectors'
import VoicePlay from './VoicePlay'
import ArrowDownIcon from '../../../../assets/images/option_icon_arrowDown.png'
import useDisplayVoiceTag from '../../../../hooks/useDisplayTag'

function VoiceSelect() {
  const { t } = useTranslation('project-option')
  const [ref, { width }] = useMeasure()
  const wrapperRef = useRef(null)
  const { displayVoiceTag } = useDisplayVoiceTag()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  /** Recoilds */

  const currentSelectedVoice = useRecoilValue(currentSelectedVoiceState)

  /** Fetching */

  /** Life Cycle */
  useAivatarDidMount(() => {}, [currentSelectedVoice])

  /** Events */

  const handleSelector = ({ isOpen }) => {
    if (isOpen) {
      setAnchorEl(wrapperRef.current)
      return
    }
    setAnchorEl(null)
  }

  const handleClick = () => {
    handleSelector({ isOpen: true })
  }

  const handleClose = () => {
    handleSelector({ isOpen: false })
  }

  const itemHeight = useMemo(() => width * 0.4156, [width])

  return (
    <div ref={ref} className="shadow_1 flex w-full items-center justify-center rounded-2xl bg-white">
      <div
        ref={wrapperRef}
        className="flex w-full flex-col gap-[24px] px-[21px] py-[16px]"
        // style={{
        //   height: itemHeight,
        // }}
      >
        <div className="flex items-center justify-between">
          <div className="flex h-[47px] min-w-[47px] items-center justify-center self-start rounded-full bg-[#EFD7D7] text-[20px] font-bold text-white">
            {currentSelectedVoice?.displayName[0] || ''}
          </div>
          <div className="mx-[15px] flex w-full flex-col">
            <p className="text-[17px] font-bold">{currentSelectedVoice?.alias || ''}</p>
            <div className="mt-[7px] flex flex-wrap items-center gap-[4px]">
              {currentSelectedVoice?.tags?.length >= 0 &&
                currentSelectedVoice.tags.map((tag) => (
                  <div
                    className="rounded-full border-[1px] border-[#7697E1] px-[4px] text-[12px] text-[#7697E1]"
                    key={`VoiceItemTag-${tag}`}
                  >
                    {`#${displayVoiceTag(tag)}`}
                  </div>
                ))}
            </div>
          </div>
          <VoicePlay voice={currentSelectedVoice} />
        </div>
        <button
          type="button"
          className="hover:bg-main-3 flex h-[35px] w-full items-center justify-center rounded-full bg-[#F5F7FE] text-[14px] text-[#7697E1]"
          onClick={handleClick}
        >
          <p className="mr-[7px]">{t('더 보기')}</p>
          <img className="h-[20px] w-[20px]" src={ArrowDownIcon} alt="arrowdown" />
        </button>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            marginTop: '5px',
            borderRadius: '16px',
            padding: '10px',
            width,
            boxShadow: '1px 3px 17px 0px rgba(0, 40, 104, 0.18)',
          },
        }}
      >
        <VoiceItemList itemWidth={width} itemHeight={itemHeight} handleSelector={handleSelector} />
      </Popover>
    </div>
  )
}

export default VoiceSelect
