import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as HomeIcon } from '../../../assets/images/nav-home.svg'
import { ReactComponent as ProjectIcon } from '../../../assets/images/nav-project.svg'
import { ReactComponent as ExportIcon } from '../../../assets/images/nav-export.svg'
import { ReactComponent as NavlinkBar } from '../../../assets/images/nav-link-bar.svg'
import AivatarNavLink from './AivatarNavLink'

function NavLinks() {
  const { t } = useTranslation('dashboard')
  const { workspaceId } = useParams()

  const links = useMemo(
    () => [
      {
        id: 'workspace',
        label: t('홈'),
        to: `/workspace/${workspaceId}`,
        Icon: HomeIcon,
      },
      {
        id: 'project',
        label: t('프로젝트 목록'),
        to: `/workspace/${workspaceId}/project`,
        Icon: ProjectIcon,
        iconClassName: 'w-[28px]',
      },
      {
        id: 'export',
        label: t('내보내기 내역'),
        to: `/workspace/${workspaceId}/export?tab=audio`,
        Icon: ExportIcon,
      },
    ],
    [t, workspaceId],
  )

  const location = useLocation()
  const currentId = location.pathname.split('/')[3] || 'workspace'
  const currentIndex = links.findIndex((link) => link.id === currentId)

  return (
    <div className="relative py-[30px] pl-[20px]">
      {currentIndex >= 0 && <NavlinkBar className="absolute right-0" style={{ top: 65 * currentIndex }} />}
      {links.map(({ id, label, to, Icon, iconClassName = '' }, index) => (
        <AivatarNavLink
          key={id}
          label={label}
          to={to}
          Icon={Icon}
          iconClassName={iconClassName}
          index={index}
          currentIndex={currentIndex}
        />
        // <Link
        //   key={id}
        //   to={to}
        //   className={`nav-link relative flex items-center h-[65px] ${
        //     index === currentIndex
        //       ? "text-point-1 [&_path]:fill-point-1"
        //       : `text-point-2 [&_path]:fill-point-2  hover:text-point-1`
        //   }`}
        // >
        //   <Icon
        //     className={`svg-fill absolute top-1/2 transform -translate-y-1/2 left-[20px] w-[25px] ${iconClassName}`}
        //   />
        //   <span className="ml-[72px] text-[17px] font-bold ">{label}</span>
        // </Link>
      ))}
    </div>
  )
}

export default NavLinks
