function TextWithDivider({ className, children }) {
  return (
    <div className={`flex w-full items-center ${className}`}>
      <div className="flex-grow border-t border-[#9F9F9F]" />
      <span className="mx-[20px] text-[14px] text-[gray-500]"> {children} </span>
      <div className="flex-grow border-t border-[#9F9F9F]" />
    </div>
  )
}

export default TextWithDivider
