import { useTranslation } from 'react-i18next'
import HelpIcon from '../../../assets/images/icon-contact.svg'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'

function ContractButton({ className = '' }) {
  const { t } = useTranslation('dashboard')
  const { user } = useAuth()
  const { uri } = useURI()

  const onClickHelp = () => {
    // window.open(`${uri['contact-us']}?email=${user.email}`)
    window.open(
      `${uri['bug-report']}?email_address=${user.email}&workspace_url=${window.location.href}&browser_info=${window.navigator.userAgent}`,
    )
  }
  return (
    <button className={`mx-[27px] flex items-center ${className}`} onClick={onClickHelp}>
      <img className="mr-[6px]" src={HelpIcon} alt="help" />
      <span className="text-point-2 text-[14px] font-medium">{t('문의하기')}</span>
    </button>
  )
}

export default ContractButton
