import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import Confirm from '../components/Dialog/Confirm'
import Prompt from '../components/Dialog/Prompt'

export const DialogContext = createContext({
  dialog: null,
  setDialog: () => {},
})

export function DialogProvider({ children }) {
  const [dialog, setDialog] = useState(null)

  const context = useMemo(
    () => ({
      dialog,
      setDialog,
    }),
    [dialog],
  )

  return (
    <DialogContext.Provider value={context}>
      {children}
      {dialog}
    </DialogContext.Provider>
  )
}

export default function useDialog() {
  const dialog = useContext(DialogContext)

  const open = useCallback(
    (Dialog) => {
      dialog.setDialog(Dialog)
    },
    [dialog],
  )

  const close = useCallback(() => {
    dialog.setDialog(null)
  }, [dialog])

  const alert = useCallback(
    ({ onClickCancel = close, onClickConfirm = close, ...otherProps }) => {
      dialog.setDialog(<Confirm {...otherProps} onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />)
    },
    [dialog, close],
  )

  const confirm = useCallback(
    ({ onClickCancel = close, onClickConfirm = close, ...otherProps }) => {
      dialog.setDialog(
        <Confirm {...otherProps} onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} useCancel />,
      )
    },
    [dialog, close],
  )

  const prompt = useCallback(
    ({ onClickCancel = close, onClickConfirm = close, ...otherProps }) => {
      dialog.setDialog(
        <Prompt {...otherProps} onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} useCancel />,
      )
    },
    [dialog, close],
  )

  return useMemo(
    () => ({
      open,
      alert,
      confirm,
      prompt,
      close,
    }),
    [alert, close, confirm, open, prompt],
  )
}
