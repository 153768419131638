import React from 'react'
import { ReactComponent as ChipProgressIcon } from '../../../../assets/images/chip-progress.svg'

export default function ChipReady() {
  return (
    <div className="chip-yellow mx-auto">
      <span>READY</span>
      <ChipProgressIcon />
    </div>
  )
}
