import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilValue } from 'recoil'
import { focusedBoxValueState } from '../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../recoil/texteditor/sentenceBox/atoms'
import { setSentenceBoxAudioManager } from '../../recoil/audiocontroller/audioControllerHelpers'

export default function useSeekHotkeys() {
  const id = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.AUDIO, key: 'id' }))

  // period: 점
  useHotkeys(
    [
      'l',
      'k',
      // 'period',
      // 'comma'
    ],
    (_, hotkeysEvent) => {
      const playerElement = document.getElementById(`Player-${id}`)
      if (!playerElement) return

      const player = playerElement.childNodes[0] as HTMLVideoElement

      let { currentTime } = player

      if (hotkeysEvent.keys?.includes('l')) {
        currentTime += 1
      }
      if (hotkeysEvent.keys?.includes('k')) {
        currentTime -= 1
      }
      // if (hotkeysEvent.keys?.includes('period')) {
      //   currentTime += 0.5
      // }
      // if (hotkeysEvent.keys?.includes('comma')) {
      //   currentTime -= 0.5
      // }
      setSentenceBoxAudioManager({ id, value: currentTime })
    },
  )
}
