import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { EditorValidationErrorCode, editorValidationState } from '../../../../recoil/texteditor/editor/selectors'

export default function WarningMessage() {
  const { t } = useTranslation('project-message')

  const validation = useRecoilValue(editorValidationState)

  const message = useMemo(() => {
    if (validation.valid) return null

    switch (validation.code) {
      case EditorValidationErrorCode.INVALID_SENTENCE_LENGTH:
        return t('글자 수가 2만 자를 초과했습니다. 내보내기와 미리 듣기를 위해 글자 수를 줄여 주세요.')
      default:
        return t('알 수 없는 에러.')
    }
  }, [t, validation.code, validation.valid])

  return <span className="text-point-3 text-[16px] font-[700]">{message}</span>
}
