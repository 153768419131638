import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export default function Th({ children }: Props) {
  return (
    <th className="bg-gray-6 !text-gray-21 py-[10px] text-left first:pl-[32px] last:pr-[32px] last:text-right [&:first-child]:rounded-l-[5px] [&:last-child]:rounded-r-[5px]">
      {children}
    </th>
  )
}
