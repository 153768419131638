import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  className?: string
  title?: string
  children: ReactNode
}

export default function BoxSection({ className, title, children }: Props) {
  return (
    <section className={twMerge(`border-gray-4 w-[532px] rounded-[10px] border p-[25px]`, className)}>
      {title ? <span className="text-gray-2 text-[17px] font-bold">{title}</span> : null}
      {children}
    </section>
  )
}
