import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import useOpenSelect from '../useOpenSelect'
import BackgroundSelectContent from './BackgroundSelectContent'

function BackgroundSelect() {
  const { t } = useTranslation('project-option')
  const { component: OpenSelectRowComponent, isOpen } = useOpenSelect({
    title: t('배경'),
    order: 3,
  })

  /** Recoilds */

  /** Fetching */

  /** Life Cycle */
  useAivatarDidMount(() => {}, [])

  /** Events */

  /** Displays */

  return (
    <div className="shadow_1 flex w-full flex-col justify-between rounded-[24px] bg-white px-[14px] py-[20px]">
      {OpenSelectRowComponent}
      <BackgroundSelectContent isOpen={isOpen} />
    </div>
  )
}
export default BackgroundSelect
