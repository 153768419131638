import { useState } from 'react'
import * as React from 'react'
import { Link } from 'react-router-dom'
// import useDialog from '../../hooks/useDialog';
import { useTranslation } from 'react-i18next'
import Section from '../../components/Dashboard/Section'
import ExportTable from '../../components/Table/ExportTable'
// import RemoveSelectedRowsButton from '../../components/Button/RemoveSelectedRowsButton';
import useSearchQuery from '../../hooks/useSearchQuery'
import { useExportList } from '../../hooks/queries'

function TabListItem({ name, to, selected }) {
  return (
    <li>
      <Link
        to={to}
        className={`block px-[15px] py-[9px] text-[23px] ${
          selected ? 'border-main text-main border-b-[2px]' : 'text-gray-21'
        } font-medium`}
        replace
      >
        {name}
      </Link>
    </li>
  )
}

export default function ExportList() {
  const { t } = useTranslation('export-list')
  // const dialog = useDialog();
  const { tab: currentTab = 'all' } = useSearchQuery()
  // let [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState([{ key: 'createdAt', order: 'desc' }])
  const [selected, setSelected] = useState([])

  const { data, isSuccess } = useExportList(currentTab, {
    page,
    sort: sort[0] ? `${sort[0].key},${sort[0].order}` : undefined,
  })

  const rows = data?.content ?? []
  const total = data?.totalElements ?? 0

  return (
    <div className="mt-[40px]">
      <Section title={t('내보내기 내역')} description={t('내보내기한 파일을 확인하고 다운로드 할 수 있습니다.')}>
        <ul className="border-gray-4 mt-[35px] flex border-b">
          {/* <TabListItem name="전체" to="." selected={currentTab === 'all'} /> */}
          <TabListItem name={t('음성')} to=".?tab=audio" selected={currentTab === 'audio'} />
          <TabListItem name={t('영상')} to=".?tab=video" selected={currentTab === 'video'} />
        </ul>
      </Section>
      <ExportTable
        selected={selected}
        onChangeSelected={setSelected}
        usePagination
        rows={rows}
        total={total}
        page={page}
        onChangePage={setPage}
        sort={sort}
        onChangeSort={setSort}
        isSuccess={isSuccess}
        // header={
        //   selected.length > 0 && (
        //     <RemoveSelectedRowsButton onClick={confirmRemove} />
        //   )
        // }
      />
    </div>
  )
}
