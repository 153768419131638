/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { selector, selectorFamily } from 'recoil'
import { SENTENCEBOX_CATEGORY, SENTENCEBOX_TYPE, sentenceBoxAudioState, sentenceBoxState } from '../sentenceBox/atoms'
import {
  isLoadingAudioState,
  isValidSentenceBoxAudioState,
  isValidSentenceBoxLengthState,
  isValidSentenceBoxState,
} from '../sentenceBox/selectors'
import { editorAudioState, editorState, sentenceBoxIdsState } from './atoms'
import {
  getFocusedBoxByCategory,
  getSelectedBoxesByCategory,
  getViewerBoxesByCategory,
  setFocusedBoxByCategory,
  setFocusedBoxValueByCategory,
} from './editorHelperCallbacks'

export const editorValueState = selectorFamily({
  key: 'editorValueState',
  get:
    ({ key }) =>
    ({ get }) => {
      const editor = get(editorState)
      return editor[key]
    },
  set:
    ({ key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(editorValueState({ key }))
      if (oldValue === newValue) return
      set(editorState, (prev) => ({ ...prev, [key]: newValue }))
    },
})

export const editorAudioValueState = selectorFamily({
  key: 'editorAudioValueState',
  get:
    ({ key }) =>
    ({ get }) => {
      const editorAudio = get(editorAudioState)
      return editorAudio[key]
    },
  set:
    ({ key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(editorAudioValueState({ key }))
      if (oldValue === newValue) return
      set(editorAudioState, (prev) => ({ ...prev, [key]: newValue }))
    },
})

/** Focused */
export const focusedBoxState = selectorFamily({
  key: 'focusedBoxState',
  get:
    ({ category }) =>
    ({ get }) => {
      const focusedBoxId = get(editorValueState({ key: 'focusedBoxId' }))
      if (!focusedBoxId) return null
      const focusedBox = getFocusedBoxByCategory({
        category,
        focusedBoxId,
        get,
      })

      return focusedBox
    },
  set:
    ({ category }) =>
    ({ get, set }, newValue) => {
      const focusedBox = get(focusedBoxState({ category }))
      if (!focusedBox) return null

      setFocusedBoxByCategory({
        category,
        focusedBoxId: focusedBox.id,
        set,
        newValue,
      })
    },
})

export const isEmptyFocusedBoxState = selector({
  key: 'isEmptyFocusedBoxState',
  get: ({ get }) => {
    const focusedBoxId = get(editorValueState({ key: 'focusedBoxId' }))
    return focusedBoxId === null
  },
})

export const focusedBoxValueState = selectorFamily({
  key: 'focusedBoxOptionValueState',
  get:
    ({ category, key }) =>
    ({ get }) => {
      const focusedBox = get(focusedBoxState({ category }))
      if (!focusedBox) return null
      return focusedBox[key]
    },
  set:
    ({ category, key }) =>
    ({ get, set }, newValue) => {
      const focusedBox = get(focusedBoxState({ category }))
      if (!focusedBox) return
      const oldValue = focusedBox[key]
      if (oldValue === newValue) return
      setFocusedBoxValueByCategory({
        category,
        key,
        focusedBoxId: focusedBox.id,
        set,
        newValue,
      })
    },
})

export const focusedBoxIndexState = selector({
  key: 'focusedBoxIndexState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)
    const focusedBoxId = get(editorValueState({ key: 'focusedBoxId' }))

    const index = sentenceBoxIds.findIndex((boxId) => boxId === focusedBoxId)
    return index === -1 ? null : index
  },
})

export const lastBoxIndexState = selector({
  key: 'lastBoxIndexState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)

    return sentenceBoxIds.length - 1
  },
})

export const focusedBoxIndexAmongSelectedBoxesState = selector({
  key: 'focusedBoxIndexAmongSelectedBoxesState',
  get: ({ get }) => {
    const focusedBoxId = get(editorValueState({ key: 'focusedBoxId' }))
    if (!focusedBoxId) return null
    const selectedBoxIds = get(selectedBoxIdsState)
    if (selectedBoxIds.length === 0) return null
    const currentFocusedBoxIndex = selectedBoxIds.indexOf(focusedBoxId)
    return currentFocusedBoxIndex === -1 ? null : currentFocusedBoxIndex
  },
})

export const isLastFocusedBoxInSelectedBoxesState = selector({
  key: 'isLastFocusedBoxInSelectedBoxesState',
  get: ({ get }) => {
    const currentFocusedBoxIndex = get(focusedBoxIndexAmongSelectedBoxesState)
    if (currentFocusedBoxIndex === null) return false

    const selectedBoxes = get(selectedBoxesState)

    return selectedBoxes.length - 1 === currentFocusedBoxIndex
  },
})

/** Sentence Boxes */

export const sentenceBoxesState = selector({
  key: 'sentenceBoxesState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)
    const sentenceBoxesWithIds = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

    const boxes = sentenceBoxesWithIds
    return boxes
  },
})

export const sentenceBoxesByTypeState = selectorFamily({
  key: 'sentenceBoxesByTypeState',
  get:
    (type) =>
    ({ get }) => {
      const sentenceBoxIds = get(sentenceBoxIdsState)
      const sentenceBoxesWithIds = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

      const boxes = sentenceBoxesWithIds.filter((sentenceBox) => sentenceBox.type === type)
      return boxes
    },
})

export const viewerBoxIdsState = selector({
  key: 'viewerBoxIdsState',
  get: ({ get }) => {
    const sentenceBoxesByType = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER))
    return sentenceBoxesByType.map((box) => box.id)
  },
})

export const viewerBoxesByCategoryState = selectorFamily({
  key: 'viewerBoxesByCategoryState',
  get:
    ({ category }) =>
    ({ get }) => {
      const boxes = getViewerBoxesByCategory({ get, category })

      return boxes
    },
})

export const viewerBoxesByCategoryWithFilterState = selectorFamily({
  key: 'viewerBoxesByCategoryWithValueState',
  get:
    ({ category, filterFunc }) =>
    ({ get }) => {
      const boxes = getViewerBoxesByCategory({ get, category })

      return boxes.filter(filterFunc)
    },
})

export const validBoxesByCategoryWithFilterState = selectorFamily({
  key: 'validBoxesByCategoryWithFilterState',
  get:
    ({ category, filterFunc }) =>
    ({ get }) => {
      let boxes = getViewerBoxesByCategory({ get, category })

      if (filterFunc && typeof filterFunc === 'function') {
        boxes = boxes.filter(filterFunc)
      }

      return boxes.filter((box) => {
        const isValid = get(isValidSentenceBoxState(box.id))
        return isValid
      })
    },
})

export const validSelectedBoxesByCategoryWithFilterState = selectorFamily({
  key: 'validBoxesByCategoryWithFilterState',
  get:
    ({ category, filterFunc }) =>
    ({ get }) => {
      let boxes = getSelectedBoxesByCategory({ get, category })

      if (filterFunc && typeof filterFunc === 'function') {
        boxes = boxes.filter(filterFunc)
      }

      return boxes.filter((box) => {
        const isValid = get(isValidSentenceBoxState(box.id))
        return isValid
      })
    },
})

/** SelectedBoxes */

export const selectedBoxIdsState = selector({
  key: 'selectedBoxIdsState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)
    const sentenceBoxWithIds = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

    const selectedBoxes = sentenceBoxWithIds.filter((sentenceBoxedWithId) => sentenceBoxedWithId.isSelected === true)

    return selectedBoxes.map((box) => box.id)
  },
})

export const selectedBoxesState = selector({
  key: 'selectedBoxesState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)
    const sentenceBoxWithIds = sentenceBoxIds.map((sentenceBoxId) => get(sentenceBoxState(sentenceBoxId)))

    const selectedBoxes = sentenceBoxWithIds.filter((sentenceBoxedWithId) => sentenceBoxedWithId.isSelected === true)

    return selectedBoxes
  },
})

export const selectedBoxesByCategoryState = selectorFamily({
  key: 'selectedBoxesByCategoryState',
  get:
    ({ category }) =>
    ({ get }) => {
      const boxes = getSelectedBoxesByCategory({ get, category })

      return boxes
    },
})

export const playableBoxesState = selector({
  key: 'playableBoxesState',
  get: ({ get }) => {
    const viewerBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER))
    const selectableBoxes = viewerBoxes.filter((box) => {
      const isValidSentenceBox = get(isValidSentenceBoxState(box.id))
      return isValidSentenceBox
    })

    return selectableBoxes
  },
})

export const selectedBoxState = selector({
  key: 'selectedBoxState',
  get: ({ get }) => {
    const selectedBoxes = get(selectedBoxesState)
    return selectedBoxes.length > 0 ? selectedBoxes[0] : null
  },
  set: ({ get, set }, newValue) => {
    const selectedBox = get(selectedBoxState)
    set(sentenceBoxState(selectedBox.id), {
      ...selectedBox,
      options: {
        ...newValue,
      },
    })
  },
})

export const isSelectedBoxesEmptyState = selector({
  key: 'isSelectedBoxesEmptyState',
  get: ({ get }) => {
    const selectedBoxes = get(selectedBoxesState)
    return selectedBoxes.length === 0
  },
})

export const selectedBoxesEstimatedDurationState = selector({
  key: 'selectedBoxesEstimatedDurationState',
  get: ({ get }) => {
    let selectedBoxIds = get(selectedBoxIdsState)
    if (selectedBoxIds.length <= 0) {
      selectedBoxIds = get(sentenceBoxIdsState)
    }

    return selectedBoxIds
      .map((boxId) => get(sentenceBoxAudioState(boxId)).estimatedDurationMS)
      .reduce((accumulator, value) => accumulator + value, 0)
  },
})

export const totalEstimatedDurationState = selector({
  key: 'totalEstimatedDurationState',
  get: ({ get }) => {
    const selectedBoxIds = get(sentenceBoxIdsState)
    return selectedBoxIds
      .map((boxId) => get(sentenceBoxAudioState(boxId)).estimatedDurationMS)
      .reduce((accumulator, value) => accumulator + value, 0)
  },
})

/** Validation */

export const isMultiSelectedState = selector({
  key: 'isMultiSelectedState',
  get: ({ get }) => {
    const selectedBoxIds = get(selectedBoxIdsState)
    return selectedBoxIds.length > 1
  },
})

export const isMultiSelectModeEnabledState = selector({
  key: 'isMultiSelectModeEnabledState',
  get: ({ get }) => {
    const enabled =
      get(editorValueState({ key: 'enableCmdSelect' })) || get(editorValueState({ key: 'enableShiftSelect' }))

    return enabled
  },
})

export const isEmptySentenceBoxState = selector({
  key: 'isEmptySentenceBoxState',
  get: ({ get }) => {
    const sentenceBoxIds = get(sentenceBoxIdsState)
    return sentenceBoxIds.length === 0
  },
})

export const isViewerBoxesEmptyState = selector({
  key: 'isViewerBoxesEmptyState',
  get: ({ get }) => {
    const viewerBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER))
    return viewerBoxes.length === 0
  },
})

// export const isValidSetenceBoxTextInEditorState = selector({
//   key: "isValidSetenceBoxTextInEditorState",
//   get: ({ get }) => {
//     const viewerBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER));
//     const viewerBoxIds = viewerBoxes.map((box) => box.id);

//     const isValidTextArray = viewerBoxIds.map((id) => {
//       const isValid = get(isValidSentenceBoxTextState(id));
//       return isValid;
//     });

//     return !isValidTextArray.includes(false);
//   }
// });

export const isValidSetenceBoxInEditorState = selector({
  key: 'isValidSetenceBoxInEditorState',
  get: ({ get }) => {
    const viewerBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER))

    const isValidArray = viewerBoxes.map((box) => {
      const isValid = get(isValidSentenceBoxState(box.id))
      return isValid
    })

    return !isValidArray.includes(false)
  },
})

export const isValidSetenceBoxAudioInEditorState = selector({
  key: 'isValidSetenceBoxAudioInEditorState',
  get: ({ get }) => {
    const viewerBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER))

    const isValidArray = viewerBoxes.map((box) => {
      const isValid = get(isValidSentenceBoxAudioState({ id: box.id }))
      return isValid
    })

    return !isValidArray.includes(false)
  },
})

export const isValidSetenceBoxLengthInEditorState = selector({
  key: 'isValidSetenceBoxLengthInEditorState',
  get: ({ get }) => {
    const viewerBoxes = get(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER))

    const isValidTextArray = viewerBoxes.map((box) => {
      const isValid = get(isValidSentenceBoxLengthState({ id: box.id }))
      return isValid
    })

    return !isValidTextArray.includes(false)
  },
})

export const isEmptyLoadingBoxState = selector({
  key: 'isEmptyLoadingBoxState',
  get: ({ get }) => {
    const viewerBoxIds = get(viewerBoxIdsState)
    const isLoadingViewerBoxes = viewerBoxIds.map((id) => {
      const isLoading = get(isLoadingAudioState({ id }))
      return isLoading
    })

    return !isLoadingViewerBoxes.includes(true)
  },
})

export const isValidBoxesEmptyState = selector({
  key: 'isValidBoxesEmptyState',
  get: ({ get }) => {
    const validBoxes = get(
      validBoxesByCategoryWithFilterState({
        category: SENTENCEBOX_CATEGORY.GENERAL,
      }),
    )
    return validBoxes.length === 0
  },
})

export const isValidSelectedBoxesEmptyState = selector({
  key: 'isValidSelectedBoxesEmptyState',
  get: ({ get }) => {
    const validBoxes = get(
      validSelectedBoxesByCategoryWithFilterState({
        category: SENTENCEBOX_CATEGORY.GENERAL,
      }),
    )
    return validBoxes.length === 0
  },
})

export const totalCharacterLengthState = selector({
  key: 'totalCharacterLengthState',
  get: ({ get }) => {
    const viewerBoxes = get(sentenceBoxesState)

    const totalLength = viewerBoxes.reduce((acc, viewerBox) => acc + viewerBox.text.length, 0)

    return totalLength
  },
})

export const EditorValidationErrorCode = {
  INVALID_SENTENCE_LENGTH: 'INVALID_SENTENCE_LENGTH',
}

export const editorValidationState = selector({
  key: 'editorValidationState',
  get: ({ get }) => {
    const validation = {
      valid: true,
      code: '',
    }

    const totalCharacterLength = get(totalCharacterLengthState)

    switch (true) {
      case totalCharacterLength > 20000:
        validation.valid = false
        validation.code = EditorValidationErrorCode.INVALID_SENTENCE_LENGTH
        break
      default:
    }

    return validation
  },
})
