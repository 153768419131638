import { useState, useMemo } from 'react'
import { ClickAwayListener } from '@mui/base'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icon-delete.svg'
import { ReactComponent as CheckIcon } from '../../../../assets/images/icon-delete-check.svg'
import useEditorCallbacks from '../../../../recoil/texteditor/editor/useEditorCallbacks'
import FirstIntervalButton from './FirstIntervalButton'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import WarningMessage from './WarningMessage'

function TextEditorHeader() {
  const { t } = useTranslation('project')
  const { deleteAllBoxes } = useEditorCallbacks()
  const [activeConfirmLevel, setActiveConfirmLevel] = useState(0)
  const [isHover, setIsHover] = useState(false)
  const { fetchProject } = useUpdateProject()

  /** Life Cycle */
  useAivatarDidMount(() => {
    let timer
    if (activeConfirmLevel === 3) {
      timer = setTimeout(() => {
        setActiveConfirmLevel(0)
      }, 2000)
    }
    return () => clearInterval(timer)
  }, [activeConfirmLevel])

  /** Methods */

  /** Event Handler */

  const handleDeleteButtonClicked = () => {
    switch (activeConfirmLevel) {
      case 0:
        setActiveConfirmLevel(1)
        break
      case 1:
        setActiveConfirmLevel(2)
        break
      case 2:
        setActiveConfirmLevel(3)
        deleteAllBoxes()
        fetchProject()
        break

      default:
        break
    }
    //
  }

  const onCickAway = () => {
    setActiveConfirmLevel(0)
  }
  /** Event Subscribers */

  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  /** Memos */

  /** Display */
  const text = useMemo(() => {
    switch (activeConfirmLevel) {
      case 0:
        return null
      case 1:
        return t('전체 삭제하시겠습니까?')
      case 2:
        return t('정말 삭제하시겠습니까?')
      case 3:
        return t('삭제되었습니다')

      default:
        return null
    }
  }, [activeConfirmLevel, t])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, consistent-return
  const animationWidth = useMemo(() => {
    switch (activeConfirmLevel) {
      case 0:
        return 0
      case 1:
      case 2:
        return 155
      case 3:
        return 100

      default:
        break
    }
  }, [activeConfirmLevel])

  const icon = useMemo(() => {
    switch (activeConfirmLevel) {
      case 0:
        return <DeleteIcon className="group-hover:[&_path]:fill-[#004fff]" />
      case 1:
        return <DeleteIcon className="group-hover:[&_path]:fill-point-3" />
      case 2:
        return <CheckIcon className="group-hover:[&_path]:fill-main-1" />
      case 3:
        return <CheckIcon className="[&_path]:fill-main-1" />
      default:
        return null
    }
  }, [activeConfirmLevel])

  // eslint-disable-next-line consistent-return, @typescript-eslint/no-unused-vars
  const imageSize = useMemo(() => {
    switch (activeConfirmLevel) {
      case 0:
      case 1:
        return 'w-[16px] h-[19px]'
      case 2:
      case 3:
        return 'w-[19px] h-[19px]'

      default:
        break
    }
  }, [activeConfirmLevel])

  const bgColor = useMemo(() => {
    switch (activeConfirmLevel) {
      case 0:
        return clsx(!isHover ? 'bg-[#ffffff]' : 'bg-main-3')
      case 1:
        return clsx(isHover ? 'bg-[#FFEEEE]' : 'bg-[#E8ECFB]')
      case 2:
        return clsx(isHover ? 'bg-[#E8ECFB]' : 'bg-[#F1F1F1]')
      case 3:
        return clsx('bg-[#E8ECFB]')
      default:
        return null
    }
  }, [activeConfirmLevel, isHover])

  return (
    <div className="relative flex h-[60px] w-full items-center justify-between border-b-[1px] border-[#F1F1F1]">
      <div className="flex items-center gap-[30px]">
        <FirstIntervalButton />
        <WarningMessage />
      </div>

      <ClickAwayListener onClickAway={onCickAway}>
        <button
          type="button"
          className="shadow_2 group flex w-fit cursor-pointer items-center justify-center rounded-full bg-white"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={handleDeleteButtonClicked}
        >
          <div
            className={twMerge(
              `${
                activeConfirmLevel === 0 && 'pointer-events-auto'
              } pointer-events-none flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full`,
              bgColor,
            )}
          >
            {icon}
          </div>
          <motion.div
            className="z-10 flex w-0 items-center overflow-hidden whitespace-nowrap text-[14px] font-medium"
            animate={{
              opacity: activeConfirmLevel > 0 ? 1 : 0,
              width: activeConfirmLevel > 0 ? 'fit-content' : 0,
              marginLeft: activeConfirmLevel > 0 ? '8px' : 0,
              marginRight: activeConfirmLevel > 0 ? '20px' : 0,
            }}
          >
            {text}
          </motion.div>
        </button>
      </ClickAwayListener>
    </div>
  )
}

export default TextEditorHeader
