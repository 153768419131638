import { useRecoilValue, useSetRecoilState } from 'recoil'
import { differenceBy } from 'lodash'
import { workspaceAvatarState } from '../../recoil/workspace/atoms'
import {
  isCallSuccessWorkspaceAvatarState,
  isLoadingWorkspaceAvatarState,
  workspaceAvatarValueState,
} from '../../recoil/workspace/selectors'
import useAivatarDidMount from '../../hooks/useAivatarDidMount'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'

export default function useAvatarList({ workspaceId }) {
  /** Recoils */
  const setWorkspaceAvatar = useSetRecoilState(workspaceAvatarState)
  const setWorkspaceAvatarApiState = useSetRecoilState(workspaceAvatarValueState({ key: 'apiState' }))

  const isCallSuccess = useRecoilValue(isCallSuccessWorkspaceAvatarState)
  const isLoading = useRecoilValue(isLoadingWorkspaceAvatarState)

  const fetch = async () => {
    try {
      setWorkspaceAvatarApiState(CALL_STATE.FETCHING)
      const [res, allRes] = await Promise.all([
        AivatarClient().get(`workspaces/${workspaceId}/aivatars`),
        AivatarClient().get(`workspaces/aivatars`),
      ])
      const { data, status } = res
      const { data: allData, status: allStatus } = allRes
      if (status !== 200 || allStatus !== 200) throw new Error('Invalid Response Status')

      const aivatarData = data.aivatars.map((avatar) => ({ ...avatar, active: true }))
      const inactiveAvatars = differenceBy(allData, aivatarData, 'id').map((avatar) => ({ ...avatar, active: false }))

      aivatarData.sort((a, b) => {
        //! CUSTOM 끼리의 정렬
        if (a.type === 'CUSTOM' && b.type === 'CUSTOM') {
          return a.displayOrder - b.displayOrder
        }
        //! CUSTOM 아바타 정렬시 앞으로 땡겨온다. 초기에 커스텀 선택하기 편하라고. (70번째 줄 참조)
        if (a.type === 'CUSTOM' || b.type === 'CUSTOM') {
          return -1
        }

        return a.displayOrder - b.displayOrder
      })
      inactiveAvatars.sort((a, b) => a.displayOrder - b.displayOrder)
      const avatarList = [...aivatarData, ...inactiveAvatars]

      //! 디버깅 용인가?? 이 에러 던져주면 모든 아바타 비활성일때 블락된다.
      // if (!Array.isArray(aivatarData)) throw new Error('useAvatarList - Invalid aivatarData')
      // if (aivatarData.length <= 0) throw new Error('useAvatarList - Invalid aivatarData')
      // if (aivatarData[0].detail.length <= 0) throw new Error('useAvatarList - Invalid detail data')

      setWorkspaceAvatar((prev) => ({
        ...prev,
        workspaceId,
        avatars: avatarList.map((avatar) => ({
          id: avatar.id,
          type: avatar.type,
          name: avatar.name,
          thumbnailSrc: avatar.thumbnailSrc,
          active: avatar.active,
          detail: avatar.detail.map((detailData) => ({
            ...detailData,
            actingVideoId: detailData.actingVideo?.id,
            deepLearningModelId: detailData.deepLearningModel?.id,
          })),
        })),
      }))

      // // 아바타 활성화 된거 있는데 선택한것 없었던 경우 첫번째 아바타, 그 아바타의 첫번째 드레스 자동선택되는 로직
      // // 이 로직은 없어져도 되는 로직이며, 다른 코드에 영향을 미치지 않는 코드이다.ㅇ
      // if (!project.avatarId && aivatarData.length !== 0) {
      //   setProject((prev) => ({
      //     ...prev,
      //     avatarId: avatarList[0].id,
      //   }))

      //   if (!project.dressId) {
      //     setProject((prev) => ({
      //       ...prev,
      //       dressId: avatarList[0].detail[0].seqId,
      //     }))
      //   }
      // }

      setWorkspaceAvatarApiState(CALL_STATE.SUCCESS)
    } catch (error) {
      console.error(error)
      setWorkspaceAvatarApiState(CALL_STATE.ERROR)
    }
  }

  useAivatarDidMount(() => {
    fetch()
  }, [])

  return {
    fetchWorkspaceAvatar: fetch,
    isLoading,
    isCallSuccess,
  }
}
