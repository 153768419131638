import { useRef, useMemo, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'
import SentenceBoxConnector from '../../../../../providers/SentenceBoxConnector'

import { isValidSentenceBoxState, sentenceBoxValueState } from '../../../../../recoil/texteditor/sentenceBox/selectors'
import useSentenceBoxCallbacks from '../../../../../recoil/texteditor/sentenceBox/useSentenceBoxCallbacks'
import {
  editorValueState,
  isMultiSelectModeEnabledState,
  isMultiSelectedState,
} from '../../../../../recoil/texteditor/editor/selectors'

type Props = {
  id: string
}

function SentenceBoxText({ id }: Props) {
  const { sentenceBoxId } = SentenceBoxConnector.useContainer()

  const doubleClickBtnRef = useRef<HTMLSpanElement>(null)

  /** Recoils */

  const text = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'text' }))
  const isSelected = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'isSelected' }))
  const isMultiSelectModeEnabled = useRecoilValue(isMultiSelectModeEnabledState)
  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(sentenceBoxId))

  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))

  const isMultiSelected = useRecoilValue(isMultiSelectedState)

  const { selectBox, convertBoxToCreator } = useSentenceBoxCallbacks()

  /** Event Handlers */

  // useDoubleClick({
  //   // onSingleClick: () => {
  //   //   // if (!isValidClick) return;
  //   //   // if (!isMultiSelectModeEnabled) {
  //   //   selectBox({ id: sentenceBoxId, isSelected: !isSelected })
  //   //   // }
  //   // },
  //   onDoubleClick: () => {
  //     // if (!isValidClick) return;
  //     convertBoxToCreator({ id: sentenceBoxId })
  //   },
  //   ref: doubleClickBtnRef,
  //   latency: 200,
  // })

  useEffect(() => {
    const mouseDownCallback = () => {
      selectBox({ id: sentenceBoxId, isSelected: !isSelected })
    }
    const doubleClickCallback = () => {
      convertBoxToCreator({ id: sentenceBoxId })
    }

    const buttonRef = doubleClickBtnRef.current
    buttonRef?.addEventListener('mousedown', mouseDownCallback)
    if (!isMultiSelectModeEnabled) {
      buttonRef?.addEventListener('dblclick', doubleClickCallback)
    } else {
      buttonRef?.removeEventListener('dblclick', doubleClickCallback)
    }

    return () => {
      buttonRef?.removeEventListener('mousedown', mouseDownCallback)
      buttonRef?.removeEventListener('dblclick', doubleClickCallback)
    }
  }, [isSelected, selectBox, sentenceBoxId, isMultiSelectModeEnabled, convertBoxToCreator])

  /** Validations */
  // const isValidClick = React.useMemo(() => {
  //   return !isLoadingAudio;
  // }, [isLoadingAudio]);

  /** Displays */

  const isFocusedBox = useMemo(() => focusedBoxId === sentenceBoxId, [focusedBoxId, sentenceBoxId])

  const borderColor = useMemo(() => {
    if (!isValidSentenceBox) return 'var(--color-point-3)'
    if (isFocusedBox) return 'var(--color-main-1)'
    return 'transparent'
  }, [isFocusedBox, isValidSentenceBox])

  const backgroundColor = useMemo(() => {
    if (!isValidSentenceBox) return 'var(--color-point-4)'
    if (isMultiSelected && isSelected) return 'var(--color-main-3)'
    return '#ffffff60'
  }, [isMultiSelected, isValidSentenceBox, isSelected])

  const textColor = useMemo(() => {
    if (!isValidSentenceBox) return 'var(--color-point-3)'
    return 'var(--color-gray-1)'
  }, [isValidSentenceBox])

  return (
    <span
      contentEditable={false}
      className={twMerge(
        'relative w-fit cursor-pointer overflow-hidden rounded-full border-[2px] border-[var(--border-color)] bg-[var(--background-color)] py-[8px] pl-[11px] pr-[11px] text-[var(--text-color)] shadow-[0px_4px_4px_0px_rgba(0,40,104,0.10)] transition-[background-color]',
        clsx(isValidSentenceBox ? 'hover:bg-[#ECE8FB]' : 'hover:bg-[#FFD5D5]'),
      )}
      ref={doubleClickBtnRef}
      style={{
        ['--border-color' as any]: borderColor,
        ['--background-color' as any]: backgroundColor,
        ['--text-color' as any]: textColor,
      }}
    >
      {/* <ReloadAudioButton /> */}

      <span id={id} className="font-bold">{`${text}`}</span>
    </span>
  )
}

export default SentenceBoxText
