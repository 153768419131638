import jena_multilingual_ko from './videos/jena_multilingual_ko.mp4'
import jena_multilingual_en from './videos/jena_multilingual_en.mp4'
import jena_multilingual_es from './videos/jena_multilingual_es.mp4'
import jena_multilingual_fr from './videos/jena_multilingual_fr.mp4'
import aria_multilingual_ko from './videos/aria_multilingual_ko.mp4'
import aria_multilingual_en from './videos/aria_multilingual_en.mp4'
import aria_multilingual_es from './videos/aria_multilingual_es.mp4'
import aria_multilingual_fr from './videos/aria_multilingual_fr.mp4'

const data = [
  {
    label: { ko: '한국어', en: 'Korean' },
    url: {
      ko: jena_multilingual_ko,
      en: aria_multilingual_ko,
    },
  },
  {
    label: { ko: '영어', en: 'English' },
    url: {
      ko: jena_multilingual_en,
      en: aria_multilingual_en,
    },
  },
  {
    label: { ko: '스페인어', en: 'Spanish' },
    url: {
      ko: jena_multilingual_es,
      en: aria_multilingual_es,
    },
  },
  {
    label: { ko: '프랑스어', en: 'French' },
    url: {
      ko: jena_multilingual_fr,
      en: aria_multilingual_fr,
    },
  },
]

export default data
