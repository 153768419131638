import { enqueueSnackbar } from 'notistack'
import { useParams, useNavigate } from 'react-router-dom'

const useSnackbarHandler = () => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  // const navigateToExport = () => {
  //   navigate()
  // }
  // todo: 호출 시  message를 dictionary로 변경.
  const showExportSnackbar = ({ filename, isSuccess, isAudio, message }) => {
    enqueueSnackbar(message ?? '', {
      variant: 'export',
      isSuccess,
      filename,
      workspaceId,
      isAudio,
      navigate,
    })
  }

  return {
    showExportSnackbar,
  }
}

export default useSnackbarHandler
