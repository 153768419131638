import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { editorValueState, focusedBoxIndexState, selectedBoxIdsState } from '../../recoil/texteditor/editor/selectors'
import { sentenceBoxIdsState } from '../../recoil/texteditor/editor/atoms'
import useSentenceBoxCallbacks from '../../recoil/texteditor/sentenceBox/useSentenceBoxCallbacks'

const useSelectHotkeys = () => {
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)
  // const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  // const focusedBoxId: string | null = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const focusedBoxIndex = useRecoilValue(focusedBoxIndexState)
  const { selectBox, selectAllBoxes } = useSentenceBoxCallbacks()
  const enableShiftSelect = useRecoilValue(editorValueState({ key: 'enableShiftSelect' }))
  const setEnableCmdSelect = useSetRecoilState(editorValueState({ key: 'enableCmdSelect' }))

  useHotkeys(
    [
      // both
      'left',
      'right',
      'shift+left',
      'shift+right',
    ],
    (_, hotKeysEvent) => {
      if (['left'].some((key) => hotKeysEvent.keys?.includes(key))) {
        if (focusedBoxIndex === null) {
          selectBox({ id: sentenceBoxIds.at(-1), isSelected: true })
        } else {
          let newIndex: number = focusedBoxIndex
          if (enableShiftSelect) {
            // newIndex 결정 시 쉬프트일때의 동작은 좀 특이합니다.
            // 일단 오른쪽 끝으로 갑니다. 그다음 마이너스 1을 합니다.
            // 그래서 튀어나온 결과가 만약에 커서 왼쪽이다? 그러면 왼쪽에 선택된놈들이 있다는 뜻입니다.
            // 그래서 마지막에 -1하는 루프를 다시 돌리는 겁니다.
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(newIndex + 1))) {
              newIndex += 1
            }
            newIndex -= 1

            if (newIndex < focusedBoxIndex) {
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(newIndex))) {
                newIndex -= 1
              }
            }

            if (newIndex < 0) {
              newIndex = 0
            }
          } else {
            newIndex -= 1
            if (newIndex < 0) {
              newIndex = sentenceBoxIds.length - 1
            }
          }

          selectBox({ id: sentenceBoxIds.at(newIndex), isSelected: true, keyDirection: 'left' })
        }
      }

      if (['right'].some((key) => hotKeysEvent.keys?.includes(key))) {
        if (focusedBoxIndex === null) {
          selectBox({ id: sentenceBoxIds.at(0), isSelected: true })
        } else {
          let newIndex: number = focusedBoxIndex
          if (enableShiftSelect) {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(newIndex - 1))) {
              newIndex -= 1
            }
            newIndex += 1

            if (newIndex > focusedBoxIndex) {
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(newIndex))) {
                newIndex += 1
              }
            }

            if (newIndex >= sentenceBoxIds.length) {
              newIndex = sentenceBoxIds.length - 1
            }
          } else {
            newIndex += 1

            if (newIndex >= sentenceBoxIds.length) {
              newIndex = 0
            }
          }

          selectBox({ id: sentenceBoxIds.at(newIndex), isSelected: true, keyDirection: 'right' })
        }
      }
    },
  )

  useHotkeys(
    [
      // mac
      'meta+left',
      'meta+right',
      'shift+meta+left',
      'shift+meta+right',
      // window
      'home',
      'end',
      'shift+home',
      'shift+end',
    ],
    (_, hotKeysEvent) => {
      if (hotKeysEvent.shift === false) {
        setEnableCmdSelect(false)
      }

      if (hotKeysEvent.keys?.some((key) => ['home', 'left'].includes(key))) {
        selectBox({ id: sentenceBoxIds.at(0), isSelected: true, keyDirection: 'left' })
      }
      if (hotKeysEvent.keys?.some((key) => ['end', 'right'].includes(key))) {
        selectBox({ id: sentenceBoxIds.at(-1), isSelected: true, keyDirection: 'right' })
      }

      if (hotKeysEvent.shift === false) {
        setEnableCmdSelect(true)
      }
    },
    { preventDefault: true },
  )

  useHotkeys(
    [
      // mac
      'meta+a',
      // window
      'ctrl+a',
    ],
    () => {
      selectAllBoxes()
    },
  )
}

export default useSelectHotkeys
