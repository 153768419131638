import { useState } from 'react'
import { Link } from 'react-router-dom'
// import { ReactComponent as HomeIcon } from '../../../assets/images/nav-home.svg'
// import { ReactComponent as ProjectIcon } from '../../../assets/images/nav-project.svg'
// import { ReactComponent as ExportIcon } from '../../../assets/images/nav-export.svg'
// import { ReactComponent as NavlinkBar } from '../../../assets/images/nav-link-bar.svg'

function AivatarNavLink({ id, label, to, Icon, iconClassName = '', index, currentIndex }) {
  const [isHover, setIsHover] = useState(false)
  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <Link
      key={id}
      to={to}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`nav-link relative flex h-[65px] items-center ${
        index === currentIndex || isHover ? 'text-point-1 [&_path]:fill-point-1' : `text-point-2 [&_path]:fill-point-2`
      } `}
    >
      <Icon className={`svg-fill absolute left-[20px] top-1/2 w-[25px] -translate-y-1/2 transform ${iconClassName}`} />
      <span className="ml-[72px] text-[17px] font-bold">{label}</span>
    </Link>
  )
}

export default AivatarNavLink
