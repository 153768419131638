import { selector, selectorFamily } from 'recoil'
import AivatarClient from '../../services/AivatarClient'
import { editorAudioValueState, focusedBoxValueState } from '../texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../texteditor/sentenceBox/atoms'

export const CURRENT_PLAYING_TYPE = {
  NEW: 'NEW',
  FIRST_INTERVAL: 'FIRST_INTERVAL',
  SENTENCE: 'SENTENCE',
  SENTENCE_INTERVAL: 'SENTENCE_INTERVAL',
}

export const currentPlayingTypeState = selector({
  key: 'currentPlayingTypeState',
  get: ({ get }) => {
    const firstIntervalPlayed = get(editorAudioValueState({ key: 'played' }))
    const intervalPlayed = get(
      focusedBoxValueState({
        category: SENTENCEBOX_CATEGORY.AUDIO,
        key: 'intervalPlayed',
      }),
    )
    const played = get(
      focusedBoxValueState({
        category: SENTENCEBOX_CATEGORY.AUDIO,
        key: 'played',
      }),
    )
    if (firstIntervalPlayed > 0) return CURRENT_PLAYING_TYPE.FIRST_INTERVAL
    if (intervalPlayed > 0) return CURRENT_PLAYING_TYPE.SENTENCE_INTERVAL
    if (played > 0) return CURRENT_PLAYING_TYPE.SENTENCE
    return CURRENT_PLAYING_TYPE.NEW
  },
})

export const isPlayingState = selector({
  key: 'isPlayingState',
  get: ({ get }) => {
    const currentPlayingType = get(currentPlayingTypeState)
    const playing = get(
      focusedBoxValueState({
        category: SENTENCEBOX_CATEGORY.AUDIO,
        key: 'playing',
      }),
    )

    const intervalPlaying = get(
      focusedBoxValueState({
        category: SENTENCEBOX_CATEGORY.AUDIO,
        key: 'intervalPlaying',
      }),
    )

    const firstIntervalPlaying = get(
      editorAudioValueState({
        key: 'playing',
      }),
    )

    switch (currentPlayingType) {
      case CURRENT_PLAYING_TYPE.FIRST_INTERVAL:
        return firstIntervalPlaying
      case CURRENT_PLAYING_TYPE.SENTENCE:
        return playing

      case CURRENT_PLAYING_TYPE.SENTENCE_INTERVAL:
        return intervalPlaying

      default:
        return playing
    }
  },
})

export const fetchCreateAudiosQuery = selectorFamily({
  key: 'fetchCreateAudiosQuery',
  get:
    ({ body }) =>
    async () => {
      const res = await AivatarClient().post(`audios/individual/sync`, body)

      const { data, status } = res
      if (status !== 200) throw new Error('fetchCreateAudiosQuery - Invalid Response Status')
      return data
    },
})
