import { useState } from 'react'
import { createContainer } from 'unstated-next'

const useSentenceBoxConnector = () => {
  const [sentenceBoxId, setSentenceBoxId] = useState<string | null>(null)
  const [sentenceBoxIndex, setSentenceBoxIndex] = useState<number | null>(null)

  return {
    sentenceBoxId,
    setSentenceBoxId,
    sentenceBoxIndex,
    setSentenceBoxIndex,
  }
}
export default createContainer(useSentenceBoxConnector)
