import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/arrow-down.svg'
// import { ReactComponent as AddIcon } from '../../../assets/images/menu-add.svg'
import { ReactComponent as ConfigIcon } from '../../../assets/images/menu-config.svg'
import { ReactComponent as SubscribeIcon } from '../../../assets/images/menu-subscribe.svg'
import DropdownMenu, { DropdownMenuList, DropdownMenuListItemA, DropdownMenuListTitle } from '../../DropdownMenu'
import { useCurrentWorkspaceDetailQuery } from '../../../hooks/queries'
import useAuth from '../../../hooks/useAuth'

function Menu() {
  const { t } = useTranslation('dashboard')
  const location = useLocation()
  const { workspaceId } = useParams()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <div className="flex h-[79px] w-full items-center justify-center">
      <div className="relative">
        <button
          className={`btn hover:text-main [&>svg>path]:hover:fill-main flex h-[35px] w-auto min-w-[160px] max-w-[250px] border-none px-[35px] text-[17px] font-bold hover:opacity-100 ${
            isOpen ? 'text-main [&>svg>path]:fill-main' : 'text-point-2 [&>svg>path]:fill-point-2'
          }`}
          onClick={() => {
            setIsOpen((prevIsOpen) => !prevIsOpen)
          }}
        >
          <span className="overflow-hidden text-ellipsis">{workspace?.name}</span>
          <ArrowDownIcon className="ml-[12px] mt-[2px] shrink-0" />
        </button>
        {isOpen && (
          <DropdownMenu className="top-[40px] w-[254px]" onOutsideClick={() => setIsOpen(false)}>
            {/* todo - remove pb-0 border-b-0 */}
            <DropdownMenuList>
              <DropdownMenuListItemA
                label={t('워크스페이스 설정')}
                Icon={ConfigIcon}
                to={`/workspace/${workspaceId}/config`}
              />
              <DropdownMenuListItemA
                label={t('구독 관리')}
                Icon={SubscribeIcon}
                to={`/workspace/${workspaceId}/subscription`}
              />
            </DropdownMenuList>
            <DropdownMenuList>
              <DropdownMenuListTitle>{t('워크스페이스 목록')}</DropdownMenuListTitle>
              {user.workspaceList.map((workspaceItem) => (
                <DropdownMenuListItemA
                  label={workspaceItem.name}
                  to={`/workspace/${workspaceItem.id}`}
                  selected={workspaceItem.id === Number(workspaceId)}
                />
              ))}
            </DropdownMenuList>
            {/* todo - remove comment */}
            {/* <DropdownMenuList>
              <DropdownMenuListItemA className="[&_svg]:mr-[10px]" label="워크스페이스 추가" Icon={AddIcon} />
            </DropdownMenuList> */}
          </DropdownMenu>
        )}
      </div>
    </div>
  )
}

export default Menu
