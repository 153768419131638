import { useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as ApplyAllIcon } from '../../../../assets/images/icon-expand.svg'
import useAivatarModal from '../../../../hooks/useAivatarModal'
import useEditorCallbacks from '../../../../recoil/texteditor/editor/useEditorCallbacks'
import OptionSettingModal from '../OptionSettingModal'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import AivatarButton from '../../../../components/buttons/AivatarButton'

const UPDATE_PROJECT_ACTION_TYPE = {
  NEW: 'NEW',
  UPDATE: 'UPDATE',
}

function ApplyAllOptions() {
  const { t } = useTranslation('project-option')
  const [actionType, setActionType] = useState(UPDATE_PROJECT_ACTION_TYPE.NEW)

  /** Recoilds */
  const { applyAllOptions } = useEditorCallbacks()

  /** Services */
  const { fetchProject } = useUpdateProject()

  /** Events */
  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    openOptionSettingModal()
  }

  const onClickClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    closeOptionSettingModal()
  }

  const onClickAllApply = () => {
    applyAllOptions()
    setActionType(UPDATE_PROJECT_ACTION_TYPE.UPDATE)
  }

  /** Modal */
  const {
    modalComponent: OptionSettingModalComponent,
    openModal: openOptionSettingModal,
    closeModal: closeOptionSettingModal,
  } = useAivatarModal({
    children: (
      <OptionSettingModal
        titleComponent={
          <p className="mb-[20px] px-[42px] text-center text-[24px] font-bold">
            <Trans t={t}>
              현재 선택된 문장박스의 옵션이 <span className="main_c">전체 적용</span>
              됩니다.
            </Trans>
          </p>
        }
        subTitle={t('현재 선택된 문장박스의 옵션이 전체 적용됩니다.')}
        onClickSetting={onClickAllApply}
        onClickClose={onClickClose}
        setButtonText={t('전체 적용')}
      />
    ),
    closeOnDocumentClick: true,
  })

  /** Action Effects */
  useAivatarDidMount(async () => {
    if (actionType !== UPDATE_PROJECT_ACTION_TYPE.UPDATE) return
    setActionType(UPDATE_PROJECT_ACTION_TYPE.NEW)
    try {
      await fetchProject()
    } catch (error) {
      /* empty */
    }
    closeOptionSettingModal()
  }, [actionType])

  /** Memos */

  return (
    <AivatarButton type="button" variant="m3" className="h-[30px]" onClick={onClick}>
      {OptionSettingModalComponent}
      <span className="mr-[5px] text-[14px] font-medium">{t('전체 적용')}</span>
      <ApplyAllIcon />
    </AivatarButton>
  )
}

export default ApplyAllOptions
