import { selector } from 'recoil'
import navigatorIndexState from './atoms'

export const isAvatarEditorShowState = selector({
  key: 'isAvatarEditorShowState',
  get: ({ get }) => {
    const navigatorIndex = get(navigatorIndexState)
    return navigatorIndex === 1
  },
})

export const dummy = null
