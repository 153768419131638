import { useTranslation } from 'react-i18next'
import Section from '../../components/Dashboard/Section'
import PaymentHistorySection from '../../components/Dashboard/Subscription/PaymentHistorySection'
import SubscriptionPlanSection from '../../components/Dashboard/Subscription/SubscriptionPlanSection'
import PaymentInfoSection from '../../components/Dashboard/Subscription/PaymentInfoSection'
import { useCurrentWorkspaceDetailQuery } from '../../hooks/queries'

export default function Subscription() {
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const { paymentMethod, isManualPayment } = workspace?.subscription ?? {}
  const { t } = useTranslation('subscription')

  return (
    <Section
      className="mt-[40px]"
      title={t('구독 관리')}
      description={t('구독 중인 플랜과 결제 정보를 확인할 수 있습니다.')}
    >
      <SubscriptionPlanSection className="mt-[55px]" />
      {paymentMethod || workspace?.subscription?.isManualPayment ? (
        <PaymentInfoSection className="mt-[16px]" isManualPayment={isManualPayment} />
      ) : null}
      {paymentMethod ? <PaymentHistorySection className="mt-[39px]" /> : null}
    </Section>
  )
}
