import React, { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

type Variant = 'm1' | 'm3' | 'm4' | 'g3' | 'g5' | 'p3' | 'p6'

export type AivatarButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: Variant
}

const variants: Record<Variant, string> = {
  m1: clsx(
    'bg-main-1 text-gray-7 hover:text-gray-7 [&_path]:fill-gray-7 [&_path]:hover:fill-gray-7 hover:bg-[#004FFF]',
  ),
  m3: clsx(
    'bg-main-3 text-main-1 hover:text-gray-7 [&_path]:fill-main-1 [&_path]:hover:fill-gray-7 hover:bg-[#004FFF]',
  ),
  m4: clsx(
    'bg-main-4 text-main-1 hover:text-gray-7 [&_path]:fill-main-1 [&_path]:hover:fill-gray-7 hover:bg-[#004FFF]',
  ),
  g3: clsx(
    'bg-gray-3 text-gray-7 hover:text-gray-7 [&_path]:fill-gray-7 [&_path]:hover:fill-gray-7 hover:bg-[#004FFF]',
  ),
  g5: clsx(
    'bg-gray-5 text-gray-3 hover:text-gray-7 [&_path]:fill-gray-3 [&_path]:hover:fill-gray-7 hover:bg-[#004FFF]',
  ),
  p3: clsx(
    'bg-point-3 text-gray-7 hover:text-gray-7 [&_path]:fill-gray-7 [&_path]:hover:fill-gray-7 hover:bg-[#EC2424]',
  ),
  p6: clsx(
    'bg-point-6 text-point-5 hover:text-gray-7 [&_path]:fill-point-5 [&_path]:hover:fill-gray-7 hover:bg-[#FFB23E]',
  ),
}

export default function AivatarButton({ variant = 'm1', children, className, ...rest }: AivatarButtonProps) {
  return (
    <button
      className={twMerge(
        'group flex h-[43px] items-center justify-center whitespace-nowrap rounded-[50px] px-[20px] text-[16px] font-[700] transition-all',
        variants[variant],
        rest.disabled
          ? variant === 'g3'
            ? clsx(variants.g3, 'disabled:pointer-events-none')
            : clsx(variants.g5, 'disabled:pointer-events-none')
          : null,
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
