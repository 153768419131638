import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import Section from '../../components/Dashboard/Section'
import { Config, ConfigName, ConfigBody, ConfigButton } from '../../components/Dashboard/WorkspaceConfig/Config'
import useDialog from '../../hooks/useDialog'
import MemberTable from '../../components/Dashboard/WorkspaceConfig/MemberTable'
import {
  useCurrentWorkspaceContracted,
  useCurrentWorkspaceDetailQuery,
  useCurrentWorkspacePaymentFailedInfo,
  useCurrentWorkspacePlanName,
} from '../../hooks/queries'
import AivatarClient from '../../services/AivatarClient'
import SubscriptionPlanDialog from '../../components/SubscriptionPlan/SubscriptionPlanDialog'
import { customDayjs } from '../../utils/date'

export default function WorkspaceConfig() {
  const { t } = useTranslation('workspace')
  const dialog = useDialog()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const [coupon, setCoupon] = useState(workspace?.couponCode)
  const planName = useCurrentWorkspacePlanName()
  const contracted = useCurrentWorkspaceContracted()
  const { paymentFailed, paymentMethodChanged } = useCurrentWorkspacePaymentFailedInfo()

  const isPause = workspace?.subscription?.status === 'PAUSE'

  const onCouponChange = (newCoupon) => {
    setCoupon(newCoupon)
  }

  const showCouponPrompt = (props) => {
    dialog.alert(props)
  }

  const showSubscriptionPlanSelector = () => {
    dialog.open(<SubscriptionPlanDialog expanded />)
  }

  const showNamePrompt = () => {
    dialog.prompt({
      title: t('워크스페이스 이름 변경'),
      message: t('변경할 워크스페이스 이름을 입력해 주세요.'),
      placeholder: t('워크스페이스 이름을 입력해 주세요.'),
    })
  }
  const showInvitationPrompt = () => {
    dialog.prompt({
      title: t('멤버 초대'),
      message: t('초대할 멤버의 이메일을 입력해 주세요.'),
      placeholder: t('e-mail주소를 입력해 주세요.'),
      confirmButtonName: t('초대'),
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onClickConfirm: showInvitationComplete,
    })
  }

  const showInvitationComplete = () => {
    dialog.confirm({
      iconType: 'complete',
      title: t('초대 완료!'),
      message: t('입력하신 이메일 주소로\n초대 링크가 포함된 이메일을 발송하였습니다.'),
      cancelButtonName: t('이어서 초대하기'),
      onClickConfirm: dialog.close,
      onClickCancel: showInvitationPrompt,
    })
  }

  const redeem = () => {
    if (!coupon) {
      showCouponPrompt({
        title: t('쿠폰 번호를 입력하세요!'),
        message: t('올바른 쿠폰 번호를 입력하세요.'),
        highlightColor: 'point-3',
      })
      return
    }

    const url = `/workspaces/${workspace?.id}/redeem?code=${coupon}`
    AivatarClient()
      .get(url)
      .then(() => {
        showCouponPrompt({
          title: t('쿠폰 코드 입력 성공!'),
          message: t('크레딧이 발급되었습니다.'),
          onClickConfirm: () => {
            window.location.reload()
          },
        })
      })
      .catch(() => {
        showCouponPrompt({
          title: t('쿠폰 코드 입력 실패!'),
          message: t('크레딧 발급 실패했습니다.'),
          highlightColor: 'point-3',
        })
      })
  }

  return (
    <div>
      <Section
        className="mt-[40px] pb-[55px]"
        title={t('워크스페이스 설정')}
        description={t('워크스페이스의 기본 설정을 변경할 수 있습니다.')}
      >
        <Config className="mt-[55px]">
          <ConfigName>{t('워크스페이스 이름')}</ConfigName>
          <ConfigBody>
            <span className="text-gray-2 font-light">{workspace?.name}</span>
            {/* todo - remove invisible */}
            <ConfigButton className="invisible" onClick={showNamePrompt}>
              {t('변경하기')}
            </ConfigButton>
          </ConfigBody>
        </Config>
        <Config className="mt-[19px]">
          <ConfigName>{t('플랜')}</ConfigName>
          <ConfigBody className="!justify-start">
            <button
              translate="no"
              type="button"
              className={twMerge(
                'btn-main-3 h-[31px] w-auto cursor-pointer px-[20px] text-[14px] font-medium',
                isPause ? 'bg-point-3 text-white' : null,
              )}
              onClick={() => showSubscriptionPlanSelector()}
            >
              {isPause ? t('비활성 플랜') : planName}
            </button>
            {!contracted && workspace?.subscription ? (
              <span className="text-gray-21 ml-[11px] text-[12px]">
                {customDayjs(workspace?.subscription.subscribedAt).format('YYYY/MM/DD')}
              </span>
            ) : null}
            {/* <Link className="ml-auto" to={`/workspace/${workspace?.id}/subscription`}>
              <ConfigButton className="btn-main-3">변경하기</ConfigButton>
            </Link> */}
          </ConfigBody>
          {isPause ? (
            <p className="text-point-3 mt-[10px] pl-[5px] text-[12px] font-[400]">{t('플랜을 선택해 주세요.')}</p>
          ) : null}
          {!isPause && paymentFailed && !paymentMethodChanged ? (
            <p className="text-point-3 mt-[10px] pl-[5px] text-[12px] font-[400]">
              {t('결제에 문제가 있습니다. 구독 관리 화면에서 결제 정보를 확인해 주세요.')}
            </p>
          ) : null}
        </Config>
      </Section>
      <Section
        className="hidden"
        title={t('쿠폰 등록')}
        description={t('메일로 전달 받은 쿠폰 번호를 입력해 주세요. 쿠폰은 워크스페이스당 한 번만 등록할 수 있습니다.')}
      >
        <input
          className="input mt-[10px]"
          onChange={(e) => onCouponChange(e.target.value)}
          style={{ width: '25%' }}
          value={coupon || ''}
          disabled={workspace?.couponCode ? 'disabled' : ''}
        />
        <button
          className={`btn-l my-[20px] font-medium ${
            workspace?.couponCode ? 'btn-gray-5 text-[#9F9F9F] hover:bg-[#F1F1F1] hover:text-[#9F9F9F]' : 'btn-main'
          }`}
          style={{ width: '25%' }}
          onClick={redeem}
          disabled={workspace?.couponCode ? 'disabled' : ''}
        >
          {t('입력하기')}
        </button>
      </Section>
      <Section
        title={t('멤버 관리')}
        description={t(
          '멤버를 등록하고 관리할 수 있습니다. 멤버 설정 후 역할에 따라 워크스페이스를 관리할 수 있습니다.',
        )}
      >
        {/* todo - remove hidden */}
        <Config className="mt-[45px] hidden">
          <ConfigBody>
            <ConfigName>{t('멤버')}</ConfigName>
            <ConfigButton onClick={showInvitationPrompt}>{t('초대하기')}</ConfigButton>
          </ConfigBody>
        </Config>
        <MemberTable className="mt-[50px]" rows={workspace?.members} />
      </Section>
    </div>
  )
}
