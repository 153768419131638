import React, { KeyboardEventHandler, MouseEventHandler, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import PlayButton from '../components/PlayButton'
import data from './data'
import LanguageSelectButton from '../components/LanguageSelectButton'

export default function InternationalizationPage() {
  const [playing, setPlaying] = useState(false)
  const [selectedDataIdx, setSelectedDataIdx] = useState(0)

  const { i18n } = useTranslation()
  const playerRef = useRef<ReactPlayer>(null)

  const videoUrl = useMemo(() => {
    const selectedData = data[selectedDataIdx]

    if (!selectedData) return undefined

    switch (true) {
      case i18n.language.startsWith('ko'):
        return selectedData.url.ko
      case i18n.language.startsWith('en'):
        return selectedData.url.en
      default:
        return selectedData.url.en
    }
  }, [i18n.language, selectedDataIdx])

  const changeData = (index: number) => {
    setPlaying(true)
    setSelectedDataIdx(index)
  }

  const togglePlay = () => {
    setPlaying((prev) => !prev)
  }

  const handlePlayerEnded = () => {
    setPlaying(false)
    playerRef.current?.seekTo(0)
  }

  const handleItemButtonClicked: MouseEventHandler<HTMLButtonElement> = (e) => {
    const dataIndex = e.currentTarget.dataset.index
    const index = dataIndex ? Number(dataIndex) : 0

    if (selectedDataIdx !== index) {
      changeData(index)
    }

    e.stopPropagation()
  }

  const handlePlayToggleButtonClicked: MouseEventHandler<HTMLButtonElement> = (e) => {
    togglePlay()

    e.stopPropagation()
  }

  const handleContainerClicked: MouseEventHandler<HTMLDivElement> = () => {
    togglePlay()
  }

  const handleKeyPressAtContainer: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === 'Space') {
      togglePlay()
    }
  }

  return (
    <div className="relative h-[340px] max-h-[100vh] w-[602px] max-w-[100vw] items-start [&_video]:object-contain [&_video]:object-top">
      <div
        role="button"
        tabIndex={0}
        className="aspect-[602/340] overflow-hidden rounded-[20px]"
        onClick={handleContainerClicked}
        onKeyDown={handleKeyPressAtContainer}
        aria-label="video"
      >
        <ReactPlayer
          // react-player는 key로 재 렌더링 유도해야 url 교체 가능
          key={videoUrl}
          ref={playerRef}
          url={videoUrl}
          playing={playing}
          onEnded={handlePlayerEnded}
          width="100%"
          height="100%"
          config={{ file: { attributes: { borderRadius: 20 } } }}
        />
      </div>

      <PlayButton
        className="absolute left-[16px] top-[16px]"
        playing={playing}
        onClick={handlePlayToggleButtonClicked}
      />

      <div className="absolute bottom-0 w-full overflow-x-auto py-[20px] text-center sm:py-[23px]">
        <ul className="inline-flex gap-[25px]">
          {data.map((item, index) => (
            <li key={item.label.en}>
              <LanguageSelectButton
                index={index}
                selected={index === selectedDataIdx}
                onClick={handleItemButtonClicked}
              >
                {item.label[i18n.language.startsWith('ko') ? 'ko' : 'en']}
              </LanguageSelectButton>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
