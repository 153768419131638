import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import useDialog from '../../hooks/useDialog'
import Section from '../../components/Dashboard/Section'
import RemoveSelectedRowsButton from '../../components/buttons/RemoveSelectedRowsButton'
import Table from '../../components/Table'
import { ReactComponent as LinkIcon } from '../../assets/images/icon-link.svg'
import useDeleteProject from '../../services/project/useDeleteProject'
import NoItemsFound from '../../assets/images/no-items-found.png'
import { useCurrentWorkspaceProjectListQuery } from '../../hooks/queries'
import { workspaceQueries } from '../../services/workspace'
import { customDayjs } from '../../utils/date'

export default function ProjectList() {
  const { t } = useTranslation('project-list')
  const dialog = useDialog()
  const queryClient = useQueryClient()
  const { workspaceId } = useParams()

  const [page, setPage] = useState(0)
  const pageSize = 10
  const [selected, setSelected] = useState([])
  const [sort, setSort] = useState([{ key: 'createdAt', order: 'desc' }])

  // const projectList = useProjectList()
  const { data, isSuccess } = useCurrentWorkspaceProjectListQuery({
    page,
    size: pageSize,
    sort: sort[0] ? `${sort[0].key},${sort[0].order}` : undefined,
  })
  const { fetchDeleteProject } = useDeleteProject()

  // const fetchProject = () => {
  //   projectList.fetchProjectList({
  //     page,
  //     size: pageSize,
  //     sort: sort[0] ? `${sort[0].key},${sort[0].order}` : undefined,
  //   })
  // }

  const onClickDeleteProject = () => {
    const promises = selected.map((projectId) => fetchDeleteProject({ projectId }))
    Promise.all(promises).then(() => {
      dialog.close()
      queryClient.invalidateQueries({ queryKey: workspaceQueries.detail(Number(workspaceId))._ctx.projects.queryKey })
    })
  }

  const columns = useMemo(
    () => [
      {
        key: 'createdAt',
        name: t('생성일'),
        className: 'text-left !text-main-2 w-[137.5px]',
        render: (row) => customDayjs(row.createdAt).format('YYYY/MM/DD'),
        sortable: true,
      },
      {
        key: 'title',
        name: t('프로젝트 이름'),
        className: 'text-left w-[180px] max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden',
        sortable: true,
      },
      {
        key: 'str',
        name: t('콘텐츠'),
        className: 'text-left pr-[10px] w-[480px] max-w-[480px] text-ellipsis whitespace-nowrap overflow-hidden',
        sortable: false,
        render: (row) => <Link to={`/workspace/${row.workspaceId}/project/${row.id}`}>{row.str}</Link>,
      },
      {
        key: 'updatedAt',
        name: t('최근 수정일'),
        className: 'text-left text-[#9F9F9F] w-[137.5px]',
        render: (row) => customDayjs(row.updatedAt).format('YYYY/MM/DD'),
        sortable: true,
      },
      {
        key: 'id',
        name: t('프로젝트'),
        className: 'w-[60px]',
        render: (row) => (
          <a
            className="mx-auto block w-fit"
            href={`/workspace/${row.workspaceId}/project/${row.id}`}
            aria-label={t('프로젝트 보기')}
          >
            <LinkIcon />
          </a>
        ),
      },
    ],
    [t],
  )

  const confirmRemove = () => {
    dialog.confirm({
      title: (
        <Trans t={t}>
          프로젝트를
          <br />
          <strong>삭제</strong>
          하시겠습니까?
        </Trans>
      ),
      message: t('삭제를 누르면 해당 프로젝트가 삭제됩니다.'),
      confirmButtonName: t('삭제'),
      highlightColor: 'point-3',
      onClickConfirm: onClickDeleteProject,
    })
  }

  // useEffect(() => {
  //   fetchProject()
  //   //! 위험한 부분
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, sort])

  return (
    <div className="mt-[40px]">
      <Section title={t('프로젝트 목록')} description={t('생성한 프로젝트를 모두 확인할 수 있습니다.')}>
        <Table
          useSelect
          selected={selected}
          onChangeSelected={setSelected}
          usePagination
          columns={columns}
          rows={data?.content}
          total={data?.totalElements ?? 0}
          page={page}
          pageSize={pageSize}
          sort={sort}
          onChangeSort={setSort}
          onChangePage={setPage}
          header={selected.length > 0 && <RemoveSelectedRowsButton onClick={confirmRemove} />}
          noDataImage={NoItemsFound}
          isSuccess={isSuccess}
        />
      </Section>
    </div>
  )
}
