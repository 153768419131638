import { useState } from 'react'
import DropdownMenu, { DropdownMenuList, DropdownMenuListItem } from './DropdownMenu'
import { ReactComponent as ArrowDownIcon } from '../assets/images/arrow-down.svg'

function DropdownSelectItem({ children, ...otherProps }) {
  return (
    <DropdownMenuListItem className="hover:text-main px-[15px] py-[10px]" {...otherProps}>
      {children}
    </DropdownMenuListItem>
  )
}

export default function DropdownSelect({ className = '', value, items, onChange }) {
  const [isOpen, setIsOpen] = useState(false)

  const selectedItem = items.find((item) => item.value === value)

  return (
    <div className={`relative w-[300px] ${className}`}>
      <button
        className="border-gray-4 text-gray-2 flex h-[45px] w-full items-center rounded-full border px-[25px]"
        onClick={() => setIsOpen(true)}
      >
        {selectedItem?.name}
        <ArrowDownIcon className="[&_path]:fill-gray-2 ml-auto w-[14px]" />
      </button>
      {isOpen && (
        <DropdownMenu className="top-[50px] w-full" onOutsideClick={() => setIsOpen(false)}>
          <DropdownMenuList>
            {items.map((item) => (
              <DropdownSelectItem
                key={item.value}
                onClick={() => {
                  onChange(item)
                  setIsOpen(false)
                }}
              >
                {item.name}
              </DropdownSelectItem>
            ))}
          </DropdownMenuList>
        </DropdownMenu>
      )}
    </div>
  )
}
