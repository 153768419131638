type Props = {
  row: any & {
    id: string
  }
  selected: string[]
  onChangeSelected: (selected: Props['selected']) => void
}

export default function CheckboxRow({ row, selected, onChangeSelected }: Props) {
  return (
    <td className="w-[60px] !pl-[10px] text-center">
      <input
        type="checkbox"
        checked={selected.includes(row.id)}
        onChange={(e) => {
          if (e.target.checked) {
            onChangeSelected?.([...selected, row.id])
          } else {
            onChangeSelected?.(selected.filter((id) => id !== row.id))
          }
        }}
      />
      {selected.includes(row.id) && (
        <div className="pointer-events-none absolute left-0 top-0 h-full w-full px-[14px] py-[6px]">
          <div className="border-main h-full rounded-[10px] border-[2px]" />
        </div>
      )}
    </td>
  )
}
