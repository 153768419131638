export const getPlanColor = (planName) => {
  switch (planName?.toUpperCase()) {
    case 'FREE':
      return '#9f9f9f'
    case 'BASIC':
      return '#2db277'
    case 'PRO':
      return '#ff9900'
    default:
      return '#3a74f5'
  }
}
export const tmp = () => {}
