import { useState, useMemo } from 'react'
import { motion } from 'framer-motion'

import { useRecoilValue } from 'recoil'
import PlusIcon from '../../../../../assets/images/editor-plus.png'

import useEditorCallbacks from '../../../../../recoil/texteditor/editor/useEditorCallbacks'
import { isCreatorOpenState } from '../../../../../recoil/texteditor/sentenceBox/selectors'

function FirstAddParagraphButton() {
  /** Recoils */

  const [isAddingHover, setIsAddingHover] = useState(false)
  const isCreatorOpen = useRecoilValue(isCreatorOpenState)

  const { insertCreatorAtIndex } = useEditorCallbacks()

  /** Event Handlers */
  const onClickAddSentence = () => {
    if (isCreatorOpen) return
    insertCreatorAtIndex(0)
  }

  /** Event Subscribers */
  const onMouseEnter = () => {
    setIsAddingHover(true)
  }

  const onMouseLeave = () => {
    setIsAddingHover(false)
  }

  /** Memos */
  const isOpenBoxCreator = useMemo(() => isAddingHover && !isCreatorOpen, [isAddingHover, isCreatorOpen])

  return (
    <motion.span className="inline-block w-[30px] align-middle" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <motion.span
        className="text-cetner relative inline-block h-full w-full cursor-pointer align-middle"
        onClick={onClickAddSentence}
        animate={{
          opacity: isOpenBoxCreator ? 1 : 0,
        }}
      >
        <div className="absolute bottom-[3px] inline-flex h-full w-full items-center justify-center">
          <img className="h-[24px] w-[24px] opacity-50 hover:opacity-100" src={PlusIcon} alt="plus" />
        </div>
      </motion.span>
    </motion.span>
  )
}

export default FirstAddParagraphButton
