import { CircularProgress } from '@mui/joy'

function AKLoading({ size = '30px', color = '#3A74F5', thickness = '4px', zIndex = 'z-10' }) {
  return (
    <div className={`absolute left-0 top-0 flex h-full w-full items-center justify-center ${zIndex}`}>
      <CircularProgress
        sx={{
          '--CircularProgress-size': size,
          '--CircularProgress-progressThickness': thickness,
          '--CircularProgress-trackThickness': thickness,
          '--CircularProgress-progressColor': color,
        }}
        determinate={false}
      />
    </div>
  )
}

export default AKLoading
