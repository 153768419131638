import { useTranslation } from 'react-i18next'
import Dialog from '.'
import { ReactComponent as IconWarning } from '../../assets/images/modal-warning.svg'
import { ReactComponent as IconComplete } from '../../assets/images/modal-complete.svg'
import AivatarButton from '../buttons/AivatarButton'

export default function Confirm({
  iconType,
  highlightColor = 'main',
  title,
  message,
  useCancel = false,
  cancelButtonName,
  confirmButtonName,
  onClickCancel,
  onClickConfirm,
  children,
  ...otherProps
}) {
  const { t } = useTranslation('modal')
  const Icon = iconType === 'complete' ? IconComplete : IconWarning

  const finalCancelButtonName = cancelButtonName ?? t('취소')
  const finalConfirmButtonName = confirmButtonName ?? t('확인')

  return (
    <Dialog {...otherProps}>
      <Icon />
      <span
        className={`text-gray-2 mt-[19px] whitespace-pre text-center text-[23px] font-bold [&>strong]:font-bold ${
          highlightColor === 'point-3' ? '[&>strong]:text-point-3' : '[&>strong]:text-main'
        }`}
      >
        {title}
      </span>
      {message && <span className="text-gray-3 mt-[19px] whitespace-pre text-center">{message}</span>}
      {children}
      <div className="mt-[23px] flex gap-[15px] px-[17px]">
        {useCancel && (
          <AivatarButton
            type="button"
            variant="g5"
            className="btn-gray-5 text-gray-2 h-[45px] w-auto min-w-[120px] shrink-0 whitespace-nowrap px-[25px] font-bold"
            onClick={onClickCancel}
          >
            {finalCancelButtonName}
          </AivatarButton>
        )}
        <AivatarButton
          type="button"
          variant="m1"
          className={`${
            highlightColor === 'point-3' ? 'btn-point-3' : 'btn-main'
          } h-[45px] w-auto min-w-[120px] shrink-0 whitespace-nowrap px-[25px] font-bold`}
          onClick={onClickConfirm}
        >
          {finalConfirmButtonName}
        </AivatarButton>
      </div>
    </Dialog>
  )
}
