import { useRecoilValue, useSetRecoilState } from 'recoil'

import { SENTENCEBOX_TYPE } from '../../../../recoil/texteditor/sentenceBox/atoms'

import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import SentenceBox from '../SentenceBox'
import SentenceBoxConnector from '../../../../providers/SentenceBoxConnector'
import ParagraphCreator from '../ParagraphCreator'
import {
  sentenceBoxAudioValueState,
  sentenceBoxOptionValueState,
  sentenceBoxValueState,
} from '../../../../recoil/texteditor/sentenceBox/selectors'

function SentenceBoxRoot({ id, index }) {
  /** Recoils */
  const { setSentenceBoxId: setSentenceBoxConnectorId, setSentenceBoxIndex: setSentenceBoxConnectorIndex } =
    SentenceBoxConnector.useContainer()

  const type = useRecoilValue(sentenceBoxValueState({ id, key: 'type' }))

  const setSentenceBoxId = useSetRecoilState(sentenceBoxValueState({ id, key: 'id' }))
  const setSentenceBoxIndex = useSetRecoilState(sentenceBoxValueState({ id, key: 'index' }))

  const setSentenceBoxAudioId = useSetRecoilState(sentenceBoxAudioValueState({ id, key: 'id' }))

  const setSentenceBoxOptionId = useSetRecoilState(sentenceBoxOptionValueState({ id, key: 'id' }))

  /** Methods */
  const initSentenceBox = (sentenceBoxId, sentenceBoxIndex) => {
    setSentenceBoxId(sentenceBoxId)
    setSentenceBoxIndex(sentenceBoxIndex)
    setSentenceBoxAudioId(sentenceBoxId)
    setSentenceBoxOptionId(sentenceBoxId)
  }

  /** Life Cycle */
  useAivatarDidMount(() => {
    // connectors
    setSentenceBoxConnectorId(id)
    setSentenceBoxConnectorIndex(index)

    // init recoils
    initSentenceBox(id, index)
  }, [id, index])

  /** Event Handler */

  /** Event Subscribers */

  /** Memos */

  /** Display */
  let contents = null
  switch (type) {
    case SENTENCEBOX_TYPE.VIEWER:
      contents = <SentenceBox />
      break
    case SENTENCEBOX_TYPE.CREATOR:
      contents = <ParagraphCreator />
      break

    default:
      break
  }

  return contents
}

export default SentenceBoxRoot
