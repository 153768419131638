import { useState, useMemo, useCallback } from 'react'

import ArrowUpIcon from '../../../../../assets/images/global_arrow-up.png'
import ArrowDownIcon from '../../../../../assets/images/global_arrow-down.png'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'

function useOpenSelect({ title, order }) {
  const [isOpen, setIsOpen] = useState(false)

  /** Recoilds */

  /** Fetching */

  /** Life Cycle */
  useAivatarDidMount(() => {}, [])

  /** Events */
  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const component = useMemo(
    () => (
      <button type="button" className="group flex w-full items-center justify-between" onClick={handleOpen}>
        <div className="flex items-center justify-center">
          <p className="main_c group-hover:text-gray-7 mr-[15px] rounded-full bg-[#F5F7FE] px-[14px] py-[3px] text-[12px] group-hover:bg-[#004fff]">{`Select ${order}`}</p>
          <p className="text-[17px] font-bold">{title}</p>
        </div>
        <img className="h-[25px] w-[25px]" src={isOpen ? ArrowUpIcon : ArrowDownIcon} alt="arrowdown" />
      </button>
    ),
    [handleOpen, order, title, isOpen],
  )

  return {
    component,
    isOpen,
    setIsOpen,
  }
}

export default useOpenSelect
