import { ReactComponent as CrownIcon } from '../../../../../assets/images/icon-crown.svg'
import useDialog from '../../../../../hooks/useDialog'
import SubscriptionPlanDialog from '../../../../../components/SubscriptionPlan/SubscriptionPlanDialog'
import AivatarButton from '../../../../../components/buttons/AivatarButton'

export default function UpgradeButton() {
  const dialog = useDialog()

  const handleClicked = () => {
    dialog.open(<SubscriptionPlanDialog expanded />)
  }

  return (
    <AivatarButton type="button" variant="p6" onClick={handleClicked}>
      <span className="mr-[5px]">UPGRADE</span>
      <CrownIcon />
    </AivatarButton>
  )
}
