import { useOutlet, useLocation, Navigate } from 'react-router-dom'
import DashboardHeader from './Header'
import NavBar from './NavBar'
import useAuth from '../../hooks/useAuth'
import { LocalStorage } from '../../utils/storage'
import NotificationBars from './NotificationBars'

function Dashboard() {
  const outlet = useOutlet()
  const location = useLocation()
  const { user } = useAuth()

  if (location.pathname === '/') {
    let initialWorkspaceId = user?.workspaceList[0].id

    const allowedWorkspaceIdList = user.workspaceList.map((workspaceItem) => workspaceItem.id)

    const { lastAccessedWorkspaceId } = LocalStorage
    if (allowedWorkspaceIdList.includes(lastAccessedWorkspaceId)) {
      initialWorkspaceId = lastAccessedWorkspaceId
    }

    if (initialWorkspaceId) {
      return <Navigate to={`/workspace/${initialWorkspaceId}`} replace />
    }
    throw new Error('user has no workspace')
  }

  return (
    <>
      <div className="flex h-full w-full">
        <NavBar />
        <div className="flex h-full flex-grow flex-col">
          <DashboardHeader />
          <div className="flex-grow overflow-y-scroll">
            <div className="relative min-h-full max-w-[1390px] px-[50px] pb-[50px]">{outlet}</div>
          </div>
        </div>
      </div>
      <NotificationBars />
    </>
  )
}

export default Dashboard
