import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import NotificationBar from '../NotificationBar'
import { useCurrentWorkspaceDetailQuery, useCurrentWorkspacePaymentFailedInfo } from '../../hooks/queries'

export default function NotificationBars() {
  const { t } = useTranslation('notification-bar')
  const navigate = useNavigate()
  const location = useLocation()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const { paymentFailed, paymentMethodChanged } = useCurrentWorkspacePaymentFailedInfo()

  const isPause = workspace?.subscription?.status === 'PAUSE'

  const pauseNotificationBar = useMemo(() => {
    if (!workspace) {
      return null
    }

    if (
      location.pathname.startsWith(`/workspace/${workspace.id}/subscription`) ||
      location.pathname.startsWith(`/workspace/${workspace.id}/config`)
    ) {
      return null
    }

    if (isPause) {
      return (
        <NotificationBar
          message={t('워크스페이스가 비활성화되었습니다. 플랜을 선택하고 다시 구독해 주세요.')}
          proceedText={t('구독하기')}
          onProceed={() => {
            navigate(`/workspace/${workspace?.id}/subscription`)
          }}
        />
      )
    }

    return null
  }, [isPause, location.pathname, navigate, t, workspace])

  const paymentFailNotificationBar = useMemo(() => {
    if (!workspace) {
      return null
    }

    if (
      location.pathname.startsWith(`/workspace/${workspace.id}/subscription`) ||
      location.pathname.startsWith(`/workspace/${workspace.id}/config`)
    ) {
      return null
    }

    if (!isPause && paymentFailed && !paymentMethodChanged) {
      return (
        <NotificationBar
          message={t('결제에 문제가 있습니다. 결제 정보를 확인해 주세요.')}
          proceedText={t('확인하기')}
          onProceed={() => {
            navigate(`/workspace/${workspace?.id}/subscription`)
          }}
        />
      )
    }

    return null
  }, [isPause, location.pathname, navigate, paymentFailed, paymentMethodChanged, t, workspace])

  return (
    <>
      {pauseNotificationBar}
      {paymentFailNotificationBar}
    </>
  )
}
