import { useRecoilValue } from 'recoil'
import VoiceItem from '../VoiceItem'
import { voiceListBySelectedLanguageState } from '../../../../../recoil/workspace/selectors'

import { focusedBoxValueState } from '../../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../../../../recoil/texteditor/sentenceBox/atoms'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'

function VoiceItemList({ itemWidth, itemHeight, handleSelector }) {
  /** Recoilds */
  // const [voice, setVoice] = React.useState(voices[0]);

  const language = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'language',
    }),
  )

  const voices = useRecoilValue(voiceListBySelectedLanguageState({ language }))

  useAivatarDidMount(() => {})

  return (
    <div
      className="aivatar_scroll_wrapper flex w-full flex-col overflow-hidden overflow-y-auto px-[3px] pt-[3px]"
      style={{
        // width: itemWidth,
        maxHeight: itemHeight * 3,
      }}
    >
      {voices.map((voice, index) => (
        <VoiceItem
          key={`VoiceItem-${voice.name}`}
          index={index}
          handleSelector={handleSelector}
          itemWidth={itemWidth}
          voice={voice}
          isLast={index === voices.length - 1}
        />
      ))}
    </div>
  )
}

export default VoiceItemList
