function audioBufferToWav(buffer: AudioBuffer): ArrayBuffer {
  const numOfChan = buffer.numberOfChannels
  const length = buffer.length * numOfChan * 2 + 44
  const bufferArray = new ArrayBuffer(length)
  const view = new DataView(bufferArray)
  const channels = []
  // let offset = 0
  let pos = 0

  function setUint16(data: number) {
    view.setUint16(pos, data, true)
    pos += 2
  }

  function setUint32(data: number) {
    view.setUint32(pos, data, true)
    pos += 4
  }

  // WAV 헤더 작성
  setUint32(0x46464952) // "RIFF"
  setUint32(length - 8) // 파일 길이 - 8
  setUint32(0x45564157) // "WAVE"
  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // 길이 = 16
  setUint16(1) // PCM (압축되지 않음)
  setUint16(numOfChan)
  setUint32(buffer.sampleRate)
  setUint32(buffer.sampleRate * 2 * numOfChan) // 초당 바이트 수
  setUint16(numOfChan * 2) // 블록 정렬
  setUint16(16) // 16비트. 8비트로 하면 뚝뚝 잡음 생김
  setUint32(0x61746164) // "data" - chunk
  setUint32(length - pos - 4) // chunk 길이

  // 채널 데이터 작성
  for (let i = 0; i < buffer.numberOfChannels; i += 1) {
    channels.push(buffer.getChannelData(i))
  }

  while (pos < length) {
    for (let i = 0; i < numOfChan; i += 1) {
      // const sample = Math.max(-1, Math.min(1, channels[i]![offset]!)) // 클램프
      // sample 0해도됨. see: https://aipark.slack.com/archives/C03R215MQ69/p1727180571043519?thread_ts=1727074127.089179&cid=C03R215MQ69
      const sample = 0
      view.setInt16(pos, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true) // PCM 16비트로 변환
      pos += 2
    }
    // offset += 1
  }

  return bufferArray
}

export default async function createSilentAudioUrl(space: number) {
  if (space < 0) {
    return null
  }

  const sampleRate = 8000 // 샘플레이트를 낮춰 설정
  const context = new window.AudioContext()
  const buffer = context.createBuffer(1, sampleRate * space, sampleRate) // space초짜리 빈 소리
  // const source = context.createBufferSource()
  // source.buffer = buffer
  // source.connect(context.destination)

  const wavData = audioBufferToWav(buffer) // AudioBuffer를 WAV 파일로 변환
  const blob = new Blob([wavData], { type: 'audio/wav' }) // Blob 생성
  const url = URL.createObjectURL(blob) // Blob URL 생성
  return url
}

// export default async function createOrGetSilentAudioUrl(space: number) {
//   console.time('maketime')
//   if (space < 0) {
//     return null
//   }

//   if (cache[space]) {
//     return cache[space]
//   }

//   const context = new window.AudioContext()
//   const buffer = context.createBuffer(1, context.sampleRate * space, context.sampleRate) // space초짜리 빈 소리
//   // const source = context.createBufferSource()
//   // source.buffer = buffer
//   // source.connect(context.destination)

//   const wavData = audioBufferToWav(buffer) // AudioBuffer를 WAV 파일로 변환
//   const blob = new Blob([wavData], { type: 'audio/wav' }) // Blob 생성
//   console.log('blob size', blob.size)
//   const url = URL.createObjectURL(blob) // Blob URL 생성
//   cache[space] = url
//   console.timeEnd('maketime')
//   return url
// }
