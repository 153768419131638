import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import PageWithCenterBoxAndLogo from '../../components/PageWithCenterBoxAndLogo'
import AivatarClient from '../../services/AivatarClient'
import usePwInput from '../../hooks/usePwInput'
import ErrMsg from './ErrMsg'
import useURI from '../../hooks/useURI'

function Checkbox({ className, children, ...otherProps }) {
  return (
    <label htmlFor={otherProps.id} className={`flex w-full cursor-pointer select-none items-start ${className}`}>
      <input type="checkbox" className="mr-[7px] mt-[3px]" {...otherProps} />
      <span className="text-gray-21 whitespace-pre text-[12px]">{children}</span>
    </label>
  )
}

function SignUpConfirm() {
  const location = useLocation()
  const navigate = useNavigate()
  const { email, verification_code: verificationCode } = Object.fromEntries(new URLSearchParams(location.search))

  const [name, setName] = useState('')
  const [nameErrMsg, setNameErrMsg] = useState('')
  const [lastName, setLastName] = useState('')
  const pwInput = usePwInput()
  const [agreedTermsAndPrivacy, setAgreedTermsAndPrivacy] = useState(false)
  const [agreedMarketing, setAgreedMarketing] = useState(false)
  const [agreementErrMsg, setAgreementErrMsg] = useState('')
  const [isComplete, setIsComplete] = useState(false)
  const { t, i18n } = useTranslation('auth-signup')
  const { uri } = useURI()

  //! Legacy
  const [isRegistering, setRegistering] = useState(false)

  const checkName = () => {
    if (!name) {
      setNameErrMsg(t('필수 입력 사항입니다.'))
      return false
    }

    return true
  }

  const checkAgreements = () => {
    if (!agreedTermsAndPrivacy || !agreedMarketing) {
      setAgreementErrMsg(t('모든 필수 항목에 동의해주세요.'))
      return false
    }

    return true
  }

  const signUp = () => {
    setRegistering(true)
    AivatarClient()
      .post('/auth/verify', {
        email,
        name,
        lastName,
        password: pwInput.pw,
        adAgreed: agreedMarketing,
        verificationCode,
        language: i18n.language,
      })
      .then(() => setIsComplete(true))
      // eslint-disable-next-line no-alert
      .catch((error) => window.alert(error.message))
      .finally(() => setRegistering(false))
  }

  return (
    <PageWithCenterBoxAndLogo>
      {isComplete ? (
        <>
          <span className="title">{t('계정이 생성되었습니다!')}</span>
          <span className="subtitle">{t('지금 바로 로그인하세요.')}</span>
          <button
            className="btn-main btn-l mt-[50px]"
            onClick={() => {
              navigate('/login')
            }}
          >
            {t('로그인')}
          </button>
        </>
      ) : (
        <>
          <span className="title">{t('가입하기')}</span>
          <span className="subtitle">
            {t('이미 계정이 있으신가요?')}{' '}
            <Link className="link-main text-[16px]" to="/login">
              {t('로그인')}
            </Link>
          </span>
          <span className="text-main mt-[50px] w-full px-[10px] py-[12px] font-bold">{email}</span>
          <input
            className={`input mt-[10px] ${nameErrMsg ? 'input-error' : ''}`}
            placeholder={t('이름')}
            value={name}
            onChange={(e) => {
              setNameErrMsg('')
              setName(e.target.value)
            }}
          />
          {nameErrMsg && <ErrMsg>{nameErrMsg}</ErrMsg>}
          <input
            className="input mt-[10px]"
            placeholder={t('성')}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value)
            }}
          />
          <input className={`input mt-[10px] ${pwInput.isError ? 'input-error' : ''}`} {...pwInput.pwInputProps} />
          <input className={`input mt-[10px] ${pwInput.isError ? 'input-error' : ''}`} {...pwInput.pwReInputProps} />
          {pwInput.isError && <ErrMsg>{pwInput.errorMsg}</ErrMsg>}
          <Checkbox
            className="mt-[20px]"
            checked={agreedTermsAndPrivacy}
            onChange={(e) => {
              setAgreementErrMsg('')
              setAgreedTermsAndPrivacy(e.target.checked)
            }}
          >
            <Trans t={t}>
              (필수) AiVATAR{' '}
              <Link className="underline" to={uri['aivatar-privacy']} target="_blank">
                개인정보처리방침
              </Link>{' '}
              및{' '}
              <Link className="underline" to={uri['aivatar-term']} target="_blank">
                서비스 이용약관
              </Link>
              에 동의합니다.
            </Trans>
          </Checkbox>
          <Checkbox
            className="mt-[5px]"
            checked={agreedMarketing}
            onChange={(e) => {
              setAgreementErrMsg('')
              setAgreedMarketing(e.target.checked)
            }}
          >
            {t('(필수) AiVATAR 서비스와 관련한 제품, 서비스 홍보 등 광고성 이메일 수신에 동의합니다.')}
          </Checkbox>
          {agreementErrMsg && <ErrMsg>{agreementErrMsg}</ErrMsg>}
          <button
            type="button"
            disabled={isRegistering}
            className="btn-main btn-l mt-[50px] w-[150px] text-center"
            onClick={() => {
              if (checkName() && pwInput.verify() && checkAgreements()) {
                signUp()
              }
            }}
          >
            {isRegistering ? <CircularProgress color="inherit" /> : t('가입하기')}
          </button>
        </>
      )}
    </PageWithCenterBoxAndLogo>
  )
}

export default SignUpConfirm
