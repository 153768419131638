import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageWithCenterBoxAndLogo from '../../components/PageWithCenterBoxAndLogo'
import usePwInput from '../../hooks/usePwInput'
import { ReactComponent as IconWarning } from '../../assets/images/icon-warning.svg'
import AivatarClient from '../../services/AivatarClient'

function ChangePw() {
  const { t } = useTranslation('auth-findpw')
  const location = useLocation()
  const navigate = useNavigate()
  const { email, verification_code: verificationCode } = Object.fromEntries(new URLSearchParams(location.search))

  const pwInput = usePwInput()
  const [isComplete, setIsComplete] = useState(false)

  const changePw = () => {
    AivatarClient()
      .post('/auth/change-password', {
        email,
        newPassword: pwInput.pw,
        verificationCode,
      })
      .then(() => setIsComplete(true))
  }

  return (
    <PageWithCenterBoxAndLogo>
      {isComplete ? (
        <>
          <span className="title">{t('비밀번호가 재설정되었습니다.')}</span>
          <span className="subtitle mb-[20px]">{t('지금 바로 로그인하세요.')}</span>
          <button
            className="btn-main btn-l my-[50px]"
            onClick={() => {
              navigate('/login')
            }}
          >
            {t('로그인')}
          </button>
        </>
      ) : (
        <>
          <span className="title">{t('비밀번호 재설정하기')}</span>
          <span className="subtitle">{t('새로운 비밀번호를 입력해주세요.')}</span>
          <span className="text-main mt-[50px] w-full px-[10px] py-[12px] font-bold">{email}</span>
          <input className={`input mt-[10px] ${pwInput.isError ? 'input-error' : ''}`} {...pwInput.pwInputProps} />
          <input className={`input mt-[10px] ${pwInput.isError ? 'input-error' : ''}`} {...pwInput.pwReInputProps} />
          {pwInput.isError && (
            <span className="text-point-3 mt-[10px] flex w-full items-center whitespace-pre">
              <IconWarning className="mr-[5px]" />
              {pwInput.errorMsg}
            </span>
          )}
          <button
            className={`btn-l my-[20px] font-medium ${
              pwInput.isError ? 'btn-gray-5 text-[#9F9F9F] hover:bg-[#F1F1F1] hover:text-[#9F9F9F]' : 'btn-main'
            }`}
            onClick={() => {
              if (pwInput.verify()) {
                changePw()
              }
            }}
          >
            {t('변경하기')}
          </button>
        </>
      )}
    </PageWithCenterBoxAndLogo>
  )
}

export default ChangePw
