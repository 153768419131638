import { useRecoilValue } from 'recoil'
import {
  isCallSuccessAudioState,
  isLoadingAudioState,
  sentenceBoxAudioValueState,
} from '../../recoil/texteditor/sentenceBox/selectors'

import AivatarClient, { API_TYPE } from '../AivatarClient'

export default function useListenAudio({ id }) {
  /** Recoils */
  const audioId = useRecoilValue(sentenceBoxAudioValueState({ id, key: 'audioId' }))

  const isCallSuccess = useRecoilValue(isCallSuccessAudioState({ id }))
  const isLoading = useRecoilValue(isLoadingAudioState({ id }))

  // eslint-disable-next-line consistent-return
  const fetch = async () => {
    try {
      const res = await AivatarClient({
        type: API_TYPE.BLOB,
      }).get(`audios/${audioId}/listen`)
      const { data, status } = res
      if (status !== 200) throw new Error('useListenAudio - Invalid Response Status')
      return data
    } catch (error) {
      /* empty */
    }
  }

  return {
    fetchListenAudio: fetch,
    isLoading,
    isCallSuccess,
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchListenAudioForBatchCreation = async ({ accessToken, audioId }) => {
  try {
    const res = await AivatarClient({
      type: API_TYPE.BLOB,
    }).get(`audios/${audioId}/listen`)
    const { data, status } = res
    if (status !== 200) throw new Error('fetchListenAudioForBatchCreation - Invalid Response Status')
    return URL.createObjectURL(data)
  } catch (error) {
    console.error(error.message)
    throw new Error(error)
  }
}
