import { useCallback, useEffect, useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import { projectValueState } from '../../../../../recoil/project/selectors'
import { getDecimalPlaces } from '../../../../../utils/number'

const useFirstIntervalValueInput = () => {
  /** Recoilds */
  const [firstInterval, setFirstInterval] = useRecoilState(projectValueState({ key: 'firstInterval' }))

  const [floatValue, setFloatValue] = useState(firstInterval)
  const { t } = useTranslation('project')

  const getCorrectValue = (value) => Math.round(value * 10) / 10

  /** Event Handlers */
  const changeValue = useCallback((value) => {
    if (value === undefined) {
      setFloatValue(0)
      return
    }

    const correctValue = getCorrectValue(value)
    setFloatValue(correctValue)
  }, [])

  useEffect(() => {
    changeValue(firstInterval)
  }, [changeValue, firstInterval])

  const isValidFirstInterval = useMemo(() => {
    const targetValue = floatValue

    if (targetValue < 0 || targetValue > 10) {
      return false
    }

    const decimalPlaces = getDecimalPlaces(targetValue)

    if (decimalPlaces >= 2) {
      return false
    }

    return true
  }, [floatValue])

  useAivatarDidMount(() => {
    if (!isValidFirstInterval) return
    setFirstInterval(floatValue)
  }, [floatValue, isValidFirstInterval])

  /** Displays */

  const component = useMemo(
    () => (
      <div className="flex items-center justify-center">
        <div className={`flex text-center ${isValidFirstInterval ? '' : 'border-[1.5px] border-red-500'} rounded-md`}>
          <NumericFormat
            name="optionValue"
            className="w-[23px] text-center text-[14px] outline-none"
            thousandSeparator={false}
            decimalScale={1}
            allowNegative={false}
            valueIsNumericString
            value={floatValue}
            onValueChange={(values, sourceInfo) => {
              if (sourceInfo.source === 'event') {
                changeValue(values.floatValue)
              }
            }}
          />
        </div>
        <p className="mr-[8px] font-light">
          <p>{t('초')}</p>
        </p>
      </div>
    ),
    [changeValue, floatValue, isValidFirstInterval, t],
  )

  return {
    firstInterval,
    component,
    isValidFirstInterval,
    changeValue,
  }
}

export default useFirstIntervalValueInput
