import { useTranslation } from 'react-i18next'
import { PlanName } from '../../@types/common'

type Props = {
  planName: PlanName
}

export default function PlanFeatures({ planName }: Props) {
  const { t } = useTranslation('plan-info-checkout')

  const allowedPlanList = ['BASIC', 'PRO'] satisfies PlanName[]
  type AllowedPlan = (typeof allowedPlanList)[number]

  const features: Record<Extract<PlanName, AllowedPlan>, string[]> = {
    BASIC: [
      t('매달 {{credits, number}} 크레딧', { credits: 12000 }),
      t('AI 아바타 {{avatar_count}}종', { avatar_count: 5 }),
      t('아바타별 제스처 {{gesture_count}}종', { gesture_count: 1 }),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
    PRO: [
      t('매달 {{credits, number}} 크레딧', { credits: 60000 }),
      t('AI 아바타 {{avatar_count}}종 이상', { avatar_count: 71 }),
      t('아바타별 모든 제스처'),
      t('{{language_count}}개 언어 및 목소리', { language_count: 140 }),
      t('워터마크 제거'),
      t('무제한 다운로드'),
    ],
  }

  // TODO 거부된 플랜도 자동화 할 수는 없을까?? 이정도로도 어느정도 정적분석은 가능할듯 하다. 여기서 실수 있다면 렌더링 부분에서 에러 날테니까
  if (planName !== 'BASIC' && planName !== 'PRO') return null

  return (
    <ul className="list-disc pl-[20px]">
      {features[planName].map((feature, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={idx} className="text-[14px] text-[#5e5e5e]">
          {feature}
        </li>
      ))}
    </ul>
  )
}
