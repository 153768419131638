import { ReactComponent as IconWarning } from '../../assets/images/icon-warning.svg'

function ErrMsg({ children }) {
  return (
    <span className="text-point-3 mt-[10px] flex w-full items-center whitespace-pre">
      <IconWarning className="mr-[5px]" />
      {children}
    </span>
  )
}

export default ErrMsg
