import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/images/aivatar-logo.png'
import Profile from '../Profile'
import ProfileImg from '../Profile/ProfileImg'
import DropdownMenu, { DropdownMenuList, DropdownMenuListItemA } from '../DropdownMenu'
import { ReactComponent as ConfigIcon } from '../../assets/images/menu-config.svg'
import { ReactComponent as LogoutIcon } from '../../assets/images/menu-logout.svg'
import useURI from '../../hooks/useURI'
import useAuth from '../../hooks/useAuth'

function DashboardHeader() {
  const { t } = useTranslation('dashboard-header')
  const location = useLocation()
  const { workspaceId } = useParams()
  const { user, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const { uri } = useURI()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <div className="border-main-3 flex h-[80px] shrink-0 items-center border-b bg-white px-[50px]">
      <Link to={`/workspace/${workspaceId}`}>
        <img className="w-[120px]" src={Logo} alt="logo" />
      </Link>
      <div className="relative ml-auto">
        <button type="button" onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)} aria-label={t('내 프로필')}>
          <ProfileImg />
        </button>
        {isOpen && (
          <DropdownMenu className="right-0 top-[50px] w-[309px]" onOutsideClick={() => setIsOpen(false)}>
            <DropdownMenuList>
              <Profile className="px-[15px] py-[10px]" user={user} />
            </DropdownMenuList>
            <DropdownMenuList>
              <DropdownMenuListItemA
                label={t('프로필 설정')}
                Icon={ConfigIcon}
                to={`/workspace/${workspaceId}/profile`}
              />
              <DropdownMenuListItemA label={t('로그아웃')} Icon={LogoutIcon} to="/login" onClick={() => logout({})} />
              <div className="mt-[10px] flex items-center justify-between px-[15px]">
                <Link className="text-gray-4 text-[12px]" to={uri['aivatar-privacy']} target="_blank">
                  {t('개인정보처리방침')}
                </Link>
                <Link className="text-gray-4 text-[12px]" to={uri['aivatar-term']} target="_blank">
                  {t('서비스 이용약관')}
                </Link>
              </div>
            </DropdownMenuList>
          </DropdownMenu>
        )}
      </div>
    </div>
  )
}

export default DashboardHeader
