import { Backdrop } from '@mui/material'
import IconLoading from './Loading/IconLoading'

function AivatarBackdrop({
  isFullScreen = true,
  isLoading = false,
  message = '',
  loadingSize = '50px',
  open = true,
  backgroundColor = '#11111125',
  zIndex = 2000,
  loadingIcon,
  onClick = () => {},
}) {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex,
        position: isFullScreen ? 'fixed' : 'absolute',
        backgroundColor,
      }}
      open={open}
      onClick={onClick}
    >
      <div className="relative flex flex-col items-center">
        {isLoading ? loadingIcon || <IconLoading size={loadingSize} /> : null}
        {message && <p className="mt-[100px] text-[24px] font-bold">{message}</p>}
      </div>
    </Backdrop>
  )
}

export default AivatarBackdrop
