import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default function useDisplayVoiceTag() {
  const { t } = useTranslation('voice-tag-name')

  // See: https://www.notion.so/aipark/Voice-Tag-0d5fa52d57444ef5b7dc84675d15a577?pvs=4
  const voiceTagI18nMap = useMemo(
    () =>
      new Map([
        ['신규', t('신규')],
        ['맞춤형', t('맞춤형')],
        ['여성', t('여성')],
        ['남성', t('남성')],
        ['중성', t('중성')],
        ['어린이', t('어린이')],
        ['청소년', t('청소년')],
        ['성인', t('성인')],
        ['중년', t('중년')],
        ['노년', t('노년')],
        ['차분한', t('차분한')],
        ['친근한', t('친근한')],
        ['전문적인', t('전문적인')],
        ['엄숙한', t('엄숙한')],
        ['경쾌한', t('경쾌한')],
        ['격의없는', t('격의없는')],
        ['힘있는', t('힘있는')],
        ['행복한', t('행복한')],
        ['슬픈', t('슬픈')],
        ['화난', t('화난')],
        ['놀라운', t('놀라운')],
        ['두려운', t('두려운')],
        ['사랑스러운', t('사랑스러운')],
        ['냉정한', t('냉정한')],
        ['교육', t('교육')],
        ['마케팅', t('마케팅')],
        ['내레이션', t('내레이션')],
        ['고객지원', t('고객지원')],
        ['안내', t('안내')],
        ['뉴스', t('뉴스')],
        ['교양', t('교양')],
        ['발표', t('발표')],
        ['쇼/오락', t('쇼/오락')],
      ]),
    [t],
  )

  const displayVoiceTag = useCallback(
    (tagNameKo: string) => {
      const str = voiceTagI18nMap.get(tagNameKo) ?? tagNameKo

      return str
    },
    [voiceTagI18nMap],
  )

  return {
    displayVoiceTag,
  }
}
