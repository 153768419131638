import { useState } from 'react'
import Confirm from './Confirm'

export default function Prompt({ initialValue = '', placeholder, onClickConfirm, ...otherProps }) {
  const [value, setValue] = useState(initialValue)

  return (
    <Confirm
      onClickConfirm={() => {
        onClickConfirm?.(value)
      }}
      {...otherProps}
    >
      <input
        className="input border-gray-4 placeholder:text-gray-4 mt-[20px] h-[34px] w-[255px] px-[15px] text-[12px]"
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
    </Confirm>
  )
}
