import { NumericFormat } from 'react-number-format'
import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../Dialog'
import useDialog from '../../hooks/useDialog'
import popupImage from '../../assets/images/popup-subscription.png'
import SubscriptionPlanDialog from '../SubscriptionPlan/SubscriptionPlanDialog'
import { ReactComponent as IconClose } from '../../assets/images/icon-close.svg'
import { LocalStorage, SessionStorage } from '../../utils/storage'

const ADDITIONAL_CREDITS = 10000

export default function SubscriptionPopup() {
  const { t } = useTranslation('popup-subscription')

  const dialog = useDialog()

  const doNotShowAgain = () => {
    LocalStorage.addNotShownPopupList('SUBSCRIPTION')
    dialog.close()
  }

  const close = () => {
    SessionStorage.addClosedPopupList('SUBSCRIPTION')
    dialog.close()
  }

  return (
    <Dialog
      classes={{
        paper: '!bg-main-3 !px-[50px] !pt-[0]',
      }}
    >
      <aside>
        <header className="h-[70px]">
          <button
            type="button"
            className="typo-body1 text-main-2 absolute left-[24px] top-[14px] underline"
            onClick={doNotShowAgain}
          >
            {t('다시보지 않기')}
          </button>
          <button
            type="button"
            className="absolute right-[20px] top-[14px]"
            onClick={close}
            aria-label={t('닫기', { ns: 'modal' })}
          >
            <IconClose />
          </button>
        </header>
        <div className="flex items-center justify-center gap-7">
          <div>
            <img src={popupImage} width={240} height={240} alt="남녀가 거대한 카드를 들고있는 모습" />
          </div>
          <div>
            <p className="typo-head1 text-point-1 whitespace-pre">
              <Trans t={t}>
                지금 카드 결제로 <span className="text-point-5">구독</span>하고
                <br /> <span className="text-point-5">추가 혜택</span>을 받아 가세요!
              </Trans>
            </p>
            <ul className="typo-body2 text-main-2 mt-6 list-disc pl-9">
              <li>
                Pro:{' '}
                <Trans t={t}>
                  <NumericFormat value={ADDITIONAL_CREDITS} thousandSeparator displayType="text" /> 크레딧 추가 제공
                </Trans>
              </li>
              <li>Basic: {t('제스처 추가 제공')}</li>
            </ul>
          </div>
        </div>
        <button
          type="button"
          className="btn-main typo-head2 mb-[12px] mt-[30px] h-[48px] w-[600px] rounded-[14px]"
          onClick={() => dialog.open(<SubscriptionPlanDialog expanded />)}
        >
          {t('플랜 바로가기')}
        </button>
      </aside>
    </Dialog>
  )
}
