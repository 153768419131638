import { createQueryKeys } from '@lukemorales/query-key-factory'
import AivatarClient from '../AivatarClient'
import { PaginationParams } from '../types'
import { getProjectList } from '../project'
import { getVideoExportList } from '../video'
import { getAudioExportList } from '../audio'

export async function getWorkspaceDetail(workspaceId: number) {
  const response = await AivatarClient().get(`/workspaces/${workspaceId}`)
  return response.data
}

export async function getWorkspaceCredits(workspaceId: number) {
  const response = await AivatarClient().get(`/workspaces/${workspaceId}/credits`)

  return response.data as {
    current: number
    predict: number
    available: number
    offer: number
    used: number
  }
}

export async function getWorkspaceInvoiceList(workspaceId: number, params: PaginationParams) {
  const { page, size, sort } = params
  const response = await AivatarClient().get(`/workspaces/${workspaceId}/invoices`, {
    params: {
      page,
      size,
      sort,
    },
  })
  return response.data
}

export const workspaceQueries = createQueryKeys('workspace', {
  detail: (workspaceId: number) => ({
    queryKey: [workspaceId],
    queryFn: () => getWorkspaceDetail(workspaceId),
    contextQueries: {
      invoices: (params: Parameters<typeof getWorkspaceInvoiceList>[1]) => ({
        queryKey: [params],
        queryFn: () => getWorkspaceInvoiceList(workspaceId, params),
      }),
      credits: {
        queryKey: null,
        queryFn: () => getWorkspaceCredits(workspaceId),
      },
      projects: (params: PaginationParams) => ({
        queryKey: [params],
        queryFn: () => getProjectList(workspaceId, params),
      }),
      videoExports: (params: PaginationParams) => ({
        queryKey: [{ workspaceId, ...params }],
        queryFn: () => getVideoExportList(workspaceId, params),
      }),
      audioExports: (params: PaginationParams) => ({
        queryKey: [{ workspaceId, ...params }],
        queryFn: () => getAudioExportList(workspaceId, params),
      }),
    },
  }),
})
