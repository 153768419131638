import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import navigatorIndexState from '../../../recoil/navigator/atoms'
import VoiceActiveIcon from '../../../assets/images/navigator-voice.png'
import VoiceInActiveIcon from '../../../assets/images/navigator-voice-inactive.png'
import VoiceHoverIcon from '../../../assets/images/navigator-voice-hover.png'

import VideoActiveIcon from '../../../assets/images/navigator-video.png'
import VideoInActiveIcon from '../../../assets/images/navigator-video-inactive.png'
import VideoHoverIcon from '../../../assets/images/navigator-video-hover.png'
import { ReactComponent as HelpIcon } from '../../../assets/images/global_icon-help.svg'
import useURI from '../../../hooks/useURI'
import NavigationItem from './NavigationItem'

function Navigator() {
  const [selectedNaviIndex] = useRecoilState(navigatorIndexState)
  const { t } = useTranslation('project')
  const { uri } = useURI()
  const PROJECT_NAVIGATION = useMemo(
    () => [
      {
        text: t('음성'),
        icon: VoiceActiveIcon,
        inactiveIcon: VoiceInActiveIcon,
        hoverIcon: VoiceHoverIcon,
      },
      {
        text: t('영상'),
        icon: VideoActiveIcon,
        inactiveIcon: VideoInActiveIcon,
        hoverIcon: VideoHoverIcon,
      },
    ],
    [t],
  )

  const onClickHelp = () => {
    window.open(uri['help-center'])
  }

  return (
    <div className="navigator_width relative flex flex-col items-center justify-between bg-[#3A74F5]">
      <button type="button" className="absolute bottom-[50px]" onClick={onClickHelp} aria-label={t('유저 가이드')}>
        <HelpIcon />
      </button>
      <div className="flex w-full flex-col">
        {PROJECT_NAVIGATION.map((item, index) => (
          <NavigationItem item={item} key={`NavigationItem-${item.text}`} index={index} />
        ))}
        <div className="navigator_item_height ml-[5px] w-[115px-5px] bg-white">
          <div
            className={`flex h-full w-full flex-col items-center justify-center bg-[#3A74F5] text-[#3A74F5] ${
              selectedNaviIndex === 1 && 'rounded-tr-[30px]'
            }`}
          />
        </div>
      </div>

      {/* <AivatarMotionButton>도움말</AivatarMotionButton> */}
    </div>
  )
}

export default Navigator
