import { Suspense, useEffect, useMemo } from 'react'
import Spinner from 'react-spinkit'

import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import AvatarEditor from './AvatarEditor'
import AudioController from './AudioController'
import TextEditor from './TextEditor'
import useVoiceList from '../../services/workspace/useVoiceList'
import useAvatarList from '../../services/workspace/useAvatarList'
import useProjectData from '../../services/project/useProjectData'
import { projectValueState } from '../../recoil/project/selectors'
import AivatarBackdrop from '../../components/AivatarBackdrop'
import { activeAvatarsState, isCallSuccessWorkspaceAvatarState } from '../../recoil/workspace/selectors'
import { projectState } from '../../recoil/project/atoms'

function Project() {
  // const preferredLanguage = usePreferredLanguage();
  // const l = useLanguages(usePreferredLanguage());
  const { t } = useTranslation('project')
  const { projectId, workspaceId } = useParams()

  const loadingMessage = useRecoilValue(projectValueState({ key: 'loadingMessage' }))
  const isAudioLoading = useRecoilValue(projectValueState({ key: 'isAudioLoading' }))
  const activeAvatars = useRecoilValue(activeAvatarsState)
  const isCallSuccessWorkspaceAvatar = useRecoilValue(isCallSuccessWorkspaceAvatarState)
  const [project, setProject] = useRecoilState(projectState)

  const { isLoading: isLoadingProject } = useProjectData({
    projectId,
    workspaceId,
  })
  const { isLoading: isLoadingVoiceList } = useVoiceList({ workspaceId })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: isLoadingAvatarList } = useAvatarList({ workspaceId })

  // useAivatarDidMount(() => {
  //   if (user.id) return
  //   // fetchLogin("test1@aipark.ai", "qwer1234");
  // }, [user])

  const projectLoadingMessage = useMemo(() => {
    if (loadingMessage.length === 0) return t('프로젝트 업데이트 중')
    return loadingMessage
  }, [loadingMessage, t])

  // 아바타 선택했었는데 지금은 활성화돼있지 않으면, 선택해제한다.
  useEffect(() => {
    if (isCallSuccessWorkspaceAvatar && project.avatarId) {
      if (!activeAvatars.find((avatar) => avatar.id === project.avatarId)) {
        setProject((prev) => ({
          ...prev,
          avatarId: null,
          dressId: null,
        }))
      }
    }
  }, [activeAvatars, isCallSuccessWorkspaceAvatar, project.avatarId, setProject])

  // 아바타가 선택되어있지 않다면? 활성화된 첫번째 아바타, 그 아바타의 첫번째 드레스를 임의로 선택해준다.
  // 바로 위쪽의 Effect와 연계되어 동작될 수도 있을것이다.
  useEffect(() => {
    if (!project.avatarId && activeAvatars.length !== 0) {
      const firstActiveAvatar = activeAvatars[0]
      setProject((prev) => ({
        ...prev,
        avatarId: firstActiveAvatar.id,
        dressId: firstActiveAvatar.detail[0].seqId,
      }))
    }
  }, [activeAvatars, project, setProject])

  return (
    <div className="project h-full w-full">
      {(isLoadingProject || isLoadingVoiceList) && (
        <AivatarBackdrop
          message={projectLoadingMessage}
          isLoading
          loadingIcon={
            isAudioLoading && (
              <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                {/* <LoadingIcon className="w-[50px] h-[50px]" /> */}
                <Spinner name="line-scale-pulse-out" color="var(--color-main-1)" fadeIn="none" />
              </div>
            )
          }
        />
      )}
      {/* {isLoadingLogin && <AivatarBackdrop message={t('로그인 중')} isLoading />} */}

      <div className="grid h-full grid-cols-1 grid-rows-[1fr_auto] justify-between gap-[25px] px-[5%] pb-[25px] pt-[50px]">
        <div className="flex h-full justify-between overflow-y-hidden">
          {/* //! suspense 한 이유: 문장박스 하나도 없을때 placeholder 박스가 삽입되는 로직이 있는데 그 때 i18n 로딩을 확실시해야 에러없이 안정화됨 */}
          <Suspense fallback={null}>
            <TextEditor />
          </Suspense>
          <AvatarEditor />
        </div>

        <AudioController />
      </div>
    </div>
  )
}

export default Project
