import i18n, { InitOptions } from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const ns = [
  'common',
  'uri',
  'locale',
  'period',
  'currency',
  'modal',
  'table',
  'dashboard',
  'dashboard-header',
  'dashboard-recent-project',
  'invoice',
  'subscription',
  'workspace',
  'popup-subscription',
  'project',
  'project-list',
  'project-export',
  'project-option',
  'project-message',
  'export-list',
  'auth-login',
  'auth-signup',
  'auth-input',
  'auth-findpw',
  'user-update',
  'user-update-prompt',
  'plan-info-modal',
  'plan-info-checkout',
  'plan-checkout',
  'plan-changed',
  'voice-tag-name',
  'notification-bar',
] as const

// See: https://www.i18next.com/overview/configuration-options
const initOptions: InitOptions<HttpBackendOptions> = {
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  // lng: 'en', // if you're using a language detector, do not define the lng option
  fallbackLng: 'ko',
  supportedLngs: ['ko', 'en'],

  // debug: process.env.NODE_ENV === 'development',

  ns,
  defaultNS: 'common',

  keySeparator: false,
  nsSeparator: false,
  returnEmptyString: false, // https://www.i18next.com/principles/fallback#missing-values-for-existing-keys

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },

  // plugin
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },

  detection: {
    // 아래는 디폴트 옵션을 복사 + 붙여넣기한 것입니다. See: https://www.npmjs.com/package/i18next-browser-languagedetector

    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expiry and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' },

    // optional conversion function used to modify the detected language code
    convertDetectedLanguage: 'Iso15897',
    // convertDetectedLanguage: (lng) => lng.replace('-', '_'),
  },

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: [], // default is ['br', 'strong', 'i'], // TODO 이 설정 i18next-parser 와 동기화 해야 디폴트 받아낼 수 있다.
    useSuspense: true, // default is true
  },
}

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(initOptions)

export default i18n
