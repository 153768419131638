import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChipCompleteIcon } from '../../../../assets/images/chip-complete.svg'

export default function ChipComplete() {
  const { t } = useTranslation('export-list')

  return (
    <div className="chip-green mx-auto">
      <span>{t('완료')}</span>
      <ChipCompleteIcon />
    </div>
  )
}
