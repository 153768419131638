import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/arrow-right-circle-b.svg'
import { customDayjs } from '../../../utils/date'

export default function RecentProjectListItem({ project, highlight }) {
  const { t, i18n } = useTranslation('dashboard-recent-project')
  const { contents } = project
  const { workspaceId } = useParams()
  dayjs.locale(i18n.language)

  const description = useMemo(() => {
    if (!Array.isArray(contents)) return ''
    if (contents.length <= 0) return ''
    return contents[0]
  }, [contents])

  const time = useMemo(() => {
    const updatedAt = customDayjs(project.updatedAt)
    const now = customDayjs()
    if (now.diff(updatedAt) <= 60000) {
      return t('방금 전')
    }
    return updatedAt.fromNow()
  }, [project.updatedAt, t])

  return (
    <li key={project.id}>
      <a
        href={`/workspace/${workspaceId}/project/${project.id}`}
        className={`box bg-gray-6 block rounded-[30px] p-[30px] shadow ${
          highlight
            ? 'bg-main [&>div>span]:text-[rgba(255,255,255,0.6)] [&>div>svg>path]:fill-white [&>p]:text-white [&>span]:text-white'
            : ''
        }`}
      >
        <span className="text-gray-2 text-[17px] font-bold">{project.title}</span>
        <p className="text-gray-21 mt-[26px] line-clamp-2 h-[48px]">{description}</p>
        <div className="mt-[38px] flex items-end">
          <span className="text-gray-3 text-[12px]">
            {t('마지막 저장')} {time}
          </span>
          <ArrowRightIcon className="[&>path]:fill-main ml-auto" />
        </div>
      </a>
    </li>
  )
}
