import { useState, useMemo, useCallback } from 'react'
import { Slider } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useDebounce } from 'react-use'
import { useParams } from 'react-router-dom'
import { editorValueState, focusedBoxValueState } from '../../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY, SentenceBoxValuesForFetch } from '../../../../../recoil/texteditor/sentenceBox/atoms'
import useEditorCallbacks from '../../../../../recoil/texteditor/editor/useEditorCallbacks'
import useUpdateAudio from '../../../../../services/audio/useUpdateAudio'
import useDidMount from '../../../../../hooks/useDidMount'
import useAudioControllerCallbacks from '../../../../../recoil/audiocontroller/useAudioControllerCallbacks'

const useOptionValueSlider = (optionKey) => {
  /** Recoilds */

  const didMount = useDidMount()
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const setPlayed = useSetRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const { fetchAudioUpdate } = useUpdateAudio()

  const optionValue = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )

  const { setOptionValues } = useEditorCallbacks()
  const audioId = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'audioId',
    }),
  )

  const [value, setValue] = useState(-1)

  const { getAudioUpdateBody } = useAudioControllerCallbacks()
  const { projectId } = useParams()
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  // TODO 이거 상위로 빼고, 옵션 선택하는 코드(다중셀렉포함)랑 같이 커플링되야됨
  useDebounce(
    () => {
      // TODO 다중옵션 적용시 [focusedBoxId] -> selectedBoxIds로 변경
      const body = getAudioUpdateBody({ sentenceBoxIds: [focusedBoxId], projectId })
      if (!didMount) return
      if (!audioId) return
      if (value === -1) return
      fetchAudioUpdate({ sentenceBoxIds: [focusedBoxId] })
      setSentenceBoxValues({ ...sentenceBoxValues, ...body.attributes[0] })
      setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
    },
    500,
    [value],
  )

  const onChangeOptionValue = useCallback(
    (e) => {
      const newOptionValue = e.target.value
      setValue(newOptionValue)
      setOptionValues({ optionKey, optionValue: newOptionValue })
    },
    [optionKey, setOptionValues],
  )

  const isInterval = useMemo(() => optionKey === 'space', [optionKey])

  const component = useMemo(
    () => (
      <div className="flex w-full flex-col">
        {/* <LinearProgress variant="buffer" value={50} valueBuffer={60} /> */}
        <Slider
          value={optionValue}
          onChange={onChangeOptionValue}
          min={isInterval ? 0.1 : 0.5}
          max={isInterval ? 99.9 : 1.5}
          aria-label="Always visible"
          size="small"
          step={0.1}
          valueLabelDisplay="auto"
          sx={{
            // color: "rgba(0,0,0,0.87)",
            height: 5,

            '& .MuiSlider-thumb': {
              width: 8,
              height: 8,
              // transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              backgroundColor: '#3A74F5',
              boxShadow: '0px 0px 0px 3px rgba(0, 73, 255, 0.25)',

              // "&:before": {
              //   boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)"
              // },
              // "&:hover, &.Mui-focusVisible": {
              //   boxShadow: "0px 0px 0px 4px rgb(0 0 0 / 16%)"
              // },
              '&.Mui-active': {
                width: 12,
                height: 12,
              },
            },
            '& .MuiSlider-rail': {
              opacity: 0.28,
              color: '#3A74F5 !important',
            },
            '& .MuiSlider-track': {
              // opacity: 0.28,
              color: '#3A74F5 !important',
              backgroundColor: '#3A74F5',
            },
            '& .MuiSlider-valueLabel': {
              lineHeight: 1.2,
              fontSize: 12,
              background: 'unset',
              padding: 0,
              width: 32,
              height: 24,
              backgroundColor: '#3A74F5',
              borderRadius: '10px',
            },
          }}
        />
      </div>
    ),
    [isInterval, onChangeOptionValue, optionValue],
  )

  return {
    optionValue,
    component,
  }
}

export default useOptionValueSlider
