import { editorAudioState } from '../texteditor/editor/atoms'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { sentenceBoxAudioState, sentenceBoxState, AUDIO_CRATE_STATUS } from '../texteditor/sentenceBox/atoms'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { checkIsValidSentenceBoxLength } from '../texteditor/sentenceBox/sentenceBoxHelpers'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CALL_STATE } from '../../services/constants'

/**
 * 실제 ReactPlayer의 값을 세팅하는 함수로, 만약 Slider에서 변화가 생겼다면 이 함수 또한 호출되어 실제 플레이어에 적용되어야 합니다.
 *
 * @typedef {Object} Props
 * @property {number} id - 오디오박스 아이디
 * @property {number} value - player에 들어갈 값으로, 초 단위의 값이 들어가야됨
 *
 * @param {Props}
 */
export const setSentenceBoxAudioManager = ({ id, value = 0 }) => {
  const playerElement = document.getElementById(`Player-${id}`)
  if (!playerElement) return

  const player = playerElement.childNodes[0]
  if (!player) {
    console.warn('Player is empty')
    return
  }

  //! 여윳값: 0.01 -> 여윳값 없으면 재생버그 생김
  player.currentTime = value > player.duration - 0.01 ? player.duration - 0.01 : value
}

export const resetSentenceBoxAudio = ({ id, set }) => {
  setSentenceBoxAudioManager({ id })
  set(sentenceBoxAudioState(id), (prev) => ({
    ...prev,
    played: 0,
    playing: false,
  }))
}

export const setIntervalAudioManager = ({ id, value = 0 }) => {
  const intervalPlayerElement = document.getElementById(`IntervalPlayer-${id}`)
  if (!intervalPlayerElement) return

  const player = intervalPlayerElement.childNodes[0]
  if (!player) {
    console.warn('IntervalPlayer is empty')
    return
  }
  player.currentTime = value
}

export const resetIntervalAudio = ({ id, set }) => {
  setIntervalAudioManager({ id })
  set(sentenceBoxAudioState(id), (prev) => ({
    ...prev,
    intervalPlayed: 0,
    intervalPlaying: false,
  }))
}

export const setFirstIntervalAudioManager = ({ value = 0 }) => {
  const intervalPlayerElement = document.getElementById('FirstIntervalPlayer')
  if (!intervalPlayerElement) return

  const player = intervalPlayerElement.childNodes[0]
  if (!player) {
    console.warn('FirstIntervalPlayer is empty')
    return
  }
  player.currentTime = value
}

export const resetFirstIntervalAudio = ({ set }) => {
  setFirstIntervalAudioManager({})
  set(editorAudioState, (prev) => ({
    ...prev,
    played: 0,
    playing: false,
  }))
}

// export const isValidPlayHelper = ({ get, id }) => {
//   const sentenceBox = get(sentenceBoxState(id))
//   const isValidSentenceBoxLength = checkIsValidSentenceBoxLength(sentenceBox.text)
//   if (!isValidSentenceBoxLength) return

//   const sentenceBoxAudio = get(sentenceBoxAudioState(id))
//   const isCompletedAudioStatus = sentenceBoxAudio.status === AUDIO_CRATE_STATUS.COMPLETED

//   if (isCompletedAudioStatus) {
//     return true
//   }
//   const isCallSuccessAudio = sentenceBoxAudio.apiStatus === CALL_STATE.SUCCESS
//   return isCallSuccessAudio
// }

// export const isLastFocusedBoxInSelectedBoxesHelper = ({ get }) => {
//   const currentFocusedBoxIndex = get(focusedBoxIndexAmongSelectedBoxesState);
//   if (currentFocusedBoxIndex === null) return false;

//   const selectedBoxes = get(selectedBoxesState);

//   return selectedBoxes.length - 1 === currentFocusedBoxIndex;
// }
