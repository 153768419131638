import { useMemo } from 'react'
import { motion } from 'framer-motion'
import { useMeasure } from 'react-use'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { dressListBySelectedCharacterState } from '../../../../../../recoil/workspace/selectors'
import ItemWrapper from './ItemWrapper'
import { projectValueState } from '../../../../../../recoil/project/selectors'

function DressSelectContent({ isOpen }) {
  const { t } = useTranslation('project-option')
  const [ref, { width }] = useMeasure()

  /** Recoils */
  const dressListBySelectedCharacter = useRecoilValue(dressListBySelectedCharacterState)

  const groupBy = (data, key) =>
    data.reduce((carry, el) => {
      const tmpCarry = { ...carry }

      const group = el[key]

      if (tmpCarry[group] === undefined) {
        tmpCarry[group] = []
      }

      tmpCarry[group].push(el)
      return tmpCarry
    }, {})

  const dressListByGroup = useMemo(() => {
    if (!dressListBySelectedCharacter) return []
    const dressListBySelectedCharacterWithOriginIndex = dressListBySelectedCharacter.map((dress, index) => ({
      ...dress,
      originIndex: index,
    }))
    return groupBy(dressListBySelectedCharacterWithOriginIndex, 'groupName')
  }, [dressListBySelectedCharacter])

  const setSelectedDressId = useSetRecoilState(projectValueState({ key: 'dressId' }))

  /** Event */
  const onClickItem = (dressId) => {
    setSelectedDressId(dressId)
  }

  /** Displays */

  const itemWidth = useMemo(() => (width - 18) / 2, [width])

  const itemHeight = useMemo(() => itemWidth * 1.54, [itemWidth])

  const wrapperHeight = useMemo(() => itemHeight * 2 + 6 + 20, [itemHeight])

  return (
    <motion.div
      className="flex w-full"
      animate={{
        height: isOpen ? wrapperHeight : 0,
        marginTop: isOpen ? '16px' : '0px',
        opacity: isOpen ? 1 : 0,
      }}
    >
      <div
        ref={ref}
        className={`aivatar_scroll_wrapper flex w-full flex-col overflow-y-auto ${!isOpen && 'pointer-events-none'}`}
      >
        {Object.keys(dressListByGroup).map((groupKey, index) => {
          const dresses = dressListByGroup[groupKey]

          return (
            <ItemWrapper
              key={`ItemWrapper-${index + 1}`}
              groupKey={`${t('타입')}${groupKey.replace('의상', '')}`}
              dresses={dresses}
              itemWidth={itemWidth}
              itemHeight={itemHeight}
              onClickItem={onClickItem}
            />
          )
        })}
      </div>
    </motion.div>
  )
}

export default DressSelectContent
