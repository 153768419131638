import { atom } from 'recoil'
import { CALL_STATE } from '../../services/constants'

export const projectState = atom({
  key: 'projectState',
  default: {
    id: null,
    title: '',
    str: null,
    firstInterval: 0,
    avatarId: null,
    dressId: null,
    backgroundColor: '008000',

    apiState: CALL_STATE.NEW,
    loadingMessage: '',
    isAudioLoading: false,
  },
})

export const dummy = null
