import { createQueryKeys } from '@lukemorales/query-key-factory'
import AivatarClient from '../AivatarClient'

export async function getPaymentsCheckoutDetails(reqBody: { planId: string; workspaceId: string }) {
  const response = await AivatarClient().post(`/payments/checkout`, reqBody)

  return response.data
}

export async function getPaymentMethodList() {
  const response = await AivatarClient().get(`/payments/methods`)

  return response.data
}

export async function unsubscribe(workspaceId: number) {
  const response = await AivatarClient().post(`/workspaces/${workspaceId}/unsubscribe`, {
    workspaceId,
    cancel: false, // false면 구독해지. 그렇다면 true는?
  })

  return response.data
}

export async function continueSubscribe(workspaceId: number) {
  const response = await AivatarClient().post(`/workspaces/${workspaceId}/continueSubscribe`)
  return response.data
}

export async function upgradeSubscribe(request: { workspaceId: number; paymentMethodId: number; planId: number }) {
  const { ...reqBody } = request

  const response = await AivatarClient().post(`/workspaces/${request.workspaceId}/upgradeSubscribe`, reqBody)

  return response.data
}

export async function downgradeSubscribe(request: {
  workspaceId: number
  paymentMethodId: number
  planId: number
  period?: number
  interval?: number
}) {
  const { workspaceId, ...reqBody } = request

  const response = await AivatarClient().post(`/workspaces/${workspaceId}/downgradeSubscribe`, reqBody)

  return response.data
}

export const paymentsQueries = createQueryKeys('payments', {
  checkout: (reqBody) => ({
    queryKey: [reqBody],
    queryFn: () => getPaymentsCheckoutDetails(reqBody),
  }),
  methods: {
    queryKey: null,
    queryFn: () => getPaymentMethodList(),
  },
})
