import { useRecoilValue } from 'recoil'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'

import PlusIcon from '../../../../../assets/images/global_icon-plus.png'
import MinusIcon from '../../../../../assets/images/global_icon-minus.png'
import useFirstIntervalValueInput from './useFirstIntervalValueInput'
import { projectValueState } from '../../../../../recoil/project/selectors'

function FirstIntervalController() {
  const firstInterval = useRecoilValue(projectValueState({ key: 'firstInterval' }))

  const { component, changeValue } = useFirstIntervalValueInput()

  /** Methods */

  const initFirstInterval = () => {
    if (firstInterval === 0) changeValue(0.5)
  }

  /** Event Handler */

  const handleFirstInterval = (type) => {
    switch (type) {
      case '+':
        if (firstInterval + 0.1 > 10) return
        changeValue(firstInterval + 0.1)
        break
      case '-':
        if (firstInterval - 0.1 < 0) return
        changeValue(firstInterval - 0.1)
        break

      default:
        break
    }
  }

  /** Event Subscribers */

  /** Memos */

  /** Life Cycle */
  useAivatarDidMount(() => {
    initFirstInterval()
  }, [])

  /** Display */

  return (
    <div className="bg-white p-[10px]">
      <div className="shadow_2 flex items-center justify-between rounded-full bg-[#FAFAFB] p-[5px] text-black">
        <button
          type="button"
          onClick={() => {
            handleFirstInterval('-')
          }}
          className="mr-[8px] flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full text-white"
        >
          <img className="h-[20px] w-[20px]" src={MinusIcon} alt="plus" />
        </button>
        {component}
        <button
          type="button"
          className="flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full text-white"
          onClick={() => {
            handleFirstInterval('+')
          }}
        >
          <img className="h-[20px] w-[20px]" src={PlusIcon} alt="plus" />
        </button>
      </div>
    </div>
  )
}

export default FirstIntervalController
