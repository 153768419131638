import { forwardRef } from 'react'
import { ReactComponent as IconWarning } from '../../../assets/images/icon-warning.svg'

export function ConfigName({ className = '', children }) {
  return <span className={`text-gray-2 block text-[17px] font-bold ${className}`}>{children}</span>
}

export function Config({ name, className = '', children }) {
  return (
    <div className={`w-fit [&+&]:mt-[40px] ${className}`}>
      <ConfigName>{name}</ConfigName>
      <div className="mt-[10px]">{children}</div>
    </div>
  )
}

export function ConfigButton({ className = '', children, ...buttonProps }) {
  return (
    <button className={`btn btn-m h-[45px] w-auto px-[30px] ${className}`} {...buttonProps}>
      {children}
    </button>
  )
}

export function ConfigEditButton({ className = '', ...buttonProps }) {
  return (
    <ConfigButton className={`btn-main-3 ${className}`} {...buttonProps}>
      변경하기
    </ConfigButton>
  )
}

export function ConfigCancelButton({ className = '', ...buttonProps }) {
  return (
    <ConfigButton className={`bg-gray-4 border-0 text-white ${className}`} {...buttonProps}>
      취소
    </ConfigButton>
  )
}

export function ConfigSaveButton({ className = '', ...buttonProps }) {
  return (
    <ConfigButton className={`btn-main ${className}`} {...buttonProps}>
      저장
    </ConfigButton>
  )
}

export const ConfigInput = forwardRef(
  ({ className = '', name, description, isError, errorMessage, ...inputProps }, ref) => (
    <div className={`${className}`}>
      {errorMessage ? (
        <span className="text-point-3 mb-[10px] flex items-center text-[12px]">
          <IconWarning className="mr-[5px]" />
          {errorMessage}
        </span>
      ) : name ? (
        <span className="text-gray-3 mb-[10px] block text-[12px]">{name}</span>
      ) : null}
      <input
        ref={ref}
        className={`input border-gray-4 text-gray-2 mr-[31px] h-[45px] w-[300px] rounded-full px-[25px] ${
          isError
            ? 'border-point-3 text-point-3 hover:border-point-3 border-[2px] shadow-[0px_0px_6px_0px_#FF5757]'
            : ''
        }`}
        {...inputProps}
      />
      {description && <span className="text-gray-3 mt-[10px] block text-[12px]">{description}</span>}
    </div>
  ),
)
