import { useMemo } from 'react'
import { useSetRecoilState } from 'recoil'
import { isMacOs } from 'react-device-detect'
import useKeyPressed from '../../hooks/useKeyPressed'
import { editorValueState } from '../../recoil/texteditor/editor/selectors'

const useMultiSelectCommandHotkeys = () => {
  const setEnableCmdSelect = useSetRecoilState(editorValueState({ key: 'enableCmdSelect' }))
  const setEnableShiftSelect = useSetRecoilState(editorValueState({ key: 'enableShiftSelect' }))

  const keyCodes = useMemo(() => {
    if (isMacOs) return ['MetaLeft', 'MetaRight']
    return ['ControlLeft', 'ControlRight']
  }, [])

  useKeyPressed({
    downCallback: () => {
      setEnableCmdSelect(true)
    },
    upCallback: () => {
      setEnableCmdSelect(false)
    },
    keyCodes, // TODO: window ctrl key
  })

  useKeyPressed({
    downCallback: () => {
      setEnableShiftSelect(true)
    },
    upCallback: () => {
      setEnableShiftSelect(false)
    },
    keyCodes: ['ShiftLeft', 'ShiftRight'],
  })
}

export default useMultiSelectCommandHotkeys
