import { forwardRef, useMemo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { closeSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import ExitIcon from '../../assets/images/global_icon-exit.png'
import SuccessIcon from '../../assets/images/global_toast-success.png'
import FailIcon from '../../assets/images/global_toast-fail.png'

export const durationTime = 10000

const ExportSnackBar = forwardRef((props, ref) => {
  const { t } = useTranslation('project-export')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, isSuccess, filename, workspaceId, isAudio, navigate } = props
  const msg = props.message

  const onClickExit = () => {
    closeSnackbar(id)
  }

  const onClickExportListPage = () => {
    window.open(`${window.location.origin}${`/workspace/${workspaceId}/export?tab=${isAudio ? 'audio' : 'video'}`}`)
  }

  const bgColor = 'bg-white'

  const title = useMemo(() => {
    if (isSuccess) {
      return t('내보내기에 성공했습니다.')
    }
    return t('내보내기에 실패했습니다.')
  }, [isSuccess, t])

  const icon = useMemo(() => {
    if (isSuccess) {
      return SuccessIcon
    }
    return FailIcon
  }, [isSuccess])

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.5 }}
        ref={ref}
        className={`flex h-[85px] w-[430px] items-center justify-between rounded-md p-[20px] ${bgColor} relative flex overflow-hidden`}
      >
        <div className="mr-[10px] h-[35px] min-w-[35px]">
          <img className="h-full w-full" src={icon} alt="exit" />
        </div>
        <div className="flex w-full flex-col">
          <p className="font-[400] text-[#3D3D3D]"> {msg || title}</p>
          <p className="text-[14px] text-[#9F9F9F]"> {filename}</p>
        </div>

        {/* <Link
          to={`/workspace/${workspaceId}/export?tab=${
            isAudio ? "audio" : "video"
          }`}
        > */}
        {isSuccess ? (
          <button
            type="button"
            className="main_c mr-[20px] min-w-[60px] text-[14px] font-medium underline"
            onClick={onClickExportListPage}
          >
            {t('바로가기')}
          </button>
        ) : null}

        {/* </Link> */}

        <button type="button" onClick={onClickExit}>
          <img className="h-[15px] min-w-[15px]" src={ExitIcon} alt="exit" />
        </button>
        <div className="absolute bottom-0 h-[3px] w-full">
          <motion.div
            animate={{ width: 0 }}
            transition={{ ease: 'linear', duration: durationTime / 1000 }}
            className="absolute h-full w-full bg-[#11111150]"
          />
        </div>
      </motion.div>
    </AnimatePresence>
  )
})

ExportSnackBar.displayName = 'ExportSnackBar'

export default ExportSnackBar
