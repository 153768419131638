import { selector, selectorFamily } from 'recoil'
import { CALL_STATE } from '../../services/constants'
import { focusedBoxValueState } from '../texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../texteditor/sentenceBox/atoms'
import { workspaceAvatarState, workspaceVoiceState } from './atoms'
import { projectValueState } from '../project/selectors'

/** Voices */
export const workspaceVoiceValueState = selectorFamily({
  key: 'workspaceVoiceValueState',
  get:
    ({ key }) =>
    ({ get }) => {
      const workspaceVoice = get(workspaceVoiceState)
      return workspaceVoice[key]
    },
  set:
    ({ key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(workspaceVoiceValueState({ key }))
      if (oldValue === newValue) return
      set(workspaceVoiceState, (prev) => ({ ...prev, [key]: newValue }))
    },
})

/* export const voiceListBySelectedLanguageState = selectorFamily({
  key: "voiceListBySelectedLanguageState",
  get:
    ({ language }) =>
    ({ get }) => {
      const allVoices = get(workspaceVoiceValueState({ key: "voices" }));

      return allVoices.filter((voice) => voice.locale === language);
    }
}); */

export const voiceListBySelectedLanguageState = selectorFamily({
  key: 'voiceListBySelectedLanguageState',
  get:
    ({ language }) =>
    ({ get }) => {
      const allVoices = get(workspaceVoiceValueState({ key: 'voices' }))
      const voicesBySelectedLang = allVoices.filter((voice) => voice.locale === language)

      const femaleVoices = voicesBySelectedLang
        .filter((voice) => voice.tags?.includes('여성'))
        .sort((a, b) => {
          if (a.type === 'CUSTOM' || b.type === 'CUSTOM') return -1
          return a.displayName.localeCompare(b.displayName)
        })
      const maleVoices = voicesBySelectedLang
        .filter((voice) => voice.tags?.includes('남성'))
        .sort((a, b) => {
          if (a.type === 'CUSTOM' || b.type === 'CUSTOM') return -1
          return a.displayName.localeCompare(b.displayName)
        })
      const etcVoices = voicesBySelectedLang
        .filter((voice) => !voice.tags?.includes('여성') && !voice.tags?.includes('남성'))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))

      // const result = voicesBySelectedLang.toSorted((a, b) => {
      //   if (a.type === 'CUSTOM' || b.type === 'CUSTOM') return -1
      //   if (!a.tags.includes('여성') && b.tags.includes('여성')) return 1
      //   return a.displayName.localeCompare(b.displayName)
      // })
      // return result

      return [...femaleVoices, ...maleVoices, ...etcVoices]
    },
})

export const isCallSuccessWorkspaceVoiceState = selector({
  key: 'isCallSuccessWorkspaceVoiceState',
  get: ({ get }) => {
    const apiState = get(workspaceVoiceValueState({ key: 'apiState' }))
    return apiState === CALL_STATE.SUCCESS
  },
})

export const isLoadingWorkspaceVoiceState = selector({
  key: 'isLoadingWorkspaceVoiceState',
  get: ({ get }) => {
    const apiState = get(workspaceVoiceValueState({ key: 'apiState' }))
    return apiState === CALL_STATE.FETCHING
  },
})

export const currentSelectedVoiceState = selector({
  key: 'currentSelectedVoiceState',
  get: ({ get }) => {
    const voices = get(workspaceVoiceValueState({ key: 'voices' }))

    const focusedBoxVoiceId = get(
      focusedBoxValueState({
        category: SENTENCEBOX_CATEGORY.OPTION,
        key: 'voiceId',
      }),
    )
    const voice = voices.find((voiceItem) => voiceItem.id === focusedBoxVoiceId)
    return voice || null
  },
})

export const defaultVoiceState = selectorFamily({
  key: 'defaultVoiceState',
  get:
    ({ language }) =>
    ({ get }) => {
      const voices = get(voiceListBySelectedLanguageState({ language }))
      if (Array.isArray(voices) && voices.length > 0) {
        return voices[0]
      }

      const allVoices = get(workspaceVoiceValueState({ key: 'voices' }))
      return allVoices[0]
    },
})

/** Avatar */

export const workspaceAvatarValueState = selectorFamily({
  key: 'workspaceAvatarValueState',
  get:
    ({ key }) =>
    ({ get }) => {
      const workspaceAvatar = get(workspaceAvatarState)
      return workspaceAvatar[key]
    },
  set:
    ({ key }) =>
    ({ get, set }, newValue) => {
      const oldValue = get(workspaceAvatarValueState({ key }))
      if (oldValue === newValue) return
      set(workspaceAvatarState, (prev) => ({ ...prev, [key]: newValue }))
    },
})

export const activeAvatarsState = selector({
  key: 'activeAvatarsState',
  get: ({ get }) => {
    const avatars = get(workspaceAvatarValueState({ key: 'avatars' }))
    return avatars.filter((avatar) => avatar.active)
  },
})

export const currentSelectedCharacterState = selector({
  key: 'currentSelectedCharacterState',
  get: ({ get }) => {
    const avatars = get(workspaceAvatarValueState({ key: 'avatars' }))
    if (avatars.length <= 0) return null
    const selectedAvatarId = get(projectValueState({ key: 'avatarId' }))
    if (!selectedAvatarId) return null

    const selectedAvatar = avatars.find((avatar) => avatar.id === selectedAvatarId)
    return selectedAvatar || null
  },
})

export const selectedCharacterByIdState = selectorFamily({
  key: 'selectedCharacterByIdState',
  get:
    ({ avatarId }) =>
    ({ get }) => {
      const avatars = get(workspaceAvatarValueState({ key: 'avatars' }))
      if (avatars.length <= 0) return null

      const selectedAvatar = avatars.find((avatar) => avatar.id === avatarId)
      return selectedAvatar || null
    },
})

export const dressListBySelectedCharacterState = selector({
  key: 'dressListBySelectedCharacterState',
  get: ({ get }) => {
    const currentSelectedCharacter = get(currentSelectedCharacterState)

    if (!currentSelectedCharacter) return []

    const { detail } = currentSelectedCharacter
    if (!detail) return []

    return detail
  },
})

export const currentSelectedDressState = selector({
  key: 'currentSelectedDressState',
  get: ({ get }) => {
    const dressList = get(dressListBySelectedCharacterState)
    if (dressList <= 0) return null
    const selectedDressId = get(projectValueState({ key: 'dressId' }))
    if (selectedDressId === null) return null
    const selectedDress = dressList.find((dress) => selectedDressId === dress.seqId)

    return selectedDress
  },
})

export const isCallSuccessWorkspaceAvatarState = selector({
  key: 'isCallSuccessWorkspaceAvatarState',
  get: ({ get }) => {
    const apiState = get(workspaceAvatarValueState({ key: 'apiState' }))
    return apiState === CALL_STATE.SUCCESS
  },
})

export const isLoadingWorkspaceAvatarState = selector({
  key: 'isLoadingWorkspaceAvatarState',
  get: ({ get }) => {
    const apiState = get(workspaceAvatarValueState({ key: 'apiState' }))
    return apiState === CALL_STATE.FETCHING
  },
})
