import { useState } from 'react'
import ReactPlayer from 'react-player'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import SentenceBoxConnector from '../../../../../providers/SentenceBoxConnector'

import useAudioControllerCallbacks from '../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { sentenceBoxAudioValueState } from '../../../../../recoil/texteditor/sentenceBox/selectors'

function AudioManager() {
  const { sentenceBoxId } = SentenceBoxConnector.useContainer()
  const [isEnded, setIsEnded] = useState(false)

  /** Recoils */

  const url = useRecoilValue(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'url' }))
  const playing = useRecoilValue(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'playing' }))
  const setDuration = useSetRecoilState(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'duration' }))
  const setPlayed = useSetRecoilState(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'played' }))

  const { nextPlayIntervalInFocused } = useAudioControllerCallbacks()

  /** Services */

  /** Life Cycles */

  /** Event Handlers */
  const onReady = () => {
    setIsEnded(false)
  }

  const onSeek = () => {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onProgress = ({ played, playedSeconds, loadedSeconds }) => {
    if (isEnded) return
    setPlayed(played)
  }

  const onDuration = (duration) => {
    setDuration(duration)
  }

  const onBuffer = () => {}

  const onEnded = () => {
    setIsEnded(true)
    nextPlayIntervalInFocused(sentenceBoxId)
  }

  const onError = (e) => {
    console.log(`onError - ${sentenceBoxId}`, e.message)
  }

  /** Memos */

  return (
    <ReactPlayer
      key={sentenceBoxId}
      id={`Player-${sentenceBoxId}`}
      playing={playing}
      className="react-player"
      style={{
        display: 'none',
      }}
      url={url}
      controls
      playsinline
      progressInterval={100}
      width={0}
      height={0}
      config={{
        file: {
          attributes: {
            crossOrigin: 'anonymous',
            onContextMenu: (e) => e.preventDefault(),
          },
        },
      }}
      onReady={onReady}
      onSeek={onSeek}
      onProgress={onProgress}
      onEnded={onEnded}
      onError={onError}
      onDuration={onDuration}
      onBuffer={onBuffer}
    />
  )
}

export default AudioManager
