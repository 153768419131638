export function getDecimalPlaces(number: number) {
  const numberString = number.toString()

  // 소숫점이 있는지 확인
  const decimalIndex = numberString.indexOf('.')

  if (decimalIndex === -1) {
    // 소숫점이 없으면 0을 반환
    return 0
  }
  // 소숫점 이후의 자리수를 계산하여 반환
  return numberString.length - decimalIndex - 1
}

export function getCardInfo(cardName: string, cardNumber: string) {
  const spacedCardNumber = cardNumber.replace(/(.{4})(.{4})(.{4})(.{4})/, '$1 $2 $3 $4')

  // return cardName === '알 수 없음' ? `${spacedCardNumber}` : `${cardName} | ${spacedCardNumber}`
  return `${cardName} | ${spacedCardNumber}`
}
