import { useState, useMemo } from 'react'
import Popup from 'reactjs-popup'

const useAivatarModal = ({ children, closeOnDocumentClick = true, contentStyle, overlayStyle }) => {
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)
  const openModal = () => setOpen(true)

  const modalComponent = useMemo(
    () => (
      <Popup
        open={open}
        modal
        closeOnDocumentClick={closeOnDocumentClick}
        closeOnEscape
        repositionOnResize
        nested
        contentStyle={contentStyle}
        overlayStyle={{
          background: 'rgba(0,0,0,0.5)',
          zIndex: 1600,
          ...overlayStyle,
        }}
        onClose={closeModal}
      >
        <section className="flex flex-col items-center">{children}</section>
      </Popup>
    ),
    [children, closeOnDocumentClick, contentStyle, open, overlayStyle],
  )

  return {
    openModal,
    closeModal,
    modalComponent,
  }
}

export default useAivatarModal
