import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowLeft } from '../../../assets/images/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right.svg'

type Props = {
  className?: string
  page: number
  pageSize: number
  total: number
  onChangePage?: (newPage: number) => void
}

export default function Pagination({ className = '', page, pageSize, total, onChangePage }: Props) {
  const { t } = useTranslation('table')
  const from = Math.min(page * pageSize + 1, total)
  const to = Math.min((page + 1) * pageSize, total)
  const isPrevDisabled = from <= 1
  const isNextDisabled = to >= total

  return (
    <div className={`flex items-center ${className}`}>
      <span className="text-gray-21 text-[14px] font-medium">
        {t('{{number_from}}-{{number_to}}, 전체: {{number_total}}', {
          number_from: from,
          number_to: to,
          number_total: total,
        })}
      </span>
      <button
        type="button"
        className="[&>svg>path]:disabled:fill-gray-4 ml-[35px]"
        onClick={() => onChangePage?.(page - 1)}
        disabled={isPrevDisabled}
        aria-label={t('이전 페이지')}
      >
        <ArrowLeft className="[&>path]:fill-gray-21" />
      </button>
      <button
        type="button"
        className="[&>svg>path]:disabled:fill-gray-4 ml-[26px]"
        onClick={() => onChangePage?.(page + 1)}
        disabled={isNextDisabled}
        aria-label={t('다음 페이지')}
      >
        <ArrowRight className="[&>path]:fill-gray-21" />
      </button>
    </div>
  )
}
