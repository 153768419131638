import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export default function Td({ children }: Props) {
  return (
    <td className="text-gray-3 py-[12px] text-left text-[14px] first:pl-[32px] last:pr-[32px] last:text-right">
      {children}
    </td>
  )
}
