import { useMemo } from 'react'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as PlayIcon } from '../../../../assets/images/icon-play.svg'

import { AUDIO_CRATE_STATUS } from '../../../../recoil/texteditor/sentenceBox/atoms'
import ExportVideoPlayModal from '../ExportVideoPlayModal'
import useAivatarModal from '../../../../hooks/useAivatarModal'
import ExportAudioPlayModal from '../ExportAudioPlayModal'

function ExportPlay({ row }) {
  const isAudio = useMemo(() => row.exportType === 'sound', [row])

  /** Events */

  const onClickPlay = async () => {
    if (!row.id) return
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (!isValidPlay) return
    if (isAudio) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      openExportAudioPlayModal()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      openExportVideoPlayModal()
    }
  }

  const handleCloseModal = () => {
    if (isAudio) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      closeExportAudioPlayModal()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      closeExportVideoPlayModal()
    }
  }

  /** Modal */
  const {
    modalComponent: ExportVideoPlayModalComponent,
    openModal: openExportVideoPlayModal,
    closeModal: closeExportVideoPlayModal,
  } = useAivatarModal({
    children: <ExportVideoPlayModal row={row} handleCloseModal={handleCloseModal} />,
    closeOnDocumentClick: true,
    contentStyle: {
      width: '490px',
    },
  })

  const {
    modalComponent: ExportAudioPlayModalComponent,
    openModal: openExportAudioPlayModal,
    closeModal: closeExportAudioPlayModal,
  } = useAivatarModal({
    children: <ExportAudioPlayModal row={row} handleCloseModal={handleCloseModal} />,
    closeOnDocumentClick: true,
    contentStyle: {
      width: '806px',
    },
  })

  /** Validations */
  const isValidPlay = React.useMemo(() => {
    if (!row.status) return false
    return row.status === AUDIO_CRATE_STATUS.COMPLETED
  }, [row.status])

  return (
    <button
      type="button"
      disabled={!isValidPlay}
      className={twMerge('mx-auto flex items-center justify-center', !isValidPlay ? 'opacity-50' : null)}
      onClick={onClickPlay}
    >
      {ExportVideoPlayModalComponent}
      {ExportAudioPlayModalComponent}

      <PlayIcon />
    </button>
  )
}

export default ExportPlay
