import React, { useState } from 'react'
import AvatarItem, { Avatar } from './AvatarItem'

type Props = {
  data: Avatar[]
}

export default function AvatarList({ data }: Props) {
  const [playingIndex, setPlayingIndex] = useState<number | null>(null)
  // const [focusedIndex, setFocusedIndex] = useState<number | null>(playingIndex)

  const handleTogglePlay = (index: number) => {
    if (playingIndex === index) {
      setPlayingIndex(null)
    } else {
      setPlayingIndex(index)
    }

    // setFocusedIndex(index)
  }

  // flex w-fit flex-wrap gap-[20px]
  return (
    <div className="grid w-full grid-cols-[repeat(auto-fill,304px)] justify-center gap-[20px]">
      {data.map((item, index) => (
        <AvatarItem
          key={item.label}
          data={item}
          itemIndex={index}
          // focusedItemIndex={focusedIndex}
          playing={index === playingIndex}
          togglePlay={handleTogglePlay}
        />
      ))}
    </div>
  )
}
