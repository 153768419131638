import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowRightCircleIcon } from '../../assets/images/arrow-right-circle.svg'

export default function Section({ title, description, linkTo, children, className = '' }) {
  const { t } = useTranslation('dashboard')

  return (
    <section className={`border-main-3 [&+&]:border-t-[1px] ${className}`}>
      <div className="mb-[15px] mt-[27px] flex items-end pr-[24px]">
        <div>
          <span className="text-gray-2 block text-[28px] font-medium">{title}</span>
          {description && <span className="text-gray-21 mt-[3px] block text-[16px]">{description}</span>}
        </div>
        {linkTo && (
          <Link to={linkTo} className="text-gray-21 ml-auto flex items-center text-[14px] font-medium">
            {t('전체 보기')}
            <ArrowRightCircleIcon className="ml-[2px]" />
          </Link>
        )}
      </div>
      {children}
    </section>
  )
}
