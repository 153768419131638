import { MouseEventHandler, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import { twMerge } from 'tailwind-merge'
import BoxSection from './BoxSection'
import useDialog from '../../../hooks/useDialog'
import SubscriptionPlanDialog from '../../SubscriptionPlan/SubscriptionPlanDialog'
import {
  useCurrentWorkspaceContracted,
  useCurrentWorkspaceDetailQuery,
  useCurrentWorkspacePaymentFailedInfo,
  useCurrentWorkspacePlanName,
} from '../../../hooks/queries'
import PriceFormat from '../../Text/PriceFormater'
import { unsubscribe, continueSubscribe, upgradeSubscribe, paymentsQueries } from '../../../services/payments'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'
import AivatarButton from '../../buttons/AivatarButton'
import { customDayjs } from '../../../utils/date'

type Props = {
  className?: string
}

export default function SubscriptionPlanSection({ className }: Props) {
  const dialog = useDialog()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const navigate = useNavigate()
  const { t } = useTranslation('subscription')
  const { data: methods } = useQuery({
    ...paymentsQueries.methods,
    select: (data) => (data.length === 0 ? null : data),
  })
  const { mutate: mutateToUnsubscribe, isPending: unsubscribing } = useMutation({ mutationFn: unsubscribe })
  const { mutate: mutateToContinue, isPending: continuing } = useMutation({ mutationFn: continueSubscribe })
  const { mutate: mutateToUpgradeSubscribe, isPending: upgrading } = useMutation({
    mutationFn: upgradeSubscribe,
  })
  const contracted = useCurrentWorkspaceContracted()
  const { user } = useAuth()
  const planName = useCurrentWorkspacePlanName()
  const { paymentFailed, failedAt, paymentMethodChanged } = useCurrentWorkspacePaymentFailedInfo()
  const { uri } = useURI()
  const isPause = workspace?.subscription?.status === 'PAUSE'
  const isNextPause = workspace?.nextSubscription?.status === 'PAUSE'

  const showSubscriptionPlanSelector = () => {
    dialog.open(<SubscriptionPlanDialog expanded />)
  }

  const subscriptionDate = useMemo(
    () => ({
      start: customDayjs(workspace?.subscription.startedAt).format('YYYY-MM-DD'),
      end: customDayjs(workspace?.subscription.endAt).format('YYYY-MM-DD'),
    }),
    [workspace],
  )

  const cancelSubscription = () => {
    if (workspace) {
      dialog.close()
      mutateToUnsubscribe(workspace?.id, {
        onSuccess: async () => {
          navigate(`/workspace/${workspace?.id}/subscription/cancelled`)
        },
        onError: () => {
          dialog.alert({
            title: t('해지 실패'),
            message: t('구독해지가 실패되었습니다.'),
          })
        },
      })
    }
  }

  const continueSubscription = () => {
    if (workspace) {
      dialog.close()
      if (isPause) {
        mutateToUpgradeSubscribe(
          {
            workspaceId: workspace?.id,
            paymentMethodId: methods?.[0].id,
            planId: workspace?.subscription?.planId,
          },
          {
            onSuccess: () => {
              navigate(`/workspace/${workspace?.id}/subscription/continue`)
            },
            onError: () => {
              dialog.alert({
                title: t('구독재개 실패'),
                message: t('구독재개에 실패하였습니다.'),
              })
            },
          },
        )
      } else {
        mutateToContinue(workspace.id, {
          onSuccess: () => {
            navigate(`/workspace/${workspace?.id}/subscription/continue`)
          },
          onError: () => {
            dialog.alert({
              title: t('구독재개 실패'),
              message: t('구독재개에 실패하였습니다.'),
            })
          },
        })
      }
    }
  }

  const handleContinueButtonClicked: MouseEventHandler<HTMLButtonElement> = () => {
    dialog.confirm({
      title: t('구독을 재개하시겠습니까?'),
      message: t('재개하기 버튼을 누르면 구독이 다시 유지됩니다.'),
      confirmButtonName: t('구독 재개'),
      onClickConfirm: continueSubscription,
      cancelButtonName: t('닫기', { ns: 'modal' }),
      onClickCancel: dialog.close,
    })
  }

  const onClickUnsubscribe = () => {
    dialog.confirm({
      title: t('정말 구독을 해지하시겠습니까?'),
      message: t(
        '해지하기 버튼을 누르면 다음 결제일부터 구독이 갱신되지 않습니다.\n현재 구독 혜택은 구독 만료일까지 유지됩니다.',
      ),
      confirmButtonName: t('구독 유지'),
      onClickConfirm: dialog.close,
      cancelButtonName: t('해지하기'),
      onClickCancel: cancelSubscription,
    })
  }

  return (
    <>
      {unsubscribing || continuing || upgrading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'fixed',
            zIndex: 9999,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <BeatLoader color="#3498db" loading size={24} />
          <p style={{ color: '#fff', marginTop: '20px' }}>Processing...</p>
        </div>
      ) : null}
      <div className={className}>
        {
          //   isPause ? (
          //   <BoxSection className="border-point-3 text-point-3 mb-[16px]">
          //     <div className="flex justify-between">
          //       <span className="whitespace-pre-wrap text-[14px]">
          //         {t('구독이 만료되었습니다.\n언제든 구독을 재개할 수 있습니다.', {
          //           endAt: subscriptionDate.end,
          //         })}
          //       </span>
          //       <AivatarButton variant="p3" type="button" onClick={handleContinueButtonClicked}>
          //         구독 재개
          //       </AivatarButton>
          //     </div>
          //   </BoxSection>
          // ) :
          isNextPause ? (
            <BoxSection className="border-point-3 text-point-3 mb-[16px]">
              <div className="flex justify-between">
                <span className="whitespace-pre-wrap text-[14px]">
                  {t('구독이 {{endAt}}에 종료됩니다.\n구독 해지 전까지 언제든 구독을 재개할 수 있습니다.', {
                    endAt: subscriptionDate.end,
                  })}
                </span>
                <AivatarButton variant="p3" type="button" onClick={handleContinueButtonClicked}>
                  {t('구독 재개')}
                </AivatarButton>
              </div>
            </BoxSection>
          ) : null
        }

        <BoxSection title={t('플랜')}>
          <div className="mt-[13px] flex items-center">
            <span translate="no" className={twMerge('chip px-[20px]', isPause ? 'bg-point-3 text-white' : null)}>
              <span className="inline-block">{isPause ? t('비활성 플랜') : planName}</span>
            </span>
            {contracted ? null : (
              <>
                {isPause ? null : (
                  <span className="text-gray-2 ml-[10px] font-bold">
                    <PriceFormat amount={workspace?.subscription.price ?? 0} /> /
                    {t('월', { ns: 'period', context: 'short' })}
                  </span>
                )}

                <button
                  className="btn-main btn-m ml-auto h-[45px] w-fit px-[30px]"
                  onClick={showSubscriptionPlanSelector}
                >
                  UPGRADE
                </button>
              </>
            )}
          </div>
          {workspace?.subscription.plan.name !== 'FREE' && (
            <>
              <span
                className={twMerge(
                  'text-gray-21 whitespace-pre-wrap break-keep text-[12px]',
                  isPause ? 'text-point-3 font-[700]' : null,
                )}
              >
                {isPause ? (
                  t(
                    '워크스페이스가 비활성화되었습니다.\n워크스페이스를 다시 활성화하려면, 플랜을 선택하고 구독을 시작해 주세요.',
                  )
                ) : contracted ? (
                  <>
                    {t(
                      '본 플랜은 별도 계약을 통해 제공되는 플랜입니다. 계약 기간은 {{start_date}} - {{end_date}}입니다.',
                      {
                        start_date: subscriptionDate.start,
                        end_date: subscriptionDate.end,
                      },
                    )}
                    <br />
                    <Trans t={t}>
                      구독・계약・결제 관련 사항은{' '}
                      <a
                        href={`${uri['contact-us']}?email=${user?.email}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-hyperlink"
                      >
                        문의하기 페이지
                      </a>
                      를 통해 문의해 주세요.
                    </Trans>
                  </>
                ) : (
                  t('구독 기간은 {{start_date}} - {{end_date}}입니다.', {
                    start_date: subscriptionDate.start,
                    end_date: subscriptionDate.end,
                  })
                )}
                {!isPause && !isNextPause ? (
                  <>
                    <br />
                    <Trans t={t}>
                      구독을 중단하고 싶으시다면,{' '}
                      <button type="button" className="text-hyperlink" onClick={onClickUnsubscribe}>
                        구독해지
                      </button>
                      를 진행해 주세요.
                    </Trans>
                  </>
                ) : null}
              </span>
              {!isPause && paymentFailed ? (
                <p
                  className={twMerge(
                    'text-point-3 mt-[13px] whitespace-pre-wrap text-[12px] font-[700]',
                    paymentMethodChanged ? 'text-main-1' : null,
                  )}
                >
                  {paymentMethodChanged
                    ? t('결제 수단이 정상적으로 업데이트되었습니다. {{retry_at}} 에 결제를 재시도합니다.', {
                        retry_at: customDayjs(failedAt).add(2, 'day').format('YYYY/MM/DD'),
                      })
                    : t(
                        '결제가 정상적으로 진행되지 않았습니다. {{retry_at}} 에 결제를 재시도합니다.\n결제에 완전히 실패할 시 서비스 이용이 중단됩니다.',
                        { retry_at: customDayjs(failedAt).add(2, 'day').format('YYYY/MM/DD') },
                      )}
                </p>
              ) : null}
            </>
          )}
        </BoxSection>
      </div>
    </>
  )
}
