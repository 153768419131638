import { Link } from 'react-router-dom'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'

function Banner({ className = '' }) {
  const { user } = useAuth()
  const { uri } = useURI()

  const queryParams = new URLSearchParams({
    email: user.email,
    firstname: user.name,
    lastname: user.lastName,
  })
  const linkTo = `${uri['contact-us']}?${queryParams}`

  return (
    <Link to={linkTo} target="_blank">
      <img
        className={`bg-main-3 h-[160px] rounded-[10px] ${className} object-cover`}
        src={uri['navbar-banner-image']}
        alt="nav-bar-banner"
      />
    </Link>
  )
}

export default Banner
