import { useRecoilState, useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import { sentenceBoxOptionValueState } from '../../../../recoil/texteditor/sentenceBox/selectors'
import { defaultVoiceState } from '../../../../recoil/workspace/selectors'
import SentenceBoxConnector from '../../../../providers/SentenceBoxConnector'

function useInitVoice() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sentenceBoxId, sentenceBoxIndex } = SentenceBoxConnector.useContainer()
  const { i18n } = useTranslation()

  const locale = i18n.language
  const string = locale.startsWith('ko') ? 'ko-KR' : 'en-US'
  /** Recoils */
  const defaultVoice = useRecoilValue(defaultVoiceState({ language: string }))

  const [voiceId, setVoiceId] = useRecoilState(sentenceBoxOptionValueState({ id: sentenceBoxId, key: 'voiceId' }))
  /** Life Cycles */
  useAivatarDidMount(() => {
    if (voiceId !== null) return
    if (!defaultVoice) return
    setVoiceId(defaultVoice.id)
  }, [voiceId, defaultVoice])

  /** Memos */
}

export default useInitVoice
