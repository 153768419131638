import AivatarClient from '../AivatarClient'

const fetchCreateAudios = async ({ audioIds, accessToken }) => {
  const body = {
    audioIds,
  }
  try {
    return await AivatarClient({ accessToken })
      .post(`audios/individual/sync`, body)
      .then((res) => {
        const { data, status } = res
        if (status !== 200) throw new Error('fetchCreateAudios - Invalid Response Status')
        return data
      })
  } catch (error) {
    throw new Error('fetchCreateAudios - Invalid Response Status')
  }
}

const fetchCreateAudiosAsync = async ({ body, accessToken }) =>
  AivatarClient({ accessToken }).post('/audios', {
    attributes: body,
  })

export { fetchCreateAudios, fetchCreateAudiosAsync }
