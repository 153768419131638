import { useTranslation } from 'react-i18next'
import IntervalIcon from '../../../../assets/images/texteditor_interval-icon.png'
import VolumeIcon from '../../../../assets/images/texteditor_volume-icon.png'
import SpeedIcon from '../../../../assets/images/texteditor_speed-icon.png'
import ToneIcon from '../../../../assets/images/texteditor_tone-icon.png'
import OptionBox from './OptionBox'

const minTitle = '-'
const maxTitle = '+'

const useOptions = () => {
  const { t } = useTranslation('project-option')

  return [
    {
      optionKey: 'space',
      name: t('문장 간격 조절'),
      icon: IntervalIcon,
      minTitle,
      maxTitle,
    },
    {
      optionKey: 'speed',
      name: t('속도 조절'),
      icon: SpeedIcon,
      minTitle,
      maxTitle,
    },
    {
      optionKey: 'pitch',
      name: t('톤 조절'),
      icon: ToneIcon,
      minTitle,
      maxTitle,
    },
    {
      optionKey: 'volume',
      name: t('볼륨 조절'),
      icon: VolumeIcon,
      minTitle,
      maxTitle,
    },
  ]
}

function SentenceBoxOptionController() {
  const OPTIONS = useOptions()

  /** Recoils *

  /** Memos */

  /** Display */

  return (
    <div className="flex w-full flex-col items-center">
      {OPTIONS.map((optionProps) => (
        <OptionBox key={`OptionBox-${optionProps.optionKey}`} optionProps={optionProps} />
      ))}
    </div>
  )
}

export default SentenceBoxOptionController
