import Item from './Item'

export default function ItemWrapper({ groupKey, dresses, itemWidth, itemHeight, onClickItem }) {
  return (
    <div className="mt-[5px] flex w-full flex-col">
      <p className="mb-[8px] text-[17px] font-medium text-[#3D3D3D]">{groupKey}</p>
      <div className="flex w-full flex-wrap pl-[3px]">
        {dresses.map((dress, index) => (
          <Item
            key={`DressContentItem-${index + 1}`}
            dress={dress}
            itemWidth={itemWidth}
            itemHeight={itemHeight}
            index={index}
            onClickItem={onClickItem}
          />
        ))}
      </div>
    </div>
  )
}
