import { CircularProgress } from '@mui/material'
import { twMerge } from 'tailwind-merge'

function IconLoading({ size = '20px', className }) {
  return (
    <div
      className={twMerge('text-main-1 absolute left-0 top-0 flex h-full w-full items-center justify-center', className)}
    >
      <CircularProgress color="inherit" size={size} />
    </div>
  )
}

export default IconLoading
