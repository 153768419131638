import { useRef, useState, useMemo } from 'react'
import { ClickAwayListener } from '@mui/base'
import { Textarea } from '@mui/joy'

import { useRecoilValue } from 'recoil'
import SentenceBoxConnector from '../../../../providers/SentenceBoxConnector'

import useEditorCallbacks from '../../../../recoil/texteditor/editor/useEditorCallbacks'
import { isValidSentenceBoxState, sentenceBoxValueState } from '../../../../recoil/texteditor/sentenceBox/selectors'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import usePlaceholderSentenceText from '../../../../hooks/usePlaceholderSentenceText'

function ParagraphCreator() {
  const textAreaRef = useRef(null)
  const { sentenceBoxId, sentenceBoxIndex } = SentenceBoxConnector.useContainer()
  const { placeholderSentenceText } = usePlaceholderSentenceText()

  /** Recoilds */
  const creatorText = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'creatorText' }))

  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(sentenceBoxId))

  const [text, setText] = useState('')

  const { fetchProject } = useUpdateProject()

  const { removeSentenceBoxAtIndex, addSentenceBoxesByCreator, resetStatesAtCreatorOpen } = useEditorCallbacks()

  const setTextareaCursor = () => {
    if (!textAreaRef.current) return
    const textArea = textAreaRef.current.firstElementChild
    textArea.selectionStart = textArea.value.length
    textArea.focus()
  }

  /** Methods */

  const checkIsEmptyText = (targetText) => targetText.length === 0 || targetText === ' '

  const addParagraphHandler = () => {
    const multiSpaceRegex = /\s+/g
    const spaceFilteredText = text.replaceAll(multiSpaceRegex, ' ')
    const isEmptyText = checkIsEmptyText(spaceFilteredText)
    if (isEmptyText) {
      removeSentenceBoxAtIndex(sentenceBoxIndex)
    } else {
      // setCreatorText(spaceFilteredText);
      addSentenceBoxesByCreator({ id: sentenceBoxId, text: spaceFilteredText })
      fetchProject()
    }
  }

  const borderColor = useMemo(
    () => (isValidSentenceBox ? 'border-[#3A74F5]' : 'border-[#f05e5e]'),
    [isValidSentenceBox],
  )

  /** Event Handlers */
  const onCickAway = () => {
    addParagraphHandler()
  }

  const handleChange = (event) => {
    const { value } = event.target
    if (value.length > 4000) return

    setText(value)
  }

  const onTextAreaKeyyPress = (e) => {
    if (e.code === 'Enter') {
      addParagraphHandler()
    }
  }

  /** Life Cycle */
  useAivatarDidMount(() => {
    resetStatesAtCreatorOpen()
  })

  useAivatarDidMount(() => {
    if (creatorText !== placeholderSentenceText) {
      setText(creatorText)
    }
  }, [creatorText])

  useAivatarDidMount(() => {
    setTextareaCursor()
  }, [textAreaRef])

  return (
    <ClickAwayListener onClickAway={onCickAway} mouseEvent="onMouseDown">
      <div className={`mb-[5px] flex h-fit w-full flex-col rounded-[20px] border-[2px] pr-[5px] ${borderColor}`}>
        <Textarea
          ref={textAreaRef}
          sx={{
            borderRadius: '20px',
            fontWeight: 350,
            // borderWidth: "0",
            border: 'none',
            // borderColor: isValidSentenceBox ? "#3A74F5" : "#f05e5e",
            '--Textarea-focusedThickness': '0',
            '&::before': {
              transition: 'box-shadow .15s ease-in-out',
            },
          }}
          placeholder=""
          minRows={3}
          maxRows={5}
          value={text}
          onChange={handleChange}
          endDecorator={<p className="w-full text-right text-[12px]">{text.length} / 4000</p>}
          onKeyPress={onTextAreaKeyyPress}
          // onKeyDown={onTextAreaKeyDown}
        />
      </div>
    </ClickAwayListener>
  )
}

export default ParagraphCreator
