import { useTranslation } from 'react-i18next'
import WarnIcon from '../../../../../../../assets/images/option_icon-warning.png'
import AivatarButton from '../../../../../../../components/buttons/AivatarButton'
import IconLoading from '../../../../../../../components/Loading/IconLoading'

function CreditModal({ onClickSetting, onClickClose, title, subTitle, setButtonText, isPending = false }) {
  /** Recoilds */
  // const [voice, setVoice] = React.useState(voices[0]);
  const { t } = useTranslation('modal')

  return (
    <div className="aivatar_scroll_wrapper z-50 flex w-[400px] flex-col overflow-hidden overflow-y-auto rounded-md px-[45px] py-[10px]">
      {isPending ? <IconLoading size="80px" /> : null}
      <div className="mb-[10px] flex w-full items-center justify-center">
        <img className="h-[60px] w-[60px]" src={WarnIcon} alt="warn" />
      </div>
      <p className="mb-[20px] text-center text-[24px] font-bold">{title}</p>

      <p className="mb-[20px] text-center text-[#9F9F9F]">{subTitle}</p>
      <div className="flex h-[44px] items-center justify-center font-bold">
        <AivatarButton
          type="button"
          variant="g5"
          className="mr-[10px] flex h-full w-[40%] items-center justify-center rounded-full bg-[#f1f1f1]"
          onClick={onClickClose}
        >
          {t('취소')}
        </AivatarButton>
        <AivatarButton
          disabled={isPending}
          className="flex h-full w-[40%] items-center justify-center rounded-full bg-blue-600 text-white"
          onClick={onClickSetting}
        >
          {setButtonText}
        </AivatarButton>
      </div>
    </div>
  )
}

export default CreditModal
