import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'

export default function AppEffects() {
  const { i18n } = useTranslation()
  const { user } = useAuth()
  const [searchParams] = useSearchParams()

  const lngParam = searchParams.get('lng')
  // document 언어는 무슨일이 있어도 i18n에 의해 정해진 최종 랭기지가 반영되어야 합니다.
  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language)
  }, [i18n.language])

  useEffect(() => {
    // lng searchParam 의 랭기지를 우선적용 합니다. lng가 없다면, 유저가 정한 언어가 i18n에 반영됩니다.
    if (!lngParam && user?.preferredLanguage) {
      i18n.changeLanguage(user.preferredLanguage)
    }
  }, [i18n, user?.preferredLanguage, lngParam])

  return null
}
