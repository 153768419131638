import { useTranslation } from 'react-i18next'
import { ReactComponent as IconBin } from '../../assets/images/icon-bin.svg'

export default function RemoveSelectedRowsButton({ className = '', ...otherProps }) {
  const { t } = useTranslation('project-list')

  return (
    <button
      className={`btn-gray-6 btn-s hover:bg-point-4 hover:text-point-3 [&>svg>path]:hover:fill-point-3 ${className}`}
      {...otherProps}
    >
      {t('삭제')}
      <IconBin className="ml-[4px]" />
    </button>
  )
}
