import { useState, useMemo } from 'react'
import { Tooltip } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import FirstIntervalController from '../FirstIntervalController'
import { editorAudioValueState } from '../../../../../recoil/texteditor/editor/selectors'
import IntervalIconActive from '../../../../../assets/images/texteditor_firstinterval-active.png'
import IntervalIconInActive from '../../../../../assets/images/texteditor_firstinterval-inactive.png'
import ExitIcon from '../../../../../assets/images/global_icon-exit.png'
import { projectValueState } from '../../../../../recoil/project/selectors'

function FirstIntervalButton() {
  const { t } = useTranslation('project')
  const [isOpen, setIsOpen] = useState(false)
  const [firstInterval, setFirstInterval] = useRecoilState(projectValueState({ key: 'firstInterval' }))
  const firstIntervalPlaying = useRecoilValue(editorAudioValueState({ key: 'playing' }))

  /** Memos */

  const isActiveFirstInterval = useMemo(() => firstInterval > 0, [firstInterval])

  const firstIntervalComponent = useMemo(() => {
    if (!isActiveFirstInterval) return null
    return (
      <div className="ml-[6px] flex items-center text-[14px] font-medium text-[#7697E1]">
        {' '}
        <p className="w-[22px] text-center">{`${firstInterval}`}</p>
        <p>{t('초')}</p>
      </div>
    )
  }, [isActiveFirstInterval, firstInterval, t])

  /** Life Cycle */
  useAivatarDidMount(() => {}, [])

  /** Methods */

  /** Event Handler */

  const onCickAway = () => {
    setIsOpen(false)
    // e.preventDefault();
    // e.stopPropagation();
  }

  const onClickOpenController = () => {
    setIsOpen(true)
    // e.preventDefault();
    // e.stopPropagation();
  }

  const onClickReset = () => {
    if (!isActiveFirstInterval) return
    setFirstInterval(0)
    onCickAway()
  }
  /** Event Subscribers */

  /** Display */

  return (
    <ClickAwayListener onClickAway={onCickAway}>
      <div>
        <Tooltip
          open={isOpen}
          onClose={() => setIsOpen(false)}
          disableFocusedListener
          disableHoverListener
          disableTouchListener
          title={<FirstIntervalController />}
          arrow
          placement="top"
          // enterTouchDelay={0}
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: 'none',
                backgroundColor: 'white',
                boxShadow: '1px 3px 17px 0px rgba(0, 40, 104, 0.18)',
                '& .MuiTooltip-arrow': {
                  '&::before': {
                    color: 'white',
                  },
                },
              },
            },
          }}
        >
          <div className="flex items-center">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={onClickOpenController}
              className={`mr-[5px] cursor-pointer ${
                firstIntervalPlaying ? 'animate-pulse bg-blue-100' : 'hover:bg-main-3 bg-white'
              } shadow_2 rounded-full p-[5px] ${isActiveFirstInterval ? '' : 'opacity-50'} flex items-center px-[10px]`}
            >
              <img
                className="h-[19px] w-[13.57px]"
                alt="interval"
                src={isActiveFirstInterval ? IntervalIconActive : IntervalIconInActive}
              />
              <p className="ml-[5px] text-[14px] font-medium">{t('맨 앞 묵음')}</p>
              {firstIntervalComponent}
            </div>
            {isActiveFirstInterval && isOpen && (
              <button
                type="button"
                className={`${isActiveFirstInterval && isOpen ? '' : 'pointer-events-none opacity-0'}`}
                onClick={onClickReset}
              >
                <img className="h-[12px] min-w-[12px]" src={ExitIcon} alt="exit" />
              </button>
            )}
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default FirstIntervalButton
