import CheckboxColumn from './components/CheckboxColumn'
import CheckboxRow from './components/CheckboxRow'
import Pagination from './components/Pagination'
import SortableColumn from './components/SortableColumn'

export default function Table({
  className = '',
  columns = [],
  rows = [],
  useSelect = false,
  selected = [],
  onChangeSelected,
  sort = [],
  onChangeSort,
  onClickReset,
  usePagination = false,
  total = 0,
  page = 0,
  pageSize = 10,
  onChangePage,
  header,
  noDataImage,
  isSuccess,
}) {
  return (
    <div className={className}>
      {(header || usePagination) && (
        <div className="flex h-[55px] items-center pr-[35px]">
          {header}
          <Pagination className="ml-auto" page={page} pageSize={pageSize} total={total} onChangePage={onChangePage} />
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            {useSelect && <CheckboxColumn rows={rows} selected={selected} onChangeSelected={onChangeSelected} />}
            {columns.map(({ key, name, sortable, className: columnClassName }) => (
              <th key={key} className={columnClassName}>
                {sortable ? (
                  <SortableColumn id={key} sort={sort} onChangeSort={onChangeSort} onClickReset={onClickReset}>
                    {name}
                  </SortableColumn>
                ) : (
                  name
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 && isSuccess ? (
            <tr>
              <td colSpan={columns.length + useSelect}>
                <img className="m-auto" src={noDataImage} alt="목록이 비어 있습니다." />
              </td>
            </tr>
          ) : (
            rows.map((row) => (
              <tr key={row.id} className="relative">
                {useSelect && <CheckboxRow row={row} selected={selected} onChangeSelected={onChangeSelected} />}
                {columns.map(({ key, className: columnClassName, render }) => (
                  <td key={key} className={columnClassName}>
                    {render?.(row) ?? row[key]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}
