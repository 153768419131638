import { DropdownMenuListItem } from '../../../DropdownMenu'
import { ReactComponent as CheckCircleIcon } from '../../../../assets/images/icon-check-circle.svg'

type Props = {
  name: string
  description: string
  selected: boolean
}

export default function RoleSelectItem({ name, description, selected }: Props) {
  return (
    <DropdownMenuListItem className="[&_.role-select-item-name]:hover:text-main flex items-center py-[10px] pl-[15px] pr-[8px] [&_.role-select-item-name]:hover:font-bold">
      <div>
        <span className="role-select-item-name text-gray-2 block">{name}</span>
        <span className="text-gray-4 block text-[12px]">{description}</span>
      </div>
      <CheckCircleIcon className="ml-auto" style={{ display: !selected ? 'none' : undefined }} />
    </DropdownMenuListItem>
  )
}
