export function Config({ className = '', children }) {
  return <div className={`w-[354px] ${className}`}>{children}</div>
}

export function ConfigName({ children }) {
  return <span className="text-gray-2 text-[17px] font-bold">{children}</span>
}

export function ConfigBody({ className = '', children }) {
  return <div className={`mt-[30px] flex items-center justify-between ${className}`}>{children}</div>
}

export function ConfigButton({ className = '', children, ...buttonProps }) {
  return (
    <button className={`btn-main btn-m h-[45px] w-auto px-[30px] ${className}`} {...buttonProps}>
      {children}
    </button>
  )
}
