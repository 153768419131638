import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function usePwInput() {
  const { t } = useTranslation('auth-input')
  const [pw, setPw] = useState('')
  const [pwRe, setPwRe] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const verify = () => {
    if (!pw) {
      setErrorMsg(t('비밀번호를 입력해주세요.'))
      return false
    }

    if (!pwRe) {
      setErrorMsg(t('비밀번호 한 번 더 입력해주세요.'))
      return false
    }

    if (pw !== pwRe) {
      setErrorMsg(t('입력한 비밀번호를 확인해 주세요.'))
      return false
    }

    if (pw !== pwRe) {
      setErrorMsg(t('입력한 비밀번호를 확인해 주세요.'))
      return false
    }

    // eslint-disable-next-line no-useless-escape
    const pwRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]).{8,}$/
    if (!pwRegex.test(pw)) {
      setErrorMsg(
        t('비밀번호는 영문(대문자 또는 소문자), 숫자 및 특수문자를\n모두 포함하여 최소 8글자 이상이어야 합니다.'),
      )
      return false
    }

    setErrorMsg('')
    return true
  }

  return {
    pw,
    setPw,
    pwRe,
    setPwRe,
    verify,
    errorMsg,
    setErrorMsg,
    isError: !!errorMsg,
    pwInputProps: {
      type: 'password',
      placeholder: t('새로운 비밀번호'),
      value: pw,
      onChange: (e) => {
        setErrorMsg('')
        setPw(e.target.value)
      },
    },
    pwReInputProps: {
      type: 'password',
      placeholder: t('비밀번호 확인'),
      value: pwRe,
      onChange: (e) => {
        setErrorMsg('')
        setPwRe(e.target.value)
      },
    },
  }
}
