import { useTranslation } from 'react-i18next'
import PlanLink from './PlanLink'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'

type Props = {
  significant?: boolean
}

function ContactLink({ significant }: Props) {
  const { user } = useAuth()
  const { uri } = useURI()

  const { t } = useTranslation('plan-info-modal')

  return (
    <PlanLink href={`${uri['contact-us']}?email=${user?.email}`} target="_blank">
      {significant ? t('세일즈팀에 문의') : t('문의하기')}
    </PlanLink>
  )
}

export default ContactLink
