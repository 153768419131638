import { useTranslation } from 'react-i18next'
import icon from '../../assets/images/icon-emptyuser.png'

export default function ProfileImg({ className = '' }) {
  // const { user } = useAuth()
  const { t } = useTranslation('dashboard-header')

  // const name = useMemo(() => {
  //   return user?.name.slice(0, 1)
  // }, [user])

  return (
    <div
      className={`flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[#a9bdea] text-[16px] font-[350] text-white ${className}`}
    >
      {/* <img src={user?.imageUrl} alt={t('내 프로필 이미지')} /> */}
      <img src={icon} alt={t('내 프로필 이미지')} width={29} />
    </div>
  )
}
