import { useMemo } from 'react'
import { useMeasure } from 'react-use'

import { useRecoilState } from 'recoil'
import { twMerge } from 'tailwind-merge'
import useOptionValueInput from './useOptionValueInput'
import useOptionValueSlider from './useOptionValueSlider'
import { focusedBoxValueState } from '../../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY, SentenceBoxValuesForFetch } from '../../../../../recoil/texteditor/sentenceBox/atoms'

function OptionBox({ optionProps }) {
  const { optionKey, name, icon, minTitle, maxTitle } = optionProps
  /** Recoils */
  const [ref, { width }] = useMeasure()
  const { component: InputComponent, setOptionInput: setInputValue } = useOptionValueInput(optionKey)
  const { component: SliderComponent } = useOptionValueSlider(optionKey)

  const isInterval = useMemo(() => optionKey === 'space', [optionKey])
  const [v, setV] = useRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  const onClickMinus = () => {
    if ((isInterval && v > 0.1) || (!isInterval && v > 0.5)) {
      const number = Number((v - 0.1).toFixed(1))
      setV(number)
      setInputValue(number)
      setSentenceBoxValues({ ...sentenceBoxValues, [optionKey]: number })
      // dirtyInput()
    }
  }

  const onClickPlus = () => {
    if ((isInterval && v < 99.9) || (!isInterval && v < 1.5)) {
      const number = Number((v + 0.1).toFixed(1))
      setV(number)
      setInputValue(number)
      setSentenceBoxValues({ ...sentenceBoxValues, [optionKey]: number })
      // dirtyInput()
    }
  }

  /** Event handler */

  /** Memos */
  const boxHeight = useMemo(() => `h-[${width * 0.4375}px]`, [width])

  return (
    <div
      className={`relative w-full ${boxHeight} shadow_1 mb-[20px] flex flex-col items-center rounded-2xl bg-[#ffffff60] px-[40px] pb-[12px] pt-[18px]`}
      ref={ref}
    >
      <div className="flex w-full items-center">
        <div className="mr-[5px] flex h-[35px] w-[35px] items-center justify-center rounded-sm bg-[#F5F7FE]">
          <img className="h-auto w-[20px]" src={icon} alt="optionbox" />
        </div>
        <p className="text-[17px] font-bold">{name}</p>
      </div>

      <div className={twMerge('relative mb-[10px] flex w-full flex-col', optionKey === 'space' ? 'mt-[-28px]' : null)}>
        {InputComponent}
        {optionKey !== 'space' ? SliderComponent : null}
        {optionKey !== 'space' ? (
          <div className="mb-[-13px] flex w-full items-center justify-between text-[12px] text-[#9F9F9F]">
            <button
              type="button"
              className="rounded-full border-[0.5px] border-[#9F9F9F] px-[5px]"
              onClick={() => onClickMinus()}
            >
              {minTitle}
            </button>
            <button
              type="button"
              className="rounded-full border-[0.5px] border-[#9F9F9F] px-[5px]"
              onClick={onClickPlus}
            >
              {maxTitle}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default OptionBox
