import { useTranslation } from 'react-i18next'
import Section from '../Section'
import ExportTable from '../../Table/ExportTable'
import { useRecentExportList } from '../../../hooks/queries'

export default function RecentExportListSection({ className = '' }) {
  const { t } = useTranslation('dashboard')
  const { data } = useRecentExportList()
  // const rows = useMemo(
  //   () => [
  //     {
  //       id: 1,
  //       title: '음성묶음.wav',
  //       createdAt: new Date('2023-07-13 22:03'),
  //       status: 'complete',
  //       type: 'audio',
  //       projectId: 1,
  //     },
  //     {
  //       id: 2,
  //       title: '영상예시1.mp4',
  //       createdAt: new Date('2023-07-13 14:30'),
  //       status: 'in-progress',
  //       type: 'video',
  //       projectId: 2,
  //     },
  //   ],
  //   [],
  // )

  return (
    <Section title={t('최근 내보내기')} linkTo="./export?tab=audio" className={className}>
      <ExportTable rows={data} />
    </Section>
  )
}
