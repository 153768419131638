import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropdownMenu, { DropdownMenuList } from '../../../DropdownMenu'
// import { ReactComponent as ArrowDownCircleFill } from '../../../../assets/images/arrow-down-circle-fill.svg'
import useDialog from '../../../../hooks/useDialog'
import RoleSelectItem from './RoleSelectItem'

export default function RoleSelect({ value }) {
  const dialog = useDialog()
  const { t } = useTranslation('workspace')
  const [isOpen, setIsOpen] = useState(false)

  const showMemberRemovalConfirm = () => {
    dialog.confirm({
      title: (
        <>
          USERNAME을(를){'\n'}워크스페이스에서 <strong>제거</strong>
          하시겠습니까?
        </>
      ),
      message: '제거를 누르면 워크스페이스에서 삭제됩니다.',
      confirmButtonName: '제거',
      highlightColor: 'point-3',
    })
  }

  const getRoleLabel = (role) => {
    switch (role.toUpperCase()) {
      case 'OWNER':
        return t('소유자')
      case 'ADMIN':
        return t('관리자')
      case 'MEMBER':
        return t('멤버')
      default:
        return role
    }
  }

  return (
    <>
      <button
        className="pointer-events-none flex items-center"
        /** todo - add onChange */
        // onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        {getRoleLabel(value)}
        {/* <ArrowDownCircleFill className="ml-[5px]" /> */}
      </button>
      {isOpen && (
        <DropdownMenu
          className="top-[69px] w-[254px]"
          onOutsideClick={() => {
            setIsOpen(false)
          }}
        >
          <DropdownMenuList>
            <RoleSelectItem name="워크스페이스 소유자" description="워크스페이스 소유자" selected />
            <RoleSelectItem name="워크스페이스 관리자" description="워크스페이스 관리자" />
            <RoleSelectItem name="멤버" description="멤버" />
            <button
              className="text-point-3 hover:bg-point-4 w-full rounded-[10px] px-[15px] py-[10px] text-left"
              onClick={() => {
                setIsOpen(false)
                showMemberRemovalConfirm()
              }}
            >
              워크스페이스에서 제거
            </button>
          </DropdownMenuList>
        </DropdownMenu>
      )}
    </>
  )
}
