import MuiDialog, { DialogProps } from '@mui/material/Dialog'

type Props = Omit<DialogProps, 'open'> & {
  open?: boolean
}

export default function Dialog({ open = true, children, ...otherProps }: Props) {
  return (
    <MuiDialog open={open} {...otherProps}>
      {children}
    </MuiDialog>
  )
}
