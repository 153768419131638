import { v4 as uuidv4 } from 'uuid'

import { useRecoilCallback } from 'recoil'
import { useTranslation } from 'react-i18next'
import { sentenceBoxState, SENTENCEBOX_CATEGORY, SENTENCEBOX_TYPE, sentenceBoxOptionState } from './atoms'
import { editorValueState, focusedBoxValueState, selectedBoxIdsState } from '../editor/selectors'
import { editorState, sentenceBoxIdsState } from '../editor/atoms'
import { isCreatorOpenState } from './selectors'
import { selectMultipleBoxes } from '../editor/editorHelperCallbacks'
import {
  resetFirstIntervalAudio,
  resetIntervalAudio,
  resetSentenceBoxAudio,
} from '../../audiocontroller/audioControllerHelpers'

import { addItemsAtIndex } from '../../../utils/AivatarArrayHandler'
import usePlaceholderSentenceText from '../../../hooks/usePlaceholderSentenceText'

const useSentenceBoxCallbacks = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language.startsWith('ko') ? 'ko-KR' : 'en-US'
  const { placeholderSentenceText } = usePlaceholderSentenceText()
  const selectBox = useRecoilCallback(
    ({ snapshot, set: outerSet, transact_UNSTABLE }) =>
      ({ id, isSelected, keyDirection }) => {
        const enableCmdSelect = snapshot.getLoadable(editorValueState({ key: 'enableCmdSelect' })).getValue()
        const enableShiftSelect = snapshot.getLoadable(editorValueState({ key: 'enableShiftSelect' })).getValue()

        const oldSelectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()

        // reset audio
        const focusedBoxAudioId = snapshot
          .getLoadable(
            focusedBoxValueState({
              category: SENTENCEBOX_CATEGORY.AUDIO,
              key: 'id',
            }),
          )
          .getValue()
        if (focusedBoxAudioId) {
          resetSentenceBoxAudio({ id: focusedBoxAudioId, set: outerSet })
          resetIntervalAudio({ id: focusedBoxAudioId, set: outerSet })
        }
        resetFirstIntervalAudio({ set: outerSet })
        // resetIntervalAudio({ id, set });

        transact_UNSTABLE(({ set }) => {
          // unselect all boxes
          if (!enableCmdSelect || (enableCmdSelect && enableShiftSelect)) {
            selectMultipleBoxes({
              set,
              boxIds: oldSelectedBoxIds,
              isSelected: false,
            })
          }

          const sentenceBoxIds = snapshot.getLoadable(sentenceBoxIdsState).getValue()
          const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()

          // select
          if (oldSelectedBoxIds.length !== 0 && enableShiftSelect) {
            const focusedBoxId = snapshot.getLoadable(editorValueState({ key: 'focusedBoxId' })).getValue()
            const focusedBoxIndex = sentenceBoxIds.findIndex((boxId) => boxId === focusedBoxId)
            const selectedBoxIndex = sentenceBoxIds.findIndex((boxId) => boxId === id)

            // 단순 클릭 시에는 쉬프트 클릭 처리는 단순해요. 커서를 기준으로만 하면 되거든요
            let oppositeBoxIndex = focusedBoxIndex

            // 키를 누른 상태는 좀 복잡합니다. 커서 반대편에 있는 놈들 해제하면 안되거든요.
            // 그래서 선택한 곳이 어딘지 찾은 다음 반대편 인덱스 또한 찾는 작업이 들어갑니다.
            // 원점으로 복귀하는 경우는 left right 따질수밖에 없어요. 직접 개발해보시면 압니다.
            // 현재 주어진 상황에서 이렇게 할 수밖에 없었음을 이해 바랍니다.
            if (keyDirection && !enableCmdSelect) {
              if (selectedBoxIndex > focusedBoxIndex) {
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(oppositeBoxIndex - 1))) {
                  oppositeBoxIndex -= 1
                }
              } else if (selectedBoxIndex < focusedBoxIndex) {
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(oppositeBoxIndex + 1))) {
                  oppositeBoxIndex += 1
                }
              } else if (keyDirection === 'left') {
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(oppositeBoxIndex - 1))) {
                  oppositeBoxIndex -= 1
                }
              } else if (keyDirection === 'right') {
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                while (selectedBoxIds.find((boxId) => boxId === sentenceBoxIds.at(oppositeBoxIndex + 1))) {
                  oppositeBoxIndex += 1
                }
              }
            }

            for (
              let i = Math.min(selectedBoxIndex, oppositeBoxIndex);
              i <= Math.max(selectedBoxIndex, oppositeBoxIndex);
              i += 1
            ) {
              set(sentenceBoxState(sentenceBoxIds[i]), (prev) => ({
                ...prev,
                isSelected: true,
              }))
            }
          } else {
            set(sentenceBoxState(id), (prev) => ({
              ...prev,
              isSelected,
            }))
            const prevFocusedBoxId = snapshot.getLoadable(editorValueState({ key: 'focusedBoxId' })).getValue()

            // 다음 셀렉트 커서 정하기
            let nextFocusedBoxId = id
            if (!isSelected) {
              if (prevFocusedBoxId === id) {
                if (selectedBoxIds.length <= 1) {
                  nextFocusedBoxId = null
                } else {
                  let nextIndex = selectedBoxIds.findIndex((boxId) => boxId === id) + 1
                  if (nextIndex >= selectedBoxIds.length) {
                    nextIndex -= 2 // 오른쪽으로 갔더니 없네?? 그럼 왼쪽으로 가야지. 2로 설정한 이유는 원점을 건너뛰기 위함
                  }
                  nextFocusedBoxId = selectedBoxIds[nextIndex]
                }
              } else {
                nextFocusedBoxId = prevFocusedBoxId
              }
            } else {
              nextFocusedBoxId = id
            }
            set(editorState, (prev) => ({
              ...prev,
              focusedBoxId: nextFocusedBoxId,
            }))
          }
          // update focused
          // updateFocused({ get, set })
        })
      },
  )

  const selectAllBoxes = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const focusedBoxId = snapshot.getLoadable(editorValueState({ key: 'focusedBoxId' })).getValue()

    transact_UNSTABLE(({ get, set }) => {
      const sentenceBoxIds = get(sentenceBoxIdsState)
      selectMultipleBoxes({ set, boxIds: sentenceBoxIds, isSelected: true })

      if (!focusedBoxId) {
        set(editorState, (prev) => ({
          ...prev,
          focusedBoxId: sentenceBoxIds[0],
        }))
      }
    })
  })

  const convertBoxToCreator = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => ({ id }) => {
    const isCreatorOpen = snapshot.getLoadable(isCreatorOpenState).getValue()

    if (isCreatorOpen) return

    transact_UNSTABLE(({ set }) => {
      set(sentenceBoxState(id), (prev) => ({
        ...prev,
        type: SENTENCEBOX_TYPE.CREATOR,
        creatorText: prev.text,
      }))
    })
  })

  const addPlaceHolderSentenceBoxAtFirstIndex = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const sentenceBoxIds = snapshot.getLoadable(sentenceBoxIdsState).getValue()
    const placeHolderBoxId = uuidv4()

    let newSentenceBoxIds = []
    // add new box
    newSentenceBoxIds = addItemsAtIndex(sentenceBoxIds, 0, [placeHolderBoxId])

    transact_UNSTABLE(({ set }) => {
      // update ids
      set(sentenceBoxIdsState, newSentenceBoxIds)
      set(sentenceBoxState(placeHolderBoxId), (prev) => ({
        ...prev,
        text: placeholderSentenceText,
      }))
      set(sentenceBoxOptionState(placeHolderBoxId), (prev) => ({
        ...prev,
        language: locale,
      }))
    })
  })

  return {
    selectBox,
    selectAllBoxes,
    convertBoxToCreator,
    addPlaceHolderSentenceBoxAtFirstIndex,
  }
}

export default useSentenceBoxCallbacks
