import { atom } from 'recoil'
import { CALL_STATE } from '../../../services/constants'

export const sentenceBoxIdsState = atom({
  key: 'sentenceBoxIdsState',
  default: [], // sentenceBoxState Ids
})

export const editorState = atom({
  key: 'editorState',
  default: {
    enableCmdSelect: false,
    enableShiftSelect: false,
    focusedBoxId: null,
  },
})

export const editorAudioState = atom({
  key: 'editorAudioState',
  default: {
    played: 0,
    playing: false,
    audioCreationState: CALL_STATE.NEW,
  },
})
