import { useRecoilCallback } from 'recoil'
import {
  isViewerBoxesEmptyState,
  isEmptyFocusedBoxState,
  playableBoxesState,
  isLastFocusedBoxInSelectedBoxesState,
  focusedBoxIndexAmongSelectedBoxesState,
  selectedBoxIdsState,
  editorValueState,
  selectedBoxesByCategoryState,
  isEmptyLoadingBoxState,
  focusedBoxValueState,
  viewerBoxIdsState,
} from '../texteditor/editor/selectors'
import {
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
  SENTENCEBOX_CATEGORY,
} from '../texteditor/sentenceBox/atoms'
import { resetFirstIntervalAudio, resetIntervalAudio, resetSentenceBoxAudio } from './audioControllerHelpers'
import { getFocusedBoxByCategory, selectMultipleBoxes } from '../texteditor/editor/editorHelperCallbacks'
import { editorAudioState, editorState } from '../texteditor/editor/atoms'
import { updateFocused } from '../texteditor/sentenceBox/sentenceBoxHelpers'
import {
  isLoadingAudioState,
  isValidPlayAudioState,
  isValidSentenceBoxState,
} from '../texteditor/sentenceBox/selectors'
import { projectState } from '../project/atoms'

const useAudioControllerCallbacks = () => {
  const updateStartFocused = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => async () => {
    const isEmptyFocusedBox = snapshot.getLoadable(isEmptyFocusedBoxState).getValue()

    const playableBoxes = snapshot.getLoadable(playableBoxesState).getValue()

    // 선택문장이 하나일 때
    // 그 선택 문장 이후에 모든 박스 선택해버려

    const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()
    const focusedBoxId = snapshot
      .getLoadable(
        focusedBoxValueState({
          key: 'id',
          category: SENTENCEBOX_CATEGORY.GENERAL,
        }),
      )
      .getValue()
    const viewerBoxIds = snapshot.getLoadable(viewerBoxIdsState).getValue()

    transact_UNSTABLE(({ get, set }) => {
      // 전체 재생인지, focused 재생인지 체크
      if (isEmptyFocusedBox) {
        selectMultipleBoxes({
          set,
          boxIds: playableBoxes.map((box) => box.id),
          isSelected: true,
        })
      } else if (selectedBoxIds.length === 1) {
        const currentFocusedBoxIndex = viewerBoxIds.findIndex((viewerId) => viewerId === focusedBoxId)
        const willSelectingIds = viewerBoxIds.slice(currentFocusedBoxIndex, viewerBoxIds.length)
        selectMultipleBoxes({
          set,
          boxIds: willSelectingIds,
          isSelected: true,
        })
      }

      updateFocused({ get, set })
    })
  })

  const startPlayFocusedBox = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => async () => {
    const focusedBoxId = await snapshot.getPromise(editorValueState({ key: 'focusedBoxId' }))

    if (!focusedBoxId) return
    const isValidPlay = await snapshot.getPromise(isValidPlayAudioState({ id: focusedBoxId }))

    if (!isValidPlay) return

    const currentFocusedBoxIndex = await snapshot.getPromise(focusedBoxIndexAmongSelectedBoxesState)
    if (currentFocusedBoxIndex === null) return
    const isFirstFocused = currentFocusedBoxIndex === 0
    transact_UNSTABLE(({ get, set }) => {
      const { played, intervalPlayed } = getFocusedBoxByCategory({
        category: SENTENCEBOX_CATEGORY.AUDIO,
        focusedBoxId,
        get,
      })
      const editorAudio = get(editorAudioState)
      const project = get(projectState)

      // 첫 문장 재생
      if (isFirstFocused) {
        // 첫 재생, 첫 묵음 고려하는 재생
        if (editorAudio.played > 0) {
          // 첫 묵음 재생 컨트롤
          set(editorAudioState, (prev) => ({
            ...prev,
            playing: !prev.playing,
          }))
        } else if (intervalPlayed > 0) {
          // 문장 묵음 재생 컨트롤
          set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
            ...prev,
            intervalPlaying: !prev.intervalPlaying,
          }))
        } else if (played > 0) {
          // 문장 재생 컨트롤
          set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
            ...prev,
            playing: !prev.playing,
          }))
          // 첫 재생 - 첫 묵음 있는 경우
        } else if (project.firstInterval > 0) {
          set(editorAudioState, (prev) => ({
            ...prev,
            playing: !prev.playing,
          }))
        } else {
          // 첫 재생 - 첫 묵음 없는경우
          set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
            ...prev,
            playing: !prev.playing,
          }))
        }
        // 중간 문장 재생
      } else if (intervalPlayed > 0) {
        // 문장 묵음 재생 컨트롤
        set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
          ...prev,
          intervalPlaying: !prev.intervalPlaying,
        }))
      } else {
        // 묵음 재생할거 아니면, 문장 재생 컨트롤
        set(sentenceBoxAudioState(focusedBoxId), (prev) => ({
          ...prev,
          playing: !prev.playing,
        }))
      }
    })
  })

  const updateNextFocused = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => (sentenceBoxId) => {
    // foucs validation
    const isEmptyFocusedBox = snapshot.getLoadable(isEmptyFocusedBoxState).getValue()
    if (isEmptyFocusedBox) return

    const isLastFocusedBoxInSelectedBoxes = snapshot.getLoadable(isLastFocusedBoxInSelectedBoxesState).getValue()

    const currentFocusedBoxIndex = snapshot.getLoadable(focusedBoxIndexAmongSelectedBoxesState).getValue()

    const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()
    let nextSelectedBoxIndex = currentFocusedBoxIndex + 1
    let newFocusedBoxId = selectedBoxIds[nextSelectedBoxIndex]

    while (!snapshot.getLoadable(isValidSentenceBoxState(newFocusedBoxId)).getValue()) {
      nextSelectedBoxIndex += 1
      newFocusedBoxId = selectedBoxIds[nextSelectedBoxIndex]
    }

    transact_UNSTABLE(({ get, set }) => {
      // 현재 트랙 오디오 전체 리셋
      resetSentenceBoxAudio({ id: sentenceBoxId, set })
      resetIntervalAudio({ id: sentenceBoxId, set })

      // 마지막 문장 처리
      if (isLastFocusedBoxInSelectedBoxes) {
        updateFocused({ get, set })
        return
      }

      // if (!isValidPlay) return
      set(editorState, (prev) => ({
        ...prev,
        focusedBoxId: newFocusedBoxId,
      }))

      set(sentenceBoxAudioState(newFocusedBoxId), (prev) => ({
        ...prev,
        playing: !prev.playing,
      }))
    })
  })
  const updatePrevFocused = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => (sentenceBoxId) => {
    // foucs validation
    const isEmptyFocusedBox = snapshot.getLoadable(isEmptyFocusedBoxState).getValue()
    if (isEmptyFocusedBox) return

    // 첫 번째 인지 체크
    const currentFocusedBoxIndex = snapshot.getLoadable(focusedBoxIndexAmongSelectedBoxesState).getValue()
    if (currentFocusedBoxIndex === 0) return

    const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()
    let nextSelectedBoxIndex = currentFocusedBoxIndex - 1
    let newFocusedBoxId = selectedBoxIds[nextSelectedBoxIndex]

    while (!snapshot.getLoadable(isValidSentenceBoxState(newFocusedBoxId)).getValue()) {
      nextSelectedBoxIndex -= 1
      newFocusedBoxId = selectedBoxIds[nextSelectedBoxIndex]
    }

    transact_UNSTABLE(({ set }) => {
      // 현재 트랙 오디오 전체 리셋
      resetSentenceBoxAudio({ id: sentenceBoxId, set })
      resetIntervalAudio({ id: sentenceBoxId, set })

      // 이전 트랙 설정
      set(editorState, (prev) => ({
        ...prev,
        focusedBoxId: newFocusedBoxId,
      }))

      // if (!isValidPlay) return
      set(sentenceBoxAudioState(newFocusedBoxId), (prev) => ({
        ...prev,
        playing: !prev.playing,
      }))
    })
  })

  // const playFocusedBox = useRecoilCallback(
  //   ({ snapshot, set, transact_UNSTABLE }) =>
  //     async () => {
  //       transact_UNSTABLE(({ get, set }) => {
  //         const { focusedBoxId: newFocusedBoxId } = get(editorState);
  //         if (!newFocusedBoxId) return;
  //         const isValidPlay = isValidPlayHelper({ get, id: newFocusedBoxId });
  //         if (!isValidPlay) return;

  //         set(sentenceBoxAudioState(newFocusedBoxId), (prev) => ({
  //           ...prev,
  //           playing: !prev.playing
  //         }));
  //       });
  //     }
  // );

  const nextPlayIntervalInFocused = useRecoilCallback(({ snapshot, set, transact_UNSTABLE }) => (sentenceBoxId) => {
    // foucs validation
    const isEmptyFocusedBox = snapshot.getLoadable(isEmptyFocusedBoxState).getValue()
    if (isEmptyFocusedBox) return

    // 현재 트랙 문장박스 오디오 리셋
    resetSentenceBoxAudio({ id: sentenceBoxId, set })
    transact_UNSTABLE(({ set: innerSet }) => {
      // play space
      innerSet(sentenceBoxAudioState(sentenceBoxId), (prev) => ({
        ...prev,
        intervalPlaying: true,
      }))
    })
  })

  const nextPlayInFirstInterval = useRecoilCallback(({ snapshot, set, transact_UNSTABLE }) => () => {
    // foucs validation
    const isEmptyFocusedBox = snapshot.getLoadable(isEmptyFocusedBoxState).getValue()
    if (isEmptyFocusedBox) return

    // 현재 트랙 오디오 전체 리셋
    resetFirstIntervalAudio({ set })

    const focusedBoxId = snapshot.getLoadable(editorValueState({ key: 'focusedBoxId' })).getValue()
    transact_UNSTABLE(({ set: innerSet }) => {
      innerSet(sentenceBoxAudioState(focusedBoxId), (prev) => ({
        ...prev,
        playing: !prev.playing,
      }))
    })
  })

  const getWillCreateAudioBoxes = useRecoilCallback(({ snapshot }) => ({ start, count }) => {
    try {
      const selectedBoxes = snapshot
        .getLoadable(
          selectedBoxesByCategoryState({
            category: SENTENCEBOX_CATEGORY.AUDIO,
          }),
        )
        .getValue()

      if (selectedBoxes.length <= 0) return []
      const nominatedAudioBoxes = selectedBoxes.slice(start, start + count)

      // get create nominatnio box
      const willCreateAudioBoxes = nominatedAudioBoxes.filter((box) => {
        const isValidSentenceBox = snapshot.getLoadable(isValidSentenceBoxState(box.id)).getValue()
        const isEmptyUrl = box.url === null
        const isLoading = snapshot.getLoadable(isLoadingAudioState({ id: box.id })).getValue()

        return isValidSentenceBox && isEmptyUrl && !isLoading
      })
      return willCreateAudioBoxes
    } catch (error) {
      console.error(error)
      return []
    }
  })

  const checkIsValidAudioPreplay = useRecoilCallback(({ snapshot }) => () => {
    const isViewerBoxesEmpty = snapshot.getLoadable(isViewerBoxesEmptyState).getValue()

    const isEmptyLoadingBox = snapshot.getLoadable(isEmptyLoadingBoxState).getValue()

    return !isViewerBoxesEmpty && isEmptyLoadingBox
  })

  const setAudioById = useRecoilCallback(({ transact_UNSTABLE }) => ({ id, newValues }) => {
    transact_UNSTABLE(({ set }) => {
      set(sentenceBoxAudioState(id), (prev) => ({ ...prev, ...newValues }))
    })
  })

  const getAudioUpdateBody = useRecoilCallback(({ snapshot }) => ({ sentenceBoxIds, projectId }) => {
    if (!Array.isArray(sentenceBoxIds)) return []
    if (sentenceBoxIds.length <= 0) return []

    const attributes = sentenceBoxIds.map((sentenceBoxId) => {
      const sentenceBoxOption = snapshot.getLoadable(sentenceBoxOptionState(sentenceBoxId)).getValue()
      const { voiceId, space, pitch, speed, volume } = sentenceBoxOption

      const sentenceBox = snapshot.getLoadable(sentenceBoxState(sentenceBoxId)).getValue()
      const sentenceBoxAudio = snapshot.getLoadable(sentenceBoxAudioState(sentenceBoxId)).getValue()
      return {
        projectId,
        sentenceId: sentenceBox.sentenceId,
        audioId: sentenceBoxAudio.audioId,
        voiceId,
        space,
        pitch,
        speed,
        volume,
      }
    })

    return {
      attributes,
    }
  })

  return {
    updateStartFocused,
    startPlayFocusedBox,
    updateNextFocused,
    updatePrevFocused,
    // playFocusedBox,
    nextPlayIntervalInFocused,
    nextPlayInFirstInterval,
    getWillCreateAudioBoxes,
    checkIsValidAudioPreplay,
    setAudioById,
    getAudioUpdateBody,
  }
}

export default useAudioControllerCallbacks
