import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useOutlet } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactElement, useEffect } from 'react'
import { globalHistory } from '@gatsbyjs/reach-router'
import { DialogProvider } from '../../../hooks/useDialog'
import AivatarSnackbarProvider from '../../Snackbar/AivatarSnackbarProvider'
// import RecoilDebugObserver from '../../../recoil/DebugObsever'
import AppEffects from './AppEffects'

const queryClient = new QueryClient()

function HubspotTrackingLister({ children }: { children: ReactElement }) {
  useEffect(() => {
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.id = 'hs-script-loader'
    script.async = true
    script.defer = true
    script.src = '//js.hs-scripts.com/39510856.js'

    document.body.appendChild(script)

    const sendPageView = () => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/naming-convention, no-multi-assign, no-underscore-dangle
      const _hsq = (window._hsq = window._hsq || [])
      _hsq.push(['setPath', window.location.pathname])
      _hsq.push(['trackPageView'])
      // console.log("set new path: ", window.location.pathname);
    }
    sendPageView()
    // @ts-ignore
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') sendPageView()
    })
  }, [])

  return children
}

function RootLayout() {
  const outlet = useOutlet()
  return (
    <RecoilRoot>
      {/* {process.env.NODE_ENV === 'development' ? <RecoilDebugObserver /> : null} */}
      <QueryClientProvider client={queryClient}>
        <AppEffects />
        <ReactQueryDevtools initialIsOpen={false} />
        <DialogProvider>
          <AivatarSnackbarProvider>
            <HubspotTrackingLister>
              <div className="h-screen w-full overflow-hidden">{outlet}</div>
            </HubspotTrackingLister>
          </AivatarSnackbarProvider>
        </DialogProvider>
      </QueryClientProvider>
    </RecoilRoot>
  )
}

export default RootLayout
