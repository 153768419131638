import { useRecoilCallback } from 'recoil'

import { selectedCharacterByIdState } from './selectors'
import { projectState } from '../project/atoms'

const useWorkspaceCallback = () => {
  const selectCharacter = useRecoilCallback(({ snapshot, set, transact_UNSTABLE }) => ({ avatarId }) => {
    const selectedCharacterById = snapshot.getLoadable(selectedCharacterByIdState({ avatarId })).getValue()
    if (!selectedCharacterById) return
    const { detail: dressList } = selectedCharacterById
    if (dressList.length <= 0) return

    transact_UNSTABLE(() => {
      set(projectState, (prev) => ({
        ...prev,
        avatarId,
        dressId: dressList[0].seqId,
      }))
    })
  })

  return { selectCharacter }
}

export default useWorkspaceCallback
