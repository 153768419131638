import { useMemo } from 'react'
import * as React from 'react'

import { twMerge } from 'tailwind-merge'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/icon-download.svg'
import { ReactComponent as DownloadWhiteIcon } from '../../../../assets/images/icon-download-white.svg'

import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import IconLoading from '../../../Loading/IconLoading'
import useListenExportAudio from '../../../../services/audio/useListenExportAudio'
import { AUDIO_CRATE_STATUS } from '../../../../recoil/texteditor/sentenceBox/atoms'
import useListenExportVideo from '../../../../services/video/useListenExportVideo'
import AivatarButton from '../../../buttons/AivatarButton'

function ExportDownload({ row, name, type = 'ICON' }) {
  const isAudio = useMemo(() => row.exportType === 'sound', [row])

  const {
    isLoadingListenExportAudio,
    url: audioUrl,

    fetchListenExportAudio,
  } = useListenExportAudio()

  const {
    isLoadingListenExportVideo,
    // isCallSuccessListenExportVideo,
    url: videoUrl,

    fetchListenExportVideo,
    // initListenExportVideo,
  } = useListenExportVideo()

  useAivatarDidMount(() => {}, [])

  /** Validations */
  const isValidPlay = React.useMemo(() => {
    if (!row.status) return false
    return row.status === AUDIO_CRATE_STATUS.COMPLETED
  }, [row.status])

  const downloadFile = (url) => {
    const anchorElement = document.createElement('a')
    document.body.appendChild(anchorElement)
    anchorElement.download = `${row.filename}.${row.filetype}`
    anchorElement.href = url
    anchorElement.click()

    document.body.removeChild(anchorElement)
  }

  const onClickDownload = async () => {
    if (!isValidPlay) return
    if (isAudio) {
      if (!audioUrl) {
        fetchListenExportAudio({ id: row.id })
          .then((url) => {
            downloadFile(url)
          })
          .catch(() => {})
        return
      }
      downloadFile(audioUrl)
    } else {
      if (!videoUrl) {
        fetchListenExportVideo({ id: row.id })
          .then((url) => {
            downloadFile(url)
          })
          .catch(() => {})
        return
      }
      downloadFile(videoUrl)
    }
  }

  /** DIsplay */
  const isLoadingDownload = React.useMemo(
    () => (isAudio ? isLoadingListenExportAudio : isLoadingListenExportVideo),
    [isAudio, isLoadingListenExportAudio, isLoadingListenExportVideo],
  )

  let component = null
  switch (type) {
    case 'ICON':
      component = (
        <button
          type="button"
          disabled={!isValidPlay}
          className={twMerge(
            `relative mx-auto flex items-center justify-center text-white`,
            !isValidPlay ? 'opacity-50' : null,
          )}
          onClick={onClickDownload}
        >
          <DownloadIcon />
          {isLoadingDownload && <IconLoading size="20px" />}
        </button>
      )

      break
    case 'BUTTON':
      component = (
        <AivatarButton
          type="button"
          variant="m1"
          className="main_bg flex h-[44px] gap-[5px] rounded-full text-[16px] font-bold text-white"
          onClick={onClickDownload}
        >
          <span>{name}</span>
          <DownloadWhiteIcon />
        </AivatarButton>
      )
      break

    default:
      break
  }

  return component
}

export default ExportDownload
