import { ReactNode, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as IconPlanPro } from '../../assets/images/plan-pro.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as IconPlanBasic } from '../../assets/images/plan-basic.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as IconPlanEnterprise } from '../../assets/images/plan-enterprise.svg'
import { ReactComponent as IconPlanLabel } from '../../../assets/images/icon-plan-label.svg'
import AivatarClient from '../../../services/AivatarClient'
import PriceFormat from '../../Text/PriceFormater'
import { getPlanColor } from '../../../utils/colors'
import { useCurrentWorkspaceDetailQuery } from '../../../hooks/queries'
import CheckoutLink from './CheckoutLink'
import IconCheckGray from './IconCheckGray'
import ColumnHeader from './ColumnHeader'
import RowHeader from './RowHeader'
import Data from './Data'
import ContactLink from './ContactLink'

const FREE_VIDEO_MINUTE = 1

export default function SubscriptionPlanFeatureSheet({ className = '' }) {
  const [plans, setPlans] = useState<any[]>([])
  const { data: currentWorkspace } = useCurrentWorkspaceDetailQuery()
  const { t } = useTranslation('plan-info-modal')
  const isPause = currentWorkspace?.subscription?.status === 'PAUSE'

  const rows = useMemo<[string, ReactNode, ReactNode, ReactNode, ReactNode][]>(
    () => [
      [
        t('크레딧'),
        <>
          <b>
            600 {t('크레딧').toLowerCase()} ({t('1회')})
          </b>
          <span className="text-[14px]">
            {t('영상 {{minutes}}분', { minutes: FREE_VIDEO_MINUTE, count: FREE_VIDEO_MINUTE })}
          </span>
        </>,
        <>
          <b>
            12,000 {t('크레딧').toLowerCase()} /{t('월', { ns: 'period', context: 'short' })}
          </b>
          <span className="text-[14px]">
            {t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', { video_minutes: 20, audio_minutes: 200 })}
          </span>
        </>,
        <>
          <b>
            60,000 {t('크레딧').toLowerCase()} /{t('월', { ns: 'period', context: 'short' })}
          </b>
          <span className="text-[14px]">
            {t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', { video_minutes: 100, audio_minutes: 1000 })}
          </span>
        </>,
        <>
          <b>
            120,000+ {t('크레딧').toLowerCase()} /{t('월', { ns: 'period', context: 'short' })}
          </b>
          <span className="text-[14px]">
            {t('영상 {{video_minutes}}분 또는 음성 {{audio_minutes}}분', { video_minutes: 200, audio_minutes: 2000 })}
          </span>
        </>,
      ],
      [t('AI 아바타'), <>5</>, <>5</>, <>71</>, <>71+</>],
      [t('제스처'), <>1</>, <>1</>, <span>{t('전체 제공')}</span>, <span>{t('전체 제공')}</span>],
      [t('언어 및 목소리'), <>140</>, <>140</>, <>140</>, <>140</>],
      [t('워터마크 제거'), '', <IconCheckGray />, <IconCheckGray />, <IconCheckGray />],
      [t('맞춤형 아바타 (선택)'), '', '', '', <IconCheckGray />],
      [t('맞춤형 목소리 (선택)'), '', '', '', <IconCheckGray />],
      [
        t('다운로드'),
        <span>{t('무제한')}</span>,
        <span>{t('무제한')}</span>,
        <span>{t('무제한')}</span>,
        <span>{t('무제한')}</span>,
      ],
    ],
    [t],
  )

  useEffect(() => {
    AivatarClient()
      .get('/plans')
      .then(({ data }) => {
        setPlans(_.sortBy(data.content, 'level') as any)
      })
  }, [])

  const valuePrice = (amount: number) => {
    if (amount === 0) {
      return t('무료')
    }

    return <PriceFormat amount={amount} hideSuffix enablePrefix />
  }

  return (
    <div className={`bg-gray-7 relative rounded-[20px] border border-[#A9BDEA] shadow ${className}`}>
      <table className="w-full">
        <colgroup>
          <col />
          <col width="220" />
          <col width="220" />
          <col width="220" />
          <col width="220" />
        </colgroup>
        <thead>
          <tr>
            <ColumnHeader />
            {plans.map((plan, i) => (
              <ColumnHeader key={plan.id}>
                <span
                  translate="no"
                  className="text-[22px] font-[900] text-[var(--color)]"
                  // eslint-disable-next-line no-useless-computed-key
                  style={{ ['--color' as any]: getPlanColor(plan.name) }}
                >
                  {plan.name}
                </span>
                <span className="mt-[6px] text-[20px] font-[900] text-[#3d3d3d]">
                  {valuePrice(plan.price)}
                  {plan.amount !== 0 ? (
                    <span className="text-[14px] font-[500] text-[#9f9f9f]">
                      {' '}
                      /{t('월', { ns: 'period', context: 'short' })}
                    </span>
                  ) : null}
                </span>
                <span className="mt-[25px]">
                  <CheckoutLink
                    isCurrent={!isPause && plan.id === currentWorkspace?.subscription?.planId}
                    planId={plan.id}
                    price={plan.price}
                  />
                </span>
                {i === plans.length - 1 ? <IconPlanLabel className="absolute right-[10px] top-[-1px]" /> : null}
              </ColumnHeader>
            ))}
            <ColumnHeader key="enterprise">
              <span className="text-main-1 text-[22px] font-[900]">ENTERPRISE</span>
              <span className="mt-[6px] text-[20px] font-[900] text-[#3d3d3d]">Let&apos;s Talk</span>
              <span className="mt-[25px]">
                <ContactLink significant />
              </span>
            </ColumnHeader>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row[0]}>
              <RowHeader>{row[0]}</RowHeader>
              <Data>{row[1]}</Data>
              <Data>{row[2]}</Data>
              <Data>{row[3]}</Data>
              <Data>{row[4]}</Data>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
