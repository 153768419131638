import { useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { useRecoilValue } from 'recoil'
import clsx from 'clsx'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import { currentSelectedDressState } from '../../../../recoil/workspace/selectors'

import PreviewImage from '../../../../assets/images/videooption_image-preview.png'
import { projectValueState } from '../../../../recoil/project/selectors'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AvatarPlay({ checked }) {
  const backgroundColor = useRecoilValue(projectValueState({ key: 'backgroundColor' }))

  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const currentSelectedDress = useRecoilValue(currentSelectedDressState)

  useAivatarDidMount(() => {
    if (!currentSelectedDress) return
    setPlaying(false)
  }, [currentSelectedDress])

  const onClickPlay = () => {
    if (currentSelectedDress?.previewSrc) {
      setPlaying(!playing)
    }
  }

  /** styles */
  // const bgImage = useMemo(() => {
  //   if (!checked) return null
  //   if (backgroundColor) return null
  //   return <img src={PreviewImage} alt="preivew" className="h-full w-full object-cover" />
  // }, [backgroundColor, checked])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx('relative h-full w-full cursor-pointer')}
      onClick={onClickPlay}
      style={{
        background: !checked
          ? null
          : backgroundColor
            ? `#${backgroundColor}`
            : `url(${PreviewImage}) no-repeat center / cover`,
      }}
    >
      <ReactPlayer
        id="AvatarPlayer"
        ref={player}
        playing={playing}
        className="absolute left-0 top-0 bg-transparent"
        url={currentSelectedDress?.previewSrc}
        controls
        // light={currentSelectedDress?.thumbnailSrc}
        // light={true}
        loop
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              autoPlay: false,
              controlsList: 'nodownload nofullscreen noremoteplayback',
            },
          },
        }}
      />
    </div>
  )
}

export default AvatarPlay
