import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getWorkspaceInvoiceList, workspaceQueries } from '../services/workspace'
import { PaginationParams } from '../services/types'
import { customDayjs } from '../utils/date'

export function useCurrentWorkspaceDetailQuery() {
  const { workspaceId } = useParams()

  return useQuery({
    ...workspaceQueries.detail(Number(workspaceId)),
    select: (data) => {
      //! nextPaymentDate 백엔드 후순위로 진행돼서 프론트에서 대신 비즈니스로직 작성
      const { subscription } = data

      if (subscription) {
        //! 나중에 백엔드에서 직접 내려받아야됨. 수기결제이거나 다음 Status가 있으면 다음결제일은 없음
        subscription.nextPaymentDate =
          data.subscription.status === 'PAUSE' ||
          data.nextSubscription?.status === 'PAUSE' ||
          data.subscription.isManualPayment
            ? null
            : customDayjs(data.subscription.endAt).add(1, 'day').format('YYYY-MM-DD')
      }

      return { ...data, subscription }
    },
    enabled: !!workspaceId,
  })
}

export function useCurrentWorkspacePaymentMethod() {
  const { workspaceId } = useParams()

  return useQuery({
    ...workspaceQueries.detail(Number(workspaceId)),
    select: (data) => {
      const { subscription, nextSubscription } = data

      let paymentMethod: any = null
      if (nextSubscription) {
        paymentMethod = nextSubscription?.paymentMethod
      } else {
        paymentMethod = subscription?.paymentMethod
      }

      return paymentMethod
    },
  })
}

export function useCurrentWorkspaceInvoiceListQuery(params: Parameters<typeof getWorkspaceInvoiceList>[1]) {
  const { workspaceId } = useParams()
  return useQuery(workspaceQueries.detail(Number(workspaceId))._ctx.invoices(params))
}

export function useCurrentWorkspaceProjectListQuery(params: PaginationParams) {
  const { workspaceId } = useParams()
  return useQuery(workspaceQueries.detail(Number(workspaceId))._ctx.projects(params))
}

export function useCurrentWorkspaceCreditsQuery() {
  const { workspaceId } = useParams()
  return useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.credits,
    initialData: () => ({
      current: 0,
      predict: 0,
      available: 0,
      offer: 0,
      used: 0,
    }),
  })
}

export function useCurrentWorkspaceContracted() {
  const { data: workspace } = useCurrentWorkspaceDetailQuery()

  //! enterprise도 수기결제 true 체크될거라면 ENTERPRISE 체크조건 필요없을수도있음
  return !!(workspace?.subscription.isManualPayment || workspace?.subscription.plan.name === 'ENTERPRISE')
}

export function useCurrentWorkspacePlanName() {
  const { data: workspace } = useCurrentWorkspaceDetailQuery()

  return workspace?.subscription.isManualPayment && workspace?.subscription.plan.name !== 'ENTERPRISE'
    ? `${workspace?.subscription.plan.name} (CUSTOM)`
    : workspace?.subscription.plan.name
}

export function useCurrentWorkspacePaymentFailedInfo() {
  const { data: workspace } = useCurrentWorkspaceDetailQuery()

  const { data: invoices } = useCurrentWorkspaceInvoiceListQuery({
    page: 0,
    size: 10,
    sort: 'createdAt,desc',
  })

  // eslint-disable-next-line spaced-comment
  //@ts-ignore
  const failedInvoice = invoices?.content?.find((invoice) => invoice.status === 'FAILED')

  const result = {
    paymentFailed: false,
    failedAt: null as string | null,
    paymentMethodChanged: false,
  }

  if (failedInvoice && customDayjs(failedInvoice.createdAt).isAfter(customDayjs(workspace?.subscription?.endAt))) {
    result.paymentFailed = true
    result.failedAt = failedInvoice.createdAt

    if (customDayjs(workspace?.subscription?.paymentMethod?.updatedAt).isAfter(customDayjs(failedInvoice.createdAt))) {
      result.paymentMethodChanged = true
    }
  }

  return result
}

export function useRecentExportList() {
  const { workspaceId } = useParams()

  const [shouldRefetchAudioList, setShouldRefetchAudioList] = useState(true)
  const [shouldRefetchVideoList, setShouldRefetchVideoList] = useState(true)

  const { data: audioList } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.audioExports({ size: 5, sort: 'createdAt,desc' }),
    initialData: [],
    refetchInterval: shouldRefetchAudioList ? 3000 : false,
  })
  const { data: videoList } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.videoExports({ size: 5, sort: 'createdAt,desc' }),
    initialData: [],
    refetchInterval: shouldRefetchVideoList ? 3000 : false,
  })

  useEffect(() => {
    if (audioList) {
      if (audioList.content?.every((audio: any) => audio.status === 'COMPLETED' || audio.status === 'FAIL')) {
        setShouldRefetchAudioList(false)
      }
    }
    if (videoList) {
      if (videoList.content?.every((video: any) => video.status === 'COMPLETED' || video.status === 'FAIL')) {
        setShouldRefetchVideoList(false)
      }
    }
  }, [audioList, videoList])

  const totalList = [...(audioList?.content ?? []), ...(videoList?.content ?? [])].sort((a, b) =>
    b.createdAt > a.createdAt ? 1 : -1,
  )

  return { data: totalList }
}

export function useExportList(exportType: 'audio' | 'video', paginationParams: PaginationParams) {
  const { workspaceId } = useParams()

  const [shouldRefetchAudioList, setShouldRefetchAudioList] = useState(true)
  const [shouldRefetchVideoList, setShouldRefetchVideoList] = useState(true)

  const { data: audioList, isSuccess: audioSuccess } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.audioExports(paginationParams),
    initialData: [],
    refetchInterval: shouldRefetchAudioList ? 3000 : false,
    enabled: exportType === 'audio',
  })
  const { data: videoList, isSuccess: videoSuccess } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.videoExports(paginationParams),
    initialData: [],
    refetchInterval: shouldRefetchVideoList ? 3000 : false,
    enabled: exportType === 'video',
  })

  useEffect(() => {
    if (audioList) {
      if (audioList.content?.every((audio: any) => audio.status === 'COMPLETED' || audio.status === 'FAIL')) {
        setShouldRefetchAudioList(false)
      }
    }
    if (videoList) {
      if (videoList.content?.every((video: any) => video.status === 'COMPLETED' || video.status === 'FAIL')) {
        setShouldRefetchVideoList(false)
      }
    }
  }, [audioList, videoList])

  return {
    data: exportType === 'audio' ? audioList : videoList,
    isSuccess: exportType === 'audio' ? audioSuccess : videoSuccess,
  }
}
