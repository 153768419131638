import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilValue } from 'recoil'
import { focusedBoxIndexState, focusedBoxValueState, lastBoxIndexState } from '../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../recoil/texteditor/sentenceBox/atoms'
import useSentenceBoxCallbacks from '../../recoil/texteditor/sentenceBox/useSentenceBoxCallbacks'
import useEditorCallbacks from '../../recoil/texteditor/editor/useEditorCallbacks'

const useSentenceBoxCreatorHotkeys = () => {
  const { convertBoxToCreator } = useSentenceBoxCallbacks()
  const focusedBoxId = useRecoilValue(
    focusedBoxValueState({
      key: 'id',
      category: SENTENCEBOX_CATEGORY.GENERAL,
    }),
  )
  const focusedBoxIndex = useRecoilValue(focusedBoxIndexState)
  const lastBoxIndex = useRecoilValue(lastBoxIndexState)
  const { insertCreatorAtIndex } = useEditorCallbacks()

  useHotkeys(
    [
      // mac
      'return',
      // window
      'enter',
    ],
    () => {
      if (!focusedBoxId) return
      convertBoxToCreator({ id: focusedBoxId })
    },
    {
      preventDefault: true,
    },
  )
  useHotkeys(
    [
      // mac
      'meta+return',
      // window
      'ctrl+enter',
    ],
    () => {
      if (focusedBoxIndex === null) {
        insertCreatorAtIndex(lastBoxIndex + 1)
      } else {
        insertCreatorAtIndex(focusedBoxIndex + 1)
      }
    },
  )
}

export default useSentenceBoxCreatorHotkeys
