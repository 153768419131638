import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

//! Router의 Link로 구현할 수 있어야됨
export default function Data({ children }: Props) {
  return (
    <td className="typo-body2 border-gray-5 border-l-[1px] border-t-[1px] py-[16px] text-center text-[#3d3d3d] [&>*]:mx-auto">
      <div className="flex flex-col items-center">{children}</div>
    </td>
  )
}
