import { createQueryKeys } from '@lukemorales/query-key-factory'
import AivatarClient from '../AivatarClient'
import { LoggedUser, PreferredLanguage } from '../../@types/common'

export async function loginUser({ ...requestBody }: { email: string; password: string }) {
  const response = await AivatarClient().post('/auth/login', requestBody)

  return response.data as LoggedUser
}

export async function fetchUser() {
  const response = await AivatarClient().get('/auth/me')

  return response.data as LoggedUser
}

export async function updatePreferredLanguage({ ...requestBody }: { preferredLanguage: PreferredLanguage }) {
  const response = await AivatarClient().post('/users/update-language', requestBody)

  return response.data as LoggedUser
}

export const authQueries = createQueryKeys('auth', {
  me: {
    queryKey: null,
    queryFn: () => fetchUser(),
  },
})
