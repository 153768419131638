import { useMemo } from 'react'
import { motion } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import LangSelect from '../LangSelect'
import VoiceSelect from '../VoiceSelect'
import SentenceBoxOptionController from '../SentenceBoxOptionController'
import ResetOptions from '../ResetOptions'
import ApplyAllOptions from '../ApplyAllOptions'
import { editorValueState } from '../../../../recoil/texteditor/editor/selectors'

function SentenceBoxOptionRoot() {
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const isEmptyFocused = useMemo(() => focusedBoxId === null, [focusedBoxId])

  return (
    <div className={`relative flex h-full w-full flex-col ${isEmptyFocused && 'pointer-events-none'}`}>
      <motion.div
        animate={{
          opacity: isEmptyFocused ? 0 : 1,
        }}
        className="flex h-full w-full flex-col"
      >
        <LangSelect />
        <div className="min-h-[20px]" />
        <VoiceSelect />
        <div className="min-h-[40px]" />
        <SentenceBoxOptionController />
        <div className="min-h-[10px]" />
        <div className="flex min-h-[40px] w-full items-center justify-center">
          <ResetOptions />
          <div className="w-[30px]" />
          <ApplyAllOptions />
        </div>
        <div className="min-h-[160px]" />
      </motion.div>
    </div>
  )
}
export default SentenceBoxOptionRoot
