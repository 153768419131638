import { useOutlet } from 'react-router-dom'
import { StompSessionProvider } from 'react-stomp-hooks'
import Header from './Header'
import Navigator from '../Navigator'
import OptionManager from '../OptionManager'
import env from '../../../constants/env'
import useAuth from '../../../hooks/useAuth'
import AivatarHotKeysRoot from '../../../providers/AivatarHotKeysRoot'
import { useCurrentWorkspaceDetailQuery } from '../../../hooks/queries'
import useDialog from '../../../hooks/useDialog'
import SubscriptionPlanDialog from '../../../components/SubscriptionPlan/SubscriptionPlanDialog'

function ProjectLayout() {
  const outlet = useOutlet()
  const { user } = useAuth()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const isPause = workspace?.subscription?.status === 'PAUSE'
  const dialog = useDialog()

  return (
    <StompSessionProvider
      url={`${env.WEBSOCKET_HOST}/ws`}
      connectHeaders={{ login: `Bearer ${user?.accessToken}` }}
      debug={(str) => {
        console.log(str)
      }}
      onChangeState={() => {
        console.log('[STOMP_EVENT] onChangeState')
      }}
      onConnect={() => {
        console.log('[STOMP_EVENT] onConnect')
      }}
      onDisconnect={() => {
        console.log('[STOMP_EVENT] onDisconnect')
      }}
      onStompError={() => {
        console.log('[STOMP_EVENT] onStompError')
      }}
      onUnhandledFrame={() => {
        console.log('[STOMP_EVENT] onUnhandledFrame')
      }}
      onUnhandledMessage={() => {
        console.log('[STOMP_EVENT] onUnhandledMessage')
      }}
      onUnhandledReceipt={() => {
        console.log('[STOMP_EVENT] onUnhandledReceipt')
      }}
      onWebSocketClose={() => {
        console.log('[STOMP_EVENT] onWebSocketClose')
      }}
      onWebSocketError={() => {
        console.log('[STOMP_EVENT] onWebSocketError')
      }}
    >
      <AivatarHotKeysRoot>
        <section className="project_layout flex h-full w-full flex-col">
          <Header />
          <div className="relative flex h-[calc(100vh-80px)]">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            {isPause ? (
              <div
                role="none"
                className="absolute bottom-0 left-0 right-0 top-0 z-[999]"
                onClick={() => dialog.open(<SubscriptionPlanDialog expanded />)}
              />
            ) : null}

            <Navigator />
            {outlet}
            <OptionManager />
          </div>
        </section>
      </AivatarHotKeysRoot>
    </StompSessionProvider>
  )
}

export default ProjectLayout
