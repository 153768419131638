import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactComponent as ChipProgressAnimatedIcon } from '../../../../assets/images/chip-progress-animated.svg'
import { customDayjs } from '../../../../utils/date'

type Props = {
  exportType: 'sound' | 'movie'
  createdAt: string
  duration: number | null
}

export default function ChipProcessing({ exportType, createdAt, duration }: Props) {
  const [progress, setProgress] = useState(0)

  const creationTime = useMemo(() => {
    let finalDuration = duration

    if (!finalDuration) {
      finalDuration = 120 * 1000 // 없으면 2분짜리라고 생각하자.
    }

    // duration: milliseconds
    switch (exportType) {
      case 'sound':
        return (0.05 * finalDuration) / 1000
      case 'movie':
        return (5 * finalDuration) / 1000
      default:
        return (5 * finalDuration) / 1000
    }
  }, [duration, exportType])

  const updateProgress = useCallback(() => {
    let newProgress = (customDayjs().diff(customDayjs(createdAt), 'second') * 100) / creationTime
    if (newProgress >= 100) {
      newProgress = 99
    }
    if (newProgress < 0) {
      newProgress = 0
    }

    newProgress = Math.floor(newProgress)

    setProgress(newProgress)
  }, [createdAt, creationTime])

  useEffect(() => {
    updateProgress()
    const intervalId = window.setInterval(() => {
      updateProgress()
    }, 1000)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [createdAt, updateProgress])

  return (
    <div className="chip-yellow mx-auto">
      <span>{progress}%</span>
      <ChipProgressAnimatedIcon />
    </div>
  )
}
