import { ReactNode, SVGProps, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDownB } from '../../../assets/images/arrow-down-b.svg'
import { ReactComponent as XIcon } from '../../../assets/images/global_icon-x.svg'

function DescArrow(
  props: SVGProps<SVGSVGElement> & {
    title?: string | undefined
  },
) {
  return <ArrowDownB {...props} />
}

function AscArrow({
  className,
  ...otherProps
}: SVGProps<SVGSVGElement> & {
  title?: string | undefined
}) {
  return <ArrowDownB className={`rotate-180 ${className}`} {...otherProps} />
}

type Props = {
  id: string
  sort: Array<{ key: string; order: 'desc' | 'asc' }>
  onChangeSort?: (sort: Props['sort']) => void
  children: ReactNode
}

export default function SortableColumn({ id, sort = [], onChangeSort, children }: Props) {
  const { t } = useTranslation('table')
  const location = useLocation()
  const { workspaceId } = useParams()
  const isHome = location.pathname === `/workspace/${workspaceId}`
  const order = sort.find((item) => item.key === id)?.order ?? null
  const OrderIcon = order === 'desc' ? DescArrow : order === 'asc' ? AscArrow : null

  /** Events */
  const onClickReset = () => {
    onChangeSort?.([{ key: 'createdAt', order: 'desc' }])
  }

  const isShowReset = useMemo(() => {
    if (id === 'createdAt') {
      return order === 'asc'
    }
    return order !== null
  }, [order, id])

  return (
    <div className="flex items-center">
      <button
        className={`flex items-center ${
          !isHome ? 'hover:bg-gray-6 px-[5px]' : 'cursor-auto'
        } rounded-[3px] py-[3px] ${order ? 'text-gray-2 px-[5px]' : ''}`}
        onClick={() => {
          onChangeSort?.([{ key: id, order: order === 'desc' ? 'asc' : 'desc' }])
        }}
      >
        {children}
        {OrderIcon && <OrderIcon className="ml-[4px]" />}
      </button>

      {isShowReset && (
        <>
          <div className="mx-[8px] h-[16px] min-w-[1px] bg-[#9F9F9F]" />
          <button
            aria-label={t('리셋')}
            className="flex h-[30px] w-[22px] items-center justify-center bg-[#ffffff60]"
            onClick={onClickReset}
          >
            <XIcon />
          </button>
        </>
      )}
    </div>
  )
}
