import React, { KeyboardEventHandler, MouseEventHandler, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { PlanName } from '../../../../@types/common'
import PlayButton from '../../components/PlayButton'

export type Avatar = {
  label: string
  plans: PlanName[]
  videoUrl: {
    ko: string
    en: string
  }
  thumbnailUrl: {
    ko: string
    en: string
  }
}

type Props = {
  data: Avatar
  playing: boolean
  itemIndex: number
  // focusedItemIndex: number | null
  togglePlay: (index: number) => void
}

export default function AvatarItem({
  data,
  playing,
  itemIndex,
  // focusedItemIndex,
  togglePlay,
}: Props) {
  const playerRef = useRef<ReactPlayer>(null)
  const { i18n } = useTranslation()

  const [isHover, setIsHover] = useState(false)

  const assetUrl = useMemo(() => {
    switch (true) {
      case i18n.language.startsWith('ko'):
        return {
          video: data.videoUrl.ko,
          thumbnail: data.thumbnailUrl.ko,
        }
      case i18n.language.startsWith('en'):
        return {
          video: data.videoUrl.en,
          thumbnail: data.thumbnailUrl.en,
        }
      default:
        return {
          video: data.videoUrl.en,
          thumbnail: data.thumbnailUrl.en,
        }
    }
  }, [data, i18n])

  const handleContainerClicked: MouseEventHandler<HTMLDivElement> = () => {
    togglePlay(itemIndex)
  }

  const handleContainerKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === 'Space') {
      togglePlay(itemIndex)
    }
  }

  const handlePlayerEnded = () => {
    togglePlay(itemIndex)
    playerRef.current?.seekTo(0)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      aria-label={data.label}
      className={twMerge(
        // 썸네일, 동영상크기: 300 x 536. border너비: 2 고려한 사이즈임
        'relative h-[540px] w-[304px] flex-initial cursor-pointer overflow-hidden rounded-[10px] border-2 border-transparent transition-all',
        isHover || playing ? 'shadow_selected border-main-1' : null,
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleContainerClicked}
      onKeyDown={handleContainerKeyDown}
    >
      <ReactPlayer
        ref={playerRef}
        url={assetUrl.video}
        playing={playing}
        width="100%"
        height="100%"
        onEnded={handlePlayerEnded}
        light={
          <img src={assetUrl.thumbnail} alt={`${data.label} 썸네일`} width="300px" style={{ objectFit: 'contain' }} />
        }
        // eslint-disable-next-line react/jsx-no-useless-fragment
        playIcon={<PlayButton className="absolute bottom-3 left-3 z-10" onClick={() => {}} playing={playing} />}
        // onPause={() => {
        //   if (itemIndex !== focusedItemIndex) {
        //     playerRef.current?.seekTo(0)
        //   }
        // }}
      />
      <PlayButton className="absolute bottom-3 left-3" onClick={() => {}} playing={playing} />
    </div>
  )
}
