import AivatarClient from '../AivatarClient'
import { PaginationParams } from '../types'

export async function getAudioExportList(workspaceId: number, params: PaginationParams) {
  const response = await AivatarClient().get(`/audios/workspace/${workspaceId}`, { params })

  return response.data
}

export const dummy = null
