export function addItemsAtIndex(arr, index, newArray) {
  return [...arr.slice(0, index), ...newArray, ...arr.slice(index)]
}

export function replaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

export function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}
