import OutsideClickHandler from 'react-outside-click-handler'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
// import { ReactComponent as DefaultIcon } from '../assets/images/menu-default.svg'

export default function DropdownMenu({ className = '', children, onOutsideClick = () => {} }) {
  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className={`absolute z-10 block rounded-[10px] p-[10px] shadow ${className} bg-white`}>{children}</div>
    </OutsideClickHandler>
  )
}

export function DropdownMenuList({ className = '', children }) {
  return (
    <ul
      className={`border-gray-4 border-t py-[10px] [&:first-child]:border-t-0 [&:first-child]:pt-0 [&:last-child]:pb-0 ${className}`}
    >
      {children}
    </ul>
  )
}

export function DropdownMenuListTitle({ children }) {
  return <span className="text-gray-4 flex h-[41px] items-center px-[15px] text-[14px] font-medium">{children}</span>
}

export function DropdownMenuListItem({ className = '', children, ...otherProps }) {
  return (
    <li className={`hover:bg-main-4 cursor-pointer rounded-[10px] ${className}`} {...otherProps}>
      {children}
    </li>
  )
}
export function DropdownMenuListItemA({ label, Icon, to, onClick, className = '', selected = false }) {
  return (
    <DropdownMenuListItem className={`hover:bg-main-4 group cursor-pointer rounded-[10px] ${className}`}>
      <Link
        to={to}
        className="grid h-[45px] w-full grid-cols-[25px_1fr] items-center gap-[10px] px-[15px]"
        onClick={onClick}
      >
        <div className="flex items-center justify-center">
          {Icon ? (
            <Icon
              className={twMerge(
                '[&>path]:fill-gray-2 [&_path]:group-hover:fill-main w-full',
                selected ? '[&_path]:fill-main' : null,
              )}
            />
          ) : (
            <div
              className={twMerge(
                'border-gray-2 group-hover:border-main-1 h-[10px] w-[10px] rounded-full border',
                selected ? 'border-main-1 bg-main-1' : null,
              )}
            />
          )}
        </div>
        <span
          className={twMerge(
            'text-gray-2 group-hover:text-main-1 line-clamp-1 break-all',
            selected ? 'text-main-1' : null,
          )}
        >
          {label}
        </span>
      </Link>
    </DropdownMenuListItem>
  )
}
