import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import useOpenSelect from '../useOpenSelect'
import CharacterSelectContent from './CharacterSelectContent'

function CharacterSelect() {
  const { t } = useTranslation('project-option')
  const {
    component: OpenSelectRowComponent,
    isOpen,
    setIsOpen,
  } = useOpenSelect({
    title: t('아바타'),
    order: 1,
  })

  /** Recoilds */

  /** Fetching */

  /** Life Cycle */
  useAivatarDidMount(() => {
    setIsOpen(true)
  })

  /** Events */

  /** Displays */

  return (
    <div className="shadow_1 flex w-full flex-col justify-between rounded-[24px] bg-white px-[14px] py-[20px]">
      {OpenSelectRowComponent}
      <CharacterSelectContent isOpen={isOpen} />
    </div>
  )
}
export default CharacterSelect
