import { useTranslation } from 'react-i18next'
import HelpIcon from '../../../assets/images/icon-help.svg'
import useURI from '../../../hooks/useURI'

function HelpButton({ className = '' }) {
  const { t } = useTranslation('dashboard')
  const { uri } = useURI()

  const onClickHelp = () => {
    window.open(uri['help-center'])
  }

  return (
    <button className={`mx-[27px] flex items-center ${className}`} onClick={onClickHelp}>
      <img className="mr-[6px]" src={HelpIcon} alt="help" />
      <span className="text-point-2 text-[14px] font-medium">{t('가이드북')}</span>
    </button>
  )
}

export default HelpButton
