import { useRecoilValue } from 'recoil'
import SentenceBoxConnector from '../../../../providers/SentenceBoxConnector'
import AddParagraphButton from './AddParagraphButton'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'

import SentenceInterval from './SentenceInterval'
import AudioManager from './AudioManager'
import SentenceBoxText from './SentenceBoxText'
import {
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
} from '../../../../recoil/texteditor/sentenceBox/atoms'
import useInitVoice from './useInitVoice'

function SentenceBox() {
  const { sentenceBoxId, sentenceBoxIndex } = SentenceBoxConnector.useContainer()

  /** Recoils */
  /** FIX: For Log */
  const sentenceBox = useRecoilValue(sentenceBoxState(sentenceBoxId))
  const sentenceBoxAudio = useRecoilValue(sentenceBoxAudioState(sentenceBoxId))
  const sentenceBoxOption = useRecoilValue(sentenceBoxOptionState(sentenceBoxId))

  /** Initiate */
  useInitVoice()

  /** Life Cycle */
  useAivatarDidMount(() => {
    console.debug(`SentenceBox Log - index: ${sentenceBoxIndex}`, {
      ...sentenceBox,
      ...sentenceBoxAudio,
      ...sentenceBoxOption,
    })
  }, [sentenceBox, sentenceBoxAudio, sentenceBoxOption])

  /** Event Handler */

  /** Methods */

  /** Displays */

  const sentenceBoxTextElementId = `sentenceBoxText--${sentenceBoxId}`

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span
      className="w-fit align-middle leading-[50px]"
      onClick={(e) => {
        e.stopPropagation()
      }}
      aria-labelledby={sentenceBoxTextElementId}
    >
      <AudioManager />
      <SentenceBoxText id={sentenceBoxTextElementId} />
      <SentenceInterval />
      <AddParagraphButton />
    </span>
  )
}

export default SentenceBox
