import GoogleIcon from '../../assets/images/google-icon.svg'
import env from '../../constants/env'

function GoogleButton({ className, children, onClick, ...buttonProps }) {
  const goToGoogleAuth = () => {
    const searchParams = new URLSearchParams({
      redirect_uri: `${window.location.origin}/oauth2/redirect`,
    })
    window.location.assign(`${env.API_HOST}/oauth2/authorize/google?${searchParams.toString()}`)
  }

  return (
    <button
      className={`btn ${className}`}
      onClick={(e) => {
        onClick?.(e)
        goToGoogleAuth()
      }}
      {...buttonProps}
    >
      <img className="mr-[10px] w-[30px]" src={GoogleIcon} alt="google" />
      {children}
    </button>
  )
}

export default GoogleButton
