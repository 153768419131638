import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import BoxSection from '../BoxSection'
import { useCurrentWorkspaceDetailQuery, useCurrentWorkspacePaymentMethod } from '../../../../hooks/queries'
import InfoTitle from './InfoTitle'
import InfoContent from './InfoContent'
import AivatarButton from '../../../buttons/AivatarButton'
import useChangePaymentMethod from '../../../../hooks/useChangePaymentMethod'
import { getCardInfo } from '../../../../utils/number'
import PriceFormat from '../../../Text/PriceFormater'

type Props = {
  className?: string
  isManualPayment?: boolean
}

export default function PaymentInfoSection({ className, isManualPayment }: Props) {
  // const dialog = useDialog()
  // const navigate = useNavigate()
  const { data: workspace } = useCurrentWorkspaceDetailQuery()
  const { data: paymentMethod } = useCurrentWorkspacePaymentMethod()
  const { t } = useTranslation('subscription')
  const { changePaymentMethod, isChangingPaymentMethod } = useChangePaymentMethod()
  // const { mutate: mutateToContinue, isPending: isContinuing } = useMutation({ mutationFn: continueSubscribe })

  // const continueSubscription = () => {
  //   if (workspace) {
  //     dialog.close()
  //     mutateToContinue(workspace.id, {
  //       onSuccess: () => {
  //         navigate(`/workspace/${workspace?.id}/subscription/continue`)
  //       },
  //       onError: () => {
  //         dialog.alert({
  //           title: '플랜변경 취소 실패',
  //           message: '플랜변경 취소가 실패되었습니다.',
  //         })
  //       },
  //     })
  //   }
  // }

  // const handleContinueButtonClicked: MouseEventHandler<HTMLButtonElement> = () => {
  //   dialog.confirm({
  //     title: t('플랜변경을 취소하시겠습니까?'),
  //     message: t('변경취소 버튼을 누르면 이전 구독이 다시 유지됩니다.'),
  //     confirmButtonName: t('변경 취소'),
  //     onClickConfirm: continueSubscription,
  //     cancelButtonName: t('닫기', { ns: 'modal' }),
  //     onClickCancel: dialog.close,
  //   })
  // }

  return (
    <>
      {isChangingPaymentMethod ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'fixed',
            zIndex: 9999,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <BeatLoader color="#3498db" loading size={24} />
          <p style={{ color: '#fff', marginTop: '20px' }}>Processing...</p>
        </div>
      ) : null}

      <BoxSection className={className} title={t('결제 정보')}>
        <div className="mb-[23px] mt-[20px]">
          <div className="flex justify-between">
            <div>
              <InfoTitle>{t('결제 수단')}</InfoTitle>
              <InfoContent>
                {isManualPayment
                  ? t('수기 결제')
                  : paymentMethod
                    ? getCardInfo(paymentMethod?.cardName, paymentMethod?.cardNumber)
                    : null}
              </InfoContent>
            </div>
            {!isManualPayment && paymentMethod ? (
              <AivatarButton type="button" variant="m4" onClick={() => changePaymentMethod()}>
                {t('변경')}
              </AivatarButton>
            ) : null}
          </div>
          {/* <InfoButton className="ml-auto">수정</InfoButton> */}
        </div>
        <div className="mb-[23px]">
          <InfoTitle>{t('다음 결제일')}</InfoTitle>
          <InfoContent>
            {workspace?.subscription.nextPaymentDate ?? '-'}
            {workspace?.nextSubscription?.status !== 'PAUSE' && workspace?.nextSubscription?.plan?.amount ? (
              <span>
                {' / '}
                <PriceFormat amount={workspace?.nextSubscription?.plan?.amount} />
              </span>
            ) : null}
          </InfoContent>
        </div>
        {/* {workspace?.nextSubscription?.status === 'CHANGED' ? (
          <div className="mb-[23px]">
            <div className="flex items-center justify-between">
              <div>
                <InfoTitle>{t('다음 예정 플랜')}</InfoTitle>
                <InfoContent>{workspace?.subscription.nextPaymentDate ?? '-'}</InfoContent>
              </div>
              <AivatarButton type="button" variant="m4" onClick={handleContinueButtonClicked}>
                {t('취소')}
              </AivatarButton>
            </div>
          </div>
        ) : null} */}
        <div className="mb-[23px]">
          <InfoTitle>{t('청구 이메일 주소')}</InfoTitle>
          <div className="mt-[11px] flex items-center">
            <InfoContent className="text-gray-2 font-normal">{workspace?.members[0].email}</InfoContent>
            {/* <InfoButton className="ml-auto" onClick={showEmailPrompt}> */}
            {/*   수정 */}
            {/* </InfoButton> */}
          </div>
        </div>
      </BoxSection>
    </>
  )
}
