import * as React from 'react'
import ReactPlayer from 'react-player'

import { useTranslation } from 'react-i18next'
import { AUDIO_CRATE_STATUS } from '../../../../recoil/texteditor/sentenceBox/atoms'
import ExportDownload from '../ExportDownload'
import env from '../../../../constants/env'
import useAuth from '../../../../hooks/useAuth'
import AivatarButton from '../../../buttons/AivatarButton'

function ExportAudioPlayModal({ row, handleCloseModal }) {
  const { user } = useAuth()
  const { t } = useTranslation('export-list')

  const player = React.useRef(null)

  const isValidPlay = React.useMemo(() => {
    if (!row.status) return false
    return row.status === AUDIO_CRATE_STATUS.COMPLETED
  }, [row])

  /** HAndlers */

  /** Validations */

  /** MEmos */

  /** Validations */

  /** Memos */
  const url = React.useMemo(() => {
    if (!isValidPlay) return null
    if (!row.id) return null
    if (!user.accessToken) return null

    return `${env.API_HOST}/api/v1/audios/stream/${row.id}?token=${user.accessToken}`
  }, [isValidPlay, row.id, user.accessToken])

  return (
    <div className="relative flex h-[185px] w-full flex-col items-center justify-center">
      <ReactPlayer
        id="ExportAudioPlay"
        ref={player}
        className="w-[666px]"
        url={url}
        autoPlay
        controls
        playsinline
        progressInterval={1000}
        width={666}
        height={53}
        config={{
          file: {
            forceAudio: true,
            attributes: {
              onContextMenu: (e) => e.preventDefault(),
              controlsList: 'nodownload',
            },
          },
        }}
      />
      <div className="mt-[33px] flex items-center justify-center gap-[15px]">
        <AivatarButton
          type="button"
          variant="g5"
          className="text-gray-2 flex h-[44px] w-[117px] items-center justify-center rounded-full text-[16px] font-bold"
          onClick={() => {
            handleCloseModal()
          }}
        >
          {t('닫기', { ns: 'modal' })}
        </AivatarButton>
        <ExportDownload row={row} type="BUTTON" name={t('다운로드')} />
      </div>
    </div>
  )
}

export default ExportAudioPlayModal
