import AivatarClient from '../AivatarClient'
import { PaginationParams } from '../types'

export async function getProjectList(workspaceId: number, params: PaginationParams) {
  const response = await AivatarClient().get(`/workspaces/${workspaceId}/projects`, { params })

  return response.data
}

export const dummy = null
