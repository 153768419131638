import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'

type Props = {
  amount: number
  currency?: 'KRW' //! 나중에 발생할 금액 Currency 변화를 위해 미리 대비해둔겁니다. 아마 api에 currency가 추가되어 enum 상수가 넘어오겠죠?
  hideSuffix?: boolean
  enablePrefix?: boolean
}

export default function PriceFormat({ amount, currency = 'KRW', hideSuffix = false, enablePrefix }: Props) {
  const { t } = useTranslation('currency')

  let suffix: string
  let prefix: string
  switch (currency) {
    case 'KRW':
      suffix = t('원')
      prefix = '￦'
      break
    default:
      suffix = t('원')
      prefix = '￦'
  }

  return (
    <NumericFormat
      value={amount}
      suffix={!hideSuffix ? suffix : undefined}
      prefix={enablePrefix ? prefix : undefined}
      thousandSeparator
      displayType="text"
    />
  )
}
