import { useEffect } from 'react'
import useDidMount from './useDidMount'

export default function useAivatarDidMount(callback, dependencies = []) {
  const didMount = useDidMount()
  useEffect(() => {
    if (!didMount) return
    if (typeof callback === 'function') callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount, ...dependencies])

  return didMount
}
