import { useTimeout } from 'react-use'

import { AnimatePresence, motion } from 'framer-motion'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import { isValidSetenceBoxAudioInEditorState } from '../../../../recoil/texteditor/editor/selectors'
import FailIcon from '../../../../assets/images/global_toast-fail.png'

const durationTime = 4000

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SentenceBoxErrorModal({ type, onClickClose }) {
  const { t } = useTranslation('project')
  /** Recoilds */
  const isValidSetenceBoxAudioInEditor = useRecoilValue(isValidSetenceBoxAudioInEditorState)

  const bgColor = 'bg-[#FFEEEE]'
  const border = 'border-[1px] border-[#F05E5E]'
  const textColor = 'text-[#F05E5E]'

  const headerMessage = !isValidSetenceBoxAudioInEditor
    ? t('음성 생성이 불가한 문자나 기호가 포함되었습니다. 문자만 입력해 주세요.')
    : // : t('음성 생성에 실패하였습니다. 텍스트 또는 음성 옵션을 다시 한 번 확인해 주세요.')
      t('마침표, 물음표, 느낌표를 포함하여 400글자 이내로 작성해 주세요.')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isReady, cancel] = useTimeout(durationTime)

  const getPopupOverlay = () => {
    const popupOverlayArray = document.getElementsByClassName('popup-overlay')
    if (!popupOverlayArray || popupOverlayArray.length <= 0) return null
    return popupOverlayArray[0]
  }

  useAivatarDidMount(() => {
    const popupOverlay = getPopupOverlay()
    popupOverlay.classList.add('pointerNone')
  }, [])

  useAivatarDidMount(() => {
    if (!isReady()) return
    onClickClose()
  }, [isReady()])

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.5 }}
        className={`h-[70px] w-[560px] min-w-[560px] overflow-hidden rounded-full px-[40px] text-white ${bgColor} ${border} relative flex items-center justify-center overflow-hidden`}
      >
        <div className="mr-[10px] h-[30px] w-[30px] min-w-[30px]">
          <img className="h-full w-full" src={FailIcon} alt="error" />
        </div>
        <div
          className={`${textColor} flex w-fit items-center overflow-hidden whitespace-pre-wrap text-[14px] font-medium`}
        >
          {headerMessage}
        </div>
        <div className="absolute bottom-0 h-[2px] w-full sm:h-[3px]">
          <motion.div
            animate={{ width: 0 }}
            transition={{ ease: 'linear', duration: durationTime / 1000 }}
            className="absolute h-full w-full bg-[#ffffff50]"
          />
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default SentenceBoxErrorModal
